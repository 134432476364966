import { SVGProps } from 'react'

export const ChevronUpIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M7.4 15.4L6 14L12 8L18 14L16.6 15.4L12 10.8L7.4 15.4Z" />
    </svg>
  )
}
