import { Text, TruckIcon, Skeleton, Accordion } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import { mercaiAddressCD, linkMapsLocationMercai } from '@/constants'
import { formatCurrency, formatNumber } from '@/helpers'

import type { SummaryDeliveryProps } from './summary-delivery.type'

import styles from './summary-delivery.module.css'
import { useState } from 'react'

const QuotationSummaryDeliveryDefault = ({
  buyerAddress,
  totalFreightPrice,
  customsFreightCost,
  financingCost,
  firstMileFreightCost,
  lastMileFreightCost,
  dollarQuotation,
  delivery,
  expectedDeliveryDays,
}: SummaryDeliveryProps) => {
  const { t } = useTranslation('screenQuotationList')
  const [activeItems, setActiveItems] = useState<string[]>([])

  const mxnAmountPrice = formatCurrency(
    totalFreightPrice * dollarQuotation,
    'es-MX',
    'MXN',
  )
  const usdAmountPrice = formatCurrency(totalFreightPrice, 'es-MX', 'USD')

  return (
    <Accordion.Root
      activeItems={activeItems}
      onHandleActiveItems={setActiveItems}
      className={styles.core}
    >
      <Accordion.Item
        id="info-list"
        header={
          <div className={styles['box-title']}>
            <TruckIcon />
            <Text variant="title-base" className={styles.title}>
              {t('quotationShowTitleSummaryDelivery')}
            </Text>
          </div>
        }
      >
        <div className={styles.content}>
          {delivery === 'pick_up' ? (
            <>
              <Text variant="text-base">
                {t(`quotationShowSummaryDeliveryPickUpTitle`)}
              </Text>

              <Text variant="text-base" className={styles.description}>
                {t('quotationShowSummaryDeliveryPickUpDescription', {
                  address: mercaiAddressCD,
                })}

                <Text
                  variant="link"
                  href={linkMapsLocationMercai}
                  target="_blank"
                >
                  {t('quotationShowSummaryDeliveryPickUpDescriptionMaps')}
                </Text>
              </Text>
            </>
          ) : (
            <>
              <Text variant="text-base">
                {t(`quotationShowSummaryDeliveryDeliveryTitle`)}
              </Text>

              <Text variant="text-base">{buyerAddress}</Text>
            </>
          )}

          <div className={styles['content-row']}>
            <Text variant="title-base">
              {t('quotationShowSummaryDeliveryFreightPrice')}:{' '}
            </Text>

            {totalFreightPrice ? (
              <Text variant="text-base">
                {mxnAmountPrice} ({usdAmountPrice})
              </Text>
            ) : (
              <Text variant="text-base">
                {t('quotationShowSummaryDeliveryFree')}
              </Text>
            )}
          </div>

          <div className={styles['content-row']}>
            <Text variant="title-base">
              {t('quotationShowSummaryDeliveryFirstMileFreightCost')}:{' '}
            </Text>

            {firstMileFreightCost ? (
              <Text variant="text-base">
                {formatCurrency(
                  firstMileFreightCost * dollarQuotation,
                  'es-MX',
                  'MXN',
                )}{' '}
                ({formatCurrency(firstMileFreightCost, 'es-MX', 'USD')})
              </Text>
            ) : (
              <Text variant="text-base">
                {t('quotationShowSummaryDeliveryUninformedData')}
              </Text>
            )}
          </div>

          <div className={styles['content-row']}>
            <Text variant="title-base">
              {t('quotationShowSummaryDeliveryLastMileFreightCost')}:{' '}
            </Text>

            {lastMileFreightCost ? (
              <Text variant="text-base">
                {formatCurrency(
                  lastMileFreightCost * dollarQuotation,
                  'es-MX',
                  'MXN',
                )}{' '}
                ({formatCurrency(lastMileFreightCost, 'es-MX', 'USD')})
              </Text>
            ) : (
              <Text variant="text-base">
                {t('quotationShowSummaryDeliveryUninformedData')}
              </Text>
            )}
          </div>

          <div className={styles['content-row']}>
            <Text variant="title-base">
              {t('quotationShowSummaryDeliveryCustomsFreightCost')}:{' '}
            </Text>

            {customsFreightCost ? (
              <Text variant="text-base">
                {formatCurrency(
                  customsFreightCost * dollarQuotation,
                  'es-MX',
                  'MXN',
                )}{' '}
                ({formatCurrency(customsFreightCost, 'es-MX', 'USD')})
              </Text>
            ) : (
              <Text variant="text-base">
                {t('quotationShowSummaryDeliveryUninformedData')}
              </Text>
            )}
          </div>

          <div className={styles['content-row']}>
            <Text variant="title-base">
              {t('quotationShowSummaryDeliveryFinancingCost')}:{' '}
            </Text>

            {financingCost ? (
              <Text variant="text-base">
                {formatNumber(financingCost * 100, 'es-MX', 2)}%
              </Text>
            ) : (
              <Text variant="text-base">
                {t('quotationShowSummaryDeliveryUninformedData')}
              </Text>
            )}
          </div>

          <div className={styles['content-row']}>
            <Text variant="title-base">
              {t('quotationShowSummaryDeliveryDeliveryDate')}:{' '}
            </Text>
            <Text variant="text-base">
              {expectedDeliveryDays
                ? `${expectedDeliveryDays} ${t('quotationShowSummaryDeliveryDaysAfterPayment')}`
                : t('quotationShowSummaryDeliveryDateNotDefined')}
            </Text>
          </div>
        </div>
      </Accordion.Item>
    </Accordion.Root>
  )
}

const QuotationSummaryDeliveryLoading = () => {
  return (
    <div className={styles.core}>
      <div className={styles.title}>
        <Skeleton height="1.5rem" width="1.5rem" />
        <Skeleton height="1rem" width="8rem" />
      </div>

      <div className={styles.content}>
        <Skeleton height="1rem" width="100%" />
        <Skeleton height="1rem" width="60%" />
      </div>
    </div>
  )
}

export const QuotationSummaryDelivery = (props: SummaryDeliveryProps) => {
  if (props.isLoading) {
    return <QuotationSummaryDeliveryLoading />
  }

  return <QuotationSummaryDeliveryDefault {...props} />
}
