const negativeMessages = (value: string) => [
  `¡Esta semana ganamos ${value} menos que la semana anterior, vamos a mejorar esto!`,
  `Esta semana obtuvimos ${value} menos que la pasada. Debemos identificar el problema y corregirlo.`,
  `Nuestras ganancias cayeron en ${value} con respecto a la semana anterior. Tenemos que averiguar qué salió mal.`,
  `Hemos sufrido una disminución de ${value} en comparación con la semana pasada. Necesitamos ajustar la estrategia.`,
  `En lugar de mejorar, esta semana retrocedimos ${value}. Es momento de analizar cuidadosamente nuestras acciones.`,
  `Lamentablemente, logramos ${value} menos que la semana anterior. Debemos tomar medidas para revertir esta situación.`,
  `No alcanzamos las metas: nuestros resultados bajaron en ${value}. Necesitamos un plan de recuperación.`,
  `Esta semana no fue buena, caímos ${value} respecto a la anterior. Debemos pensar en nuevas tácticas.`,
  `Registramos una pérdida de ${value} en comparación con la semana previa. Es necesario un cambio de rumbo.`,
  `La disminución fue significativa: ${value} menos que antes. Hay que tomar acción inmediata.`,
  `Esta vez no mejoramos, nos quedamos con ${value} menos que la semana pasada. Debemos replantearnos el enfoque.`,
]

const positiveMessages = (value: string) => [
  `Genial Equipo! Logramos ganar ${value} más esta semana, que la anterior.`,
  '¡Estamos en el camino correcto, sigamos adelante!',
  `¡Excelente trabajo, equipo! Esta semana obtuvimos ${value} más que la semana pasada. ¡Sigamos sumando esfuerzos y alcanzaremos nuestras metas!`,
  `¡Buen progreso! Conseguimos superar el resultado anterior por ${value}. Mantengamos este ritmo y seguiremos creciendo.`,
  `¡Grandes noticias! Esta vez logramos superar las ganancias de la semana anterior en ${value}. ¡Continuemos con esta energía positiva!`,
  `¡Felicidades! Hemos incrementado nuestro rendimiento semanal en ${value}. ¡No bajemos la guardia, sigamos avanzando!`,
  `¡Lo estamos logrando! Esta semana aumentamos nuestras ganancias en ${value} comparado con la anterior. ¡Podemos ir por más!`,
  `¡Enhorabuena equipo! Nuestros resultados crecieron ${value} respecto a la semana previa. ¡Continuemos con esta tendencia positiva!`,
  `¡Gran esfuerzo! Aumentamos en ${value} nuestras cifras en comparación con la semana pasada. ¡Esto demuestra que vamos por el camino correcto!`,
  `¡Impresionante! Logramos subir nuestro rendimiento semanal en ${value}. ¡A seguir impulsando y mejorando!`,
  `¡Muy bien! Hemos superado la marca anterior en ${value}. ¡Continuemos trabajando en equipo para mejorar aún más!`,
  `¡Excelente avance! Esta semana obtuvimos ${value} más que la anterior. ¡Juntos lograremos metas cada vez más altas!`,
]

export const getRandomMessage = (value: string, isPositive = false) => {
  const messages = isPositive
    ? positiveMessages(value)
    : negativeMessages(value)

  const randomIndex = Math.floor(Math.random() * messages.length)

  return messages[randomIndex]
}
