import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Tab } from '@mercai/clever'

import styles from './finance-form.module.css'
import type { FinanceFormProps } from './finance-form.type'
import type { SaleOrderProps } from '@/types'

import { FinanceInvoicesTab, FinancePaymentTab } from './components'

export const FinanceForm = ({
  onChange,
  values = {} as SaleOrderProps,
  onSuccessUploadFiles,
}: FinanceFormProps) => {
  const { t } = useTranslation('componentOrderCenterForm')

  const [tab, setTab] = useState('payments')

  const tabsContent: Record<string, React.ReactNode> = {
    payments: (
      <FinancePaymentTab
        onChange={onChange}
        values={values}
        onSuccessUploadFiles={onSuccessUploadFiles}
      />
    ),
    invoices: (
      <FinanceInvoicesTab
        onChange={onChange}
        values={values}
        onSuccessUploadFiles={onSuccessUploadFiles}
      />
    ),
  }

  return (
    <div className={styles.core}>
      <Tab
        items={[
          {
            label: t('financeTabPayments'),
            value: 'payments',
          },
          {
            label: t('financeTabInvoices'),
            value: 'invoices',
          },
        ]}
        onChange={(value) => setTab(value)}
        value={tab}
      />

      {tabsContent[tab]}
    </div>
  )
}
