import styles from './avatar.module.css'

const avatarSize = {
  small: styles.small,
  large: styles.large,
}

export const generateAvatarClassName = (size: 'small' | 'large') => {
  return `${styles.avatar} ${avatarSize[size]}`
}
