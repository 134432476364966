import styles from './table-columns.module.css'

export const expandableIcon: Record<string, string> = {
  open: styles['body-column-expandable-button-state-open'],
  close: '',
}

export const generateClassesExpandableIcon = (
  isOpen: boolean,
  className?: string,
) => {
  return `${styles['body-column-expandable-button']} ${
    expandableIcon[isOpen ? 'open' : 'close']
  } ${className || ''}`
}

export const expandableRow: Record<string, string> = {
  open: styles['body-column-expandable-row-state-open'],
  close: '',
}

export const generateClassesExpandableRow = (
  isOpen: boolean,
  className?: string,
) => {
  return `${className || ''} ${styles['body-column-expandable-row']} ${
    expandableRow[isOpen ? 'open' : 'close']
  }`
}
