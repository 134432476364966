import { useMutation } from '@tanstack/react-query'
import { convertObjectToCamelCase, convertObjectToSnakeCase } from '@/helpers'

import { FinanceProfileProps } from '@/types'

import { QUERIES_ADMIN } from './api.constants'
import { api } from './api.service'
import { FINANCE_PROFILE_URL, FINANCE_PROFILES_URL } from './api.urls'

export const useCreateFinanceProfile = () => {
  const createFinanceProfile = async (
    payload: Partial<FinanceProfileProps>,
  ): Promise<{
    success: boolean
    message: string
    data?: FinanceProfileProps
  }> => {
    try {
      const { data } = await api.post(
        FINANCE_PROFILES_URL,
        convertObjectToSnakeCase(payload),
      )

      return {
        success: true,
        message: 'Finance profile created successfully!',
        data: convertObjectToCamelCase(data) as FinanceProfileProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to create finance profile!',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.FINANCE_PROFILE.module],
    mutationFn: (payload: Partial<FinanceProfileProps>) =>
      createFinanceProfile(payload),
  })
}

export const useUpdateFinanceProfile = (profileUuid?: string) => {
  const updateFinanceProfile = async (
    payload: Partial<FinanceProfileProps>,
  ): Promise<{
    success: boolean
    message: string
    data?: FinanceProfileProps
  }> => {
    try {
      const { data } = await api.patch(
        FINANCE_PROFILE_URL(profileUuid || ''),
        convertObjectToSnakeCase(payload),
      )

      return {
        success: true,
        message: 'Finance profile updated successfully!',
        data: convertObjectToCamelCase(data) as FinanceProfileProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to update finance profile!',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.FINANCE_PROFILE.module],
    mutationFn: (payload: Partial<FinanceProfileProps>) =>
      updateFinanceProfile(payload),
  })
}
