import { useTranslation } from 'react-i18next'

import { Alert, AlertIcon, WarningIcon } from '@mercai/clever'

export const AlertSmartSearch = ({
  smartSearchUUID,
  filterReference,
}: {
  smartSearchUUID: string | null | undefined
  filterReference: string | undefined
}) => {
  const { t } = useTranslation('screenCatalogProduct')

  return (
    <>
      {smartSearchUUID && (
        <Alert leftIcon={<WarningIcon />} variant="warning">
          {t('smartSearchFormProcessing')}
        </Alert>
      )}
      {smartSearchUUID === null && (
        <Alert leftIcon={<WarningIcon />} variant="danger">
          {t('smartSearchFormNotFound')}
        </Alert>
      )}
      {!smartSearchUUID && filterReference && (
        <Alert leftIcon={<AlertIcon />} variant="primary">
          {`${t('smartSearchFormProcessed')} ${filterReference.replace(',', ', ')}.`}
        </Alert>
      )}
    </>
  )
}
