import { Button, Checkbox, Label } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import type { RouteFormProps } from './routes-form.type'

import styles from './routes-form.module.css'
import {
  TransportCompanyRouteResponseProps,
  TransportCompanyProps,
} from '@/types'
import { deepCopy } from '@/helpers'

const Root = ({
  onChangeValues,
  values,
  transportCompanyRoutes = [],
}: RouteFormProps) => {
  const { t } = useTranslation('screenTransportCompanies')

  const onHandleValues = (
    value: TransportCompanyRouteResponseProps[] | undefined,
  ) => {
    onChangeValues({ ...values, transportCompanyRoutes: value })
  }

  const onChange = (index: number, isChecked: boolean) => {
    const valuesAux: Partial<TransportCompanyProps> = deepCopy(values)

    let transportCompanyRoutesArray = Array.isArray(
      valuesAux.transportCompanyRoutes,
    )
      ? valuesAux.transportCompanyRoutes
      : ([] as TransportCompanyRouteResponseProps[])

    if (isChecked) {
      const newObject = {
        logisticRouteUuid: transportCompanyRoutes[index].uuid,
        logisticRoute: transportCompanyRoutes[index],
      } as TransportCompanyRouteResponseProps

      transportCompanyRoutesArray.push(newObject)
    } else {
      transportCompanyRoutesArray = transportCompanyRoutesArray?.filter(
        ({ logisticRoute, logisticRouteUuid }) =>
          (logisticRouteUuid &&
            transportCompanyRoutes[index].uuid !== logisticRouteUuid) ||
          (logisticRoute?.uuid &&
            transportCompanyRoutes[index].uuid !== logisticRoute?.uuid),
      )
    }

    onHandleValues(transportCompanyRoutesArray)
  }

  return (
    <div className={styles.core}>
      <div>
        <Label>{t('routesFormRouteLabel')}</Label>
        {transportCompanyRoutes?.map((route, index) => {
          const isChecked = !!values?.transportCompanyRoutes?.find(
            ({ logisticRoute, logisticRouteUuid }) =>
              route.uuid === logisticRoute?.uuid ||
              route.uuid === logisticRouteUuid,
          )
          return (
            <Checkbox
              value={isChecked}
              key={route.uuid}
              onChange={() => onChange(index, !isChecked)}
            >
              {route.name}
            </Checkbox>
          )
        })}
      </div>
    </div>
  )
}

const Footer = ({
  onNavigateToPreviousStep,
  onNavigateToNextStep,
  values,
}: {
  onNavigateToPreviousStep: () => void
  onNavigateToNextStep: () => void
  values: Partial<TransportCompanyProps>
}) => {
  const { t } = useTranslation('screenTransportCompanies')
  return (
    <>
      <Button variant="secondary" onClick={() => onNavigateToPreviousStep()}>
        {t('drawerFormReturnButton')}
      </Button>

      <Button
        onClick={onNavigateToNextStep}
        disabled={
          !values?.transportCompanyRoutes ||
          values?.transportCompanyRoutes?.length === 0
        }
      >
        {t('drawerFormNextButton')}
      </Button>
    </>
  )
}

export const RouteForm = {
  Root,
  Footer,
}
