import { ChevronDownIcon, ChevronUpIcon, Text } from '@mercai/clever'
import { useTranslation } from 'react-i18next'
import { Card } from '../card'
import styles from './dollar-exchange-rate.module.css'

import { DollarExchangeRateProps } from './dollar-exchange-rate.type'
import { formatCurrency } from '@/helpers'
import { calculatePercentageChange } from './dollar-exchange-rate.constants'
import { LineChart } from './components/line-chart'

export const DollarExchangeRate = ({ values }: DollarExchangeRateProps) => {
  const { t } = useTranslation('componentsDollarExchangeRate')

  const percentage = calculatePercentageChange(
    Number(values?.[1]?.value || 0),
    Number(values?.[0]?.value || 0),
  )
  const isNegative = !!(+percentage < 0)

  return (
    <div className={styles.wrapper}>
      <Text variant="title-medium">
        <strong>{t('title')}</strong>
      </Text>
      <Card className={styles.content}>
        <Text variant="title-large">{t('label')}</Text>
        <div className={styles.chart}>
          <LineChart data={values} />
        </div>
        <div className={styles.dollar}>
          <Text variant="title-base">
            MX$ {formatCurrency(values?.[0]?.value, 'ex-MX')}
          </Text>
          {isNegative ? (
            <Text variant="text-xsmall" className={styles.negative}>
              <ChevronDownIcon />
              {percentage}%
            </Text>
          ) : (
            <Text variant="text-xsmall" className={styles.positive}>
              <ChevronUpIcon />
              {percentage}%
            </Text>
          )}
        </div>
      </Card>
    </div>
  )
}
