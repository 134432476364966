import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Pagination,
  Select,
  TableColumns,
  TableColumnsColumnProps,
  Text,
} from '@mercai/clever'
import { TransportCompanyRouteResponseProps } from '@/types'
import { formatCurrency, useParamsState } from '@/helpers'
import { useRouteList, useTransporCompanyRoutesList } from '@/services'
import { DEFAULT_LIMIT_PAGE } from '@/constants'

import { TransportCompanyRoutesTableProps } from './transport-company-routes-table.types'

import styles from './transport-company-routes-table.module.css'

export const TransportCompanyRoutesTable = ({
  uuid,
}: TransportCompanyRoutesTableProps) => {
  const { t } = useTranslation('screenTransportCompanies')

  const [filter, setFilter] = useParamsState({
    transport_company__uuid: uuid,
    page: '1',
    limit: `${DEFAULT_LIMIT_PAGE}`,
    logistic_route__uuid: 'all',
  })

  const [totalPages, setTotalPages] = useState(1)

  const handleFilter = (changedKey: string, newValue: string) => {
    setFilter({ ...filter, [changedKey]: newValue })
  }

  const { data, isLoading } = useTransporCompanyRoutesList({
    params: {
      ...filter,
      logistic_route__uuid:
        filter.logistic_route__uuid !== 'all'
          ? filter.logistic_route__uuid
          : '',
    },
  })
  const { data: dataRoutes } = useRouteList({})

  const columns: TableColumnsColumnProps<TransportCompanyRouteResponseProps>[] =
    [
      {
        title: t('transportCompaniesRoutesTableName'),
        key: 'name',
        dataKey: 'logisticRoute',
        render: ({ record }) => record?.logisticRoute?.name,
      },
      {
        title: t('transportCompaniesRoutesTableVehicle'),
        key: 'vehicle.name',
        dataKey: 'vehicle.name',
        width: '12rem',
        render: ({ record }) => record?.vehicle?.name,
      },
      {
        title: t('transportCompaniesRoutesTableCapacity'),
        key: 'vehicle.capacity',
        dataKey: 'vehicle.capacity',
        width: '12rem',
        render: ({ record }) => record?.vehicle?.capacity,
      },
      {
        title: t('transportCompaniesRoutesTablePrice'),
        key: 'price',
        dataKey: 'price',
        width: '12rem',
        render: ({ record }) =>
          formatCurrency(record?.price || '', 'es-MX', 'MXN'),
      },
    ]

  useEffect(() => {
    if (data?.data?.count) {
      setTotalPages(Math.ceil(data?.data?.count / +filter.limit))
    }
  }, [data])

  return (
    <div>
      <Text className={styles.title} variant="title-large">
        {t('transportCompaniesRoutesTitle')}
      </Text>
      <div className={styles.filters}>
        <Select
          options={[
            {
              label: t('transportCompaniesRoutesFilterRoutes'),
              value: 'all',
            },
            ...(dataRoutes?.data?.results?.map((route) => ({
              label: route.name,
              value: route.uuid,
            })) || []),
          ]}
          value={filter.logistic_route__uuid}
          onChange={(value) => handleFilter('logistic_route__uuid', value)}
        />
      </div>
      <TableColumns<TransportCompanyRouteResponseProps>
        isLoading={isLoading}
        columns={columns}
        data={data?.data?.results || []}
      />
      <div className={styles.pagination}>
        <div className={styles['pagination-show-registers']}>
          <Text>
            {t('tableShowRegisters', {
              showNumber:
                (data?.data?.count || 0) < +filter.limit
                  ? data?.data?.count
                  : +filter.limit,
              totalNumber: data?.data?.count || 0,
            })}
          </Text>

          <Select
            value={filter.limit}
            onChange={(value) => handleFilter('limit', value)}
            options={[
              { label: '10', value: '10' },
              { label: '20', value: '20' },
              { label: '50', value: '50' },
              { label: '100', value: '100' },
            ]}
          />
        </div>

        <Pagination
          totalPages={totalPages}
          onChange={(page) => handleFilter('page', `${page}`)}
          currentPage={+filter.page as number}
        />
      </div>
    </div>
  )
}
