import { Button, Input, InputPhone, Label } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import type {
  TransportCompanyFormFooterProps,
  TransportCompanyFormProps,
} from './transport-company-form.type'

import styles from './transport-company-form.module.css'

const Root = ({
  onChangeValues,
  values,
  validation,
}: TransportCompanyFormProps) => {
  const { t } = useTranslation('screenTransportCompanies')

  const { errors } = validation

  const onHandleValues = (key: string, value: string | number | boolean) => {
    onChangeValues({ ...values, [key]: value })
  }

  return (
    <div className={styles.core}>
      <div>
        <Label>{t('transportCompanyFormNameLabel')}</Label>
        <Input
          placeholder={t('transportCompanyFormNamePlaceholder')}
          value={values?.name}
          onChange={(newValue) => onHandleValues('name', newValue)}
          error={errors?.name as string}
        />
      </div>

      <div>
        <Label>{t('transportCompanyFormEmailLabel')} </Label>
        <Input
          placeholder={t('transportCompanyFormEmailPlaceholder')}
          value={values?.email}
          onChange={(newValue) => onHandleValues('email', newValue)}
          error={errors?.email as string}
        />
      </div>

      <div>
        <Label>{t('transportCompanyFormPhoneLabel')}</Label>
        <InputPhone
          value={values?.phone}
          onChange={(newValue) => onHandleValues('phone', newValue)}
          error={errors?.phone as string}
        />
      </div>
    </div>
  )
}

const Footer = ({
  onNavigateToNextStep,
  validation,
}: TransportCompanyFormFooterProps) => {
  const { t } = useTranslation('screenTransportCompanies')

  const { isValid, onHandleListenErrors } = validation

  const onHandleNavigateToNextStep = () => {
    if (!isValid) {
      onHandleListenErrors(true)
      return
    }

    onHandleListenErrors(false)
    onNavigateToNextStep()
  }

  return (
    <>
      <Button onClick={onHandleNavigateToNextStep}>
        {t('drawerFormNextButton')}
      </Button>
    </>
  )
}

export const TransportCompanyForm = {
  Root,
  Footer,
}
