import * as Yup from 'yup'
import {
  shemaProductItemCatalogProduct,
  shemaProductItemNonCatalogProduct,
} from './components/product-form/product-form.constants'

export const shemaAddressItem = (t: (key: string) => string) => ({
  postalCode: Yup.string().required(t('requiredMessage')),
  state: Yup.string().required(t('requiredMessage')),
  city: Yup.string().required(t('requiredMessage')),
  neighborhood: Yup.string().required(t('requiredMessage')),
  street: Yup.string().required(t('requiredMessage')),
  number: Yup.string().required(t('requiredMessage')),
})

export const schemaProductList = (t: (key: string) => string) => ({
  products: Yup.array()
    .of(
      Yup.lazy((value) => {
        if (value?.catalogProduct?.uuid) {
          return Yup.object().shape(shemaProductItemCatalogProduct(t))
        } else {
          return Yup.object().shape(shemaProductItemNonCatalogProduct(t))
        }
      }),
    )
    .min(1, t('quotationFormProductListRequiredMessage'))
    .required(t('quotationFormProductListRequiredMessage')),
})

export const schemaProfileItem = (t: (key: string) => string) => ({
  email: Yup.string().required(t('requiredMessage')),
  companyName: Yup.string().required(t('requiredMessage')),
  phone: Yup.string().required(t('requiredMessage')),
})
