import { Text } from '../text'
import styles from './switch.module.css'
import { SwitchProps } from './switch.types'

export const Switch = ({
  className,
  checked,
  onChange,
  error,
  disabled,
  id,
  ref,
}: SwitchProps) => {
  return (
    <div className={className}>
      <label className={styles['switch-container']}>
        <input
          ref={ref}
          id={id}
          type="checkbox"
          disabled={disabled}
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
          className={styles['switch-input']}
        />
        <span className={styles['switch-slider']}></span>
      </label>
      {!error || <Text className={styles.error}>{error}</Text>}
    </div>
  )
}
