import styles from './notification-popup.module.css'

export const statesOverlay: Record<'open' | 'close', string> = {
  open: styles['overlay-state-open'],
  close: styles['overlay-state-close'],
}

export const generateOverlayClasses = (
  state: 'open' | 'close',
  className?: string,
) => {
  return `${statesOverlay[state]} ${className || ''}`
}

export const statesMain: Record<'open' | 'close', string> = {
  open: styles['main-state-open'],
  close: styles['main-state-close'],
}

export const generateMainClasses = (
  state: 'open' | 'close',
  className?: string,
) => {
  return `${statesMain[state]}  ${className || ''}`
}
