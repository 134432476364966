import { useTranslation } from 'react-i18next'
import { Skeleton, Text } from '@mercai/clever'
import { Card } from '../card'
import { GoalProps } from './goal.type'

import styles from './goal.module.css'
import { formatCurrency, formatNumber } from '@/helpers'
import { getRandomMessage } from './goal.constants'
import { useMemo } from 'react'

export const Goal = ({
  currentGMV,
  thisWeekGMV,
  targetThisWeekGMV,
  targetGMV,
  currentCM3,
  currentGM,
  targetCM3,
  targetGM,
  isLoading,
}: GoalProps) => {
  const { t } = useTranslation('componentsGoal')

  const progress = 157 * (1 - currentGMV / targetGMV)

  const randomMessage = useMemo(
    () =>
      getRandomMessage(
        formatCurrency(thisWeekGMV, 'es-MX', 'USD'),
        targetThisWeekGMV <= thisWeekGMV,
      ),
    [thisWeekGMV, targetThisWeekGMV],
  )

  return (
    <Card>
      <div className={styles.title}>
        <Text variant="title-large">
          {formatCurrency(targetGMV, 'es-MX', 'USD')}
        </Text>
        <Text variant="text-xsmall">{t('seriesALabel')}</Text>
      </div>
      {!isLoading ? (
        <div className={styles['progress-box']}>
          <svg viewBox="0 30 120 80">
            <path
              d="M10,90 A50,50 0 1,1 110,90"
              fill="none"
              transform="translate(0.385742)"
            />
            <path
              fill="none"
              className={styles.progress}
              d="M10,90 A50,50 0 1,1 110,90"
              strokeDashoffset={progress}
            />
          </svg>
          <div className={styles['current-gmv']}>
            <Text variant="title-medium" className={styles['gmv-label']}>
              <strong>{t('gmvLabel')}</strong>
            </Text>
            <Text variant="title-large">
              <strong>{formatCurrency(currentGMV, 'es-MX', 'USD')}</strong>
            </Text>
          </div>
        </div>
      ) : (
        <div
          className={styles['progress-box']}
          style={{ paddingBottom: '18px' }}
        >
          <Skeleton height="100px" width="100%" />
        </div>
      )}
      <Text variant="text-small" className={styles['random-message']}>
        {!isLoading ? randomMessage : <Skeleton height="50px" width="100%" />}
      </Text>
      <div className={styles['gm-cm3-box']}>
        <div>
          <Text variant="title-small" className={styles['gmv-label']}>
            <strong>{t('gmLabel')}</strong>
          </Text>
          <div>
            <Text variant="title-medium">
              {!isLoading ? (
                formatNumber(currentGM)
              ) : (
                <Skeleton height="18px" width="18px" />
              )}
            </Text>
            <Text className={styles['gray-400']}>
              <strong>{t('ofLabel')}</strong>
            </Text>
            <Text variant="title-medium" className={styles['gray-400']}>
              {targetGM}%
            </Text>
          </div>
        </div>
        <div>
          <Text variant="title-small" className={styles['gmv-label']}>
            <strong>{t('cm3Label')}</strong>
          </Text>
          <div>
            <Text variant="title-medium">
              {!isLoading ? (
                formatNumber(currentCM3)
              ) : (
                <Skeleton height="18px" width="18px" />
              )}
            </Text>
            <Text className={styles['gray-400']}>
              <strong>{t('ofLabel')}</strong>
            </Text>
            <Text variant="title-medium" className={styles['gray-400']}>
              {targetCM3}%
            </Text>
          </div>
        </div>
      </div>
    </Card>
  )
}
