import { SVGProps } from 'react'

export const ForkLiftIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M4 21C3.16667 21 2.45833 20.7083 1.875 20.125C1.29167 19.5417 1 18.8333 1 18C1 17.5667 1.08333 17.1542 1.25 16.7625C1.41667 16.3708 1.66667 16.0333 2 15.75V11H4V5H12L16.7 16.075C16.8 16.3083 16.875 16.5417 16.925 16.775C16.975 17.0083 17 17.25 17 17.5C17 18.4667 16.6583 19.2917 15.975 19.975C15.2917 20.6583 14.4667 21 13.5 21C12.8167 21 12.1875 20.8208 11.6125 20.4625C11.0375 20.1042 10.6083 19.6167 10.325 19H6.825C6.60833 19.6 6.24167 20.0833 5.725 20.45C5.20833 20.8167 4.63333 21 4 21ZM18 20V4H20V18H23V20H18ZM4 19C4.28333 19 4.52083 18.9042 4.7125 18.7125C4.90417 18.5208 5 18.2833 5 18C5 17.7167 4.90417 17.4792 4.7125 17.2875C4.52083 17.0958 4.28333 17 4 17C3.71667 17 3.47917 17.0958 3.2875 17.2875C3.09583 17.4792 3 17.7167 3 18C3 18.2833 3.09583 18.5208 3.2875 18.7125C3.47917 18.9042 3.71667 19 4 19ZM13.5 19C13.9167 19 14.2708 18.8542 14.5625 18.5625C14.8542 18.2708 15 17.9167 15 17.5C15 17.0833 14.8542 16.7292 14.5625 16.4375C14.2708 16.1458 13.9167 16 13.5 16C13.0833 16 12.7292 16.1458 12.4375 16.4375C12.1458 16.7292 12 17.0833 12 17.5C12 17.9167 12.1458 18.2708 12.4375 18.5625C12.7292 18.8542 13.0833 19 13.5 19ZM6.825 17H10.025C10.0583 16.8167 10.0958 16.6458 10.1375 16.4875C10.1792 16.3292 10.2417 16.1667 10.325 16H8.075L5.15 13H4V15C4.63333 15 5.20833 15.1833 5.725 15.55C6.24167 15.9167 6.60833 16.4 6.825 17ZM8.925 14H13.65L10.675 7H6V11L8.925 14ZM8.075 16L7.6125 15.525C7.30417 15.2083 6.97083 14.8625 6.6125 14.4875C6.25417 14.1125 5.92083 13.7708 5.6125 13.4625L5.15 13L8.075 16Z" />
    </svg>
  )
}
