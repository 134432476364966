import { useParams } from 'react-router-dom'
import { useCatalogProductDetail } from '@/services'
import { CatalogProductDetail } from './components/detail'
import { CatalogProductProps } from '@/types'

import styles from './show.module.css'
import { Button, ChevronLeftIcon, Text } from '@mercai/clever'
import { ROUTES_ADMIN } from '@/router'
import { useTranslation } from 'react-i18next'
import { DrawerForm } from '../components'
import { useParamsState } from '@/helpers'
import { SupplierProductTable } from './components/supplier-product-table'

export const ProductShow = () => {
  const { uuid } = useParams()

  const { t } = useTranslation('screenCatalogProduct')

  const [queryParams, setQueryParams] = useParamsState({
    form: '',
  })

  const handleEditCatalogProduct = () => {
    setQueryParams({
      form: 'true',
    })
  }

  const { data: catalogProductData, isLoading: catalogProductIsLoading } =
    useCatalogProductDetail(uuid)

  return (
    <div className={styles.core}>
      <section className={styles['header-section']}>
        <div className={styles['header-left-sidebar']}>
          <a href={`/${ROUTES_ADMIN.CATALOG.PRODUCT.path}`}>
            <ChevronLeftIcon />
          </a>
          <div>
            <Text>
              <strong>{`${t('title')} / `}</strong>
              <Text>{catalogProductData?.data?.reference}</Text>
            </Text>
            <div className={styles.title}>
              <Text variant="title-large">
                {catalogProductData?.data?.attributes?.title}
              </Text>
            </div>
          </div>
        </div>
        <Button variant="secondary" onClick={() => handleEditCatalogProduct()}>
          {t('detailEditButton')}
        </Button>
      </section>
      <CatalogProductDetail
        catalogProduct={catalogProductData?.data as CatalogProductProps}
        isLoading={catalogProductIsLoading}
      />

      <SupplierProductTable uuid={uuid || ''} />

      <DrawerForm
        isOpen={!!queryParams.form}
        onHandleOpen={() =>
          setQueryParams({
            form: '',
          })
        }
        catalogProductUuid={uuid}
      />
    </div>
  )
}
