import { useTranslation } from 'react-i18next'
import styles from './metrics.module.css'

import { MetricsProps } from './metrics.type'
import { MetricsCard } from '../metrics-card'
import { formatCurrency, formatNumber } from '@/helpers'

export const Metrics = ({ isLoading, values }: MetricsProps) => {
  const { t } = useTranslation('saleBoardScreen')

  const getPercentage = (value: number = 0) => {
    return +formatNumber(value * 100)
  }

  return (
    <div className={styles.metrics}>
      <MetricsCard
        variant="rfq_attended"
        value={`${getPercentage(values?.processedRfqsPercentage || 0)}%`}
        tooltipText={t('metricsRfqAttendedTooltip')}
        isLoading={isLoading}
      />

      <MetricsCard
        variant="gmv_acquisition"
        value={`${formatCurrency(values?.acquisitionGmv || 0, 'ex-MX', 'USD')}`}
        tooltipText={t('metricsGmvAcquisitionTooltip')}
        isLoading={isLoading}
      />

      <MetricsCard
        variant="gmv_paid"
        value={`${formatCurrency(values?.payedGmv || 0, 'ex-MX', 'USD')}`}
        tooltipText={t('metricsGmvPaidTooltip')}
        isLoading={isLoading}
      />

      <MetricsCard
        variant="orders_delivered"
        value={`${values?.deliveredOrders || 0}`}
        tooltipText={t('metricsOrdersDeliveredTooltip')}
        isLoading={isLoading}
      />

      <MetricsCard
        variant="active_clients"
        value={`${values?.activeClients || 0}`}
        tooltipText={t('metricsActiveClientsTooltip')}
        isLoading={isLoading}
      />
    </div>
  )
}
