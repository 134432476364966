import { ProfileProps } from './profiles.type'
import { AddressProps } from './address.type'
import { CatalogProductProps } from './catalog-product.type'
import { QuotationProps } from './quotation.type'

export enum QuotationRequestItemCategoryEnum {
  'pp',
  'pe',
  'pc',
  'abs',
  'eva',
  'ps',
  'tpe',
  'pet',
  'pvc',
  'pa',
}

export interface QuotationRequestItemProductProps {
  title: string
  density: number
  fluidity: number
  category: string
  size: string
  quantityPerPackage: string
  itemDataSheetPath: string
}

export interface QuotationRequestItemProps {
  uuid: string
  product: Partial<QuotationRequestItemProductProps>
  catalogProductUuid?: string
  catalogProduct?: CatalogProductProps
  quantity: number
  targetPrice: number
  purchaseFrequency: string
  observation: string
  isSimilarAccepted: boolean
}

export enum QuotationRequestStatusEnum {
  'pending' = 'pending',
  'processed' = 'processed',
  'completed' = 'completed',
  'expired' = 'expired',
  'invalid' = 'invalid',
  'disqualified' = 'disqualified',
  'approved_for_quotation' = 'approved_for_quotation',
}

export enum QuotationRequestOriginEnum {
  'quotation_request',
  'catalog',
  'backoffice_quotation_request',
  'open_quotation_request',
  'open_catalog',
  'product_match',
}

export interface QuotationRequestRefusedByProps {
  id: string
  name: string
  email: string
  phone: string
  isActive: string
  allowEmail: string
  allowWhatsapp: string
}

export interface QuotationRequestProps {
  uuid: string
  file: string
  filePath: string
  reference: string
  status: keyof typeof QuotationRequestStatusEnum
  address: Partial<AddressProps>
  addressUuid: string
  items: Partial<QuotationRequestItemProps>[] | QuotationRequestItemProps[]
  origin: keyof typeof QuotationRequestOriginEnum
  quotations?: QuotationProps[]
  createdAt: string
  updatedAt: string
  buyerProfile: ProfileProps
  buyerProfileUuid: string
  creatorUser: {
    name: string
    email: string
  }
  refusedReason?: string
  refusedBy?: QuotationRequestRefusedByProps
}

export interface screenQuotationRequestListWithPagesProps {
  count: number
  next?: string
  previous?: string
  results?: QuotationRequestProps[]
}
