import { useEffect, useState } from 'react'
import * as yup from 'yup'

import {
  Button,
  DragDropFileList,
  Label,
  Input,
  InputNumber,
  Select,
  Text,
  FileListFileProps,
} from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import {
  onSuccessMessage,
  onErrorMessage,
  getFileNameAndExtension,
  useValidationErrors,
} from '@/helpers'

import {
  api,
  useUpdateFinanceProfile,
  useCreateFinanceProfile,
  FINANCE_PROFILE_URL,
} from '@/services'

import styles from './form.module.css'

import type { ProfileCreditFormProps } from './form.type'

import {
  FinanceProfileProps,
  FinanceProfileCreditStatusEnum,
  FinanceProfileCreditGatewayEnum,
  FinanceProfilePersonTypeEnum,
} from '@/types'

export const ProfileCreditForm = ({
  onHandleOpen,
  profileUuid,
  onSuccessUpdated,
  financeProfileData,
  onHandleShow,
}: ProfileCreditFormProps) => {
  const { t } = useTranslation('componentsFinanceProfileForm')
  const { t: tEnums } = useTranslation('enums')
  const { t: tDefaults } = useTranslation('defaults')

  const [values, setValues] = useState<Partial<FinanceProfileProps>>({})

  const { errors, isValid, onHandleListenErrors } = useValidationErrors(
    {
      creditStatus: yup.string().required(tDefaults('fieldRequired')),
      creditGateway: yup.string().required(tDefaults('fieldRequired')),
      totalCreditAmount: yup.number().required(tDefaults('fieldRequired')),
      personType: yup.string().required(tDefaults('fieldRequired')),
    },
    values,
  )

  const [dataFilesGovernmentId, setDataFilesGovernmentId] = useState<
    FileListFileProps[]
  >([])
  const [dataFilesTaxSituation, setDataFilesTaxSituation] = useState<
    FileListFileProps[]
  >([])
  const [dataFilesAddressProof, setDataFilesAddressProof] = useState<
    FileListFileProps[]
  >([])
  const [dataFilesConstitutiveAct, setDataFilesConstitutiveAct] = useState<
    FileListFileProps[]
  >([])

  const onHandleValues = (key: string, value: string | number) => {
    setValues((prev) => ({ ...prev, [key]: value }))
  }

  const { mutateAsync: mutateAsyncCreate, isPending: isLoadingCreate } =
    useCreateFinanceProfile()
  const { mutateAsync: mutateAsyncUpdate, isPending: isLoadingUpdate } =
    useUpdateFinanceProfile(profileUuid)

  const isNewRecord = !financeProfileData?.createdAt

  const onHandleSubmit = async () => {
    if (!isValid) {
      onHandleListenErrors(true)
      return
    }

    const mutateAsync = isNewRecord ? mutateAsyncCreate : mutateAsyncUpdate

    let payload: Partial<FinanceProfileProps> = {
      creditStatus: values.creditStatus,
      creditGateway: values.creditGateway,
      totalCreditAmount: values.totalCreditAmount,
      personType: values.personType,
      creditBureau:
        values.creditGateway === 'internal' ? 'black_trust' : undefined,
      creditBureauUrl:
        values.creditGateway === 'internal'
          ? values.creditBureauUrl
          : undefined,
    }

    if (isNewRecord) {
      payload = {
        ...payload,
        profileUuid,
        upfrontPaymentPercentage: values?.upfrontPaymentPercentage || 1,
      }
    }

    const response = await mutateAsync(payload)

    if (response.success) {
      onSuccessMessage(
        isNewRecord
          ? t('profileCreditSuccessTitle')
          : t('profileCreditSuccessUpdateTitle'),
        isNewRecord
          ? t('profileCreditSuccessUpdateMessage')
          : t('profileCreditSuccessUpdateMessage'),
      )

      if (!isNewRecord) {
        onHandleOpen(false)
      }

      if (onSuccessUpdated) {
        onSuccessUpdated()
      }

      onHandleListenErrors(false)
    } else {
      onErrorMessage(
        t('profileCreditErrorTitle'),
        t('profileCreditErrorUpdateMessage'),
      )
    }
  }

  useEffect(() => {
    if (financeProfileData) {
      setValues(financeProfileData)
    }

    if (financeProfileData?.governmentIdFile) {
      const file = getFileNameAndExtension(
        financeProfileData?.governmentIdFile || '',
      )

      setDataFilesGovernmentId([
        {
          id: file.fileName,
          name: `${file.fileName}.${file.extension}`,
          url: financeProfileData?.governmentIdFile,
          preview: financeProfileData?.governmentIdFile,
          uploaded: true,
        },
      ])
    }

    if (financeProfileData?.taxSituation) {
      const file = getFileNameAndExtension(
        financeProfileData?.taxSituation || '',
      )

      setDataFilesTaxSituation([
        {
          id: file.fileName,
          name: `${file.fileName}.${file.extension}`,
          url: financeProfileData?.taxSituation,
          preview: financeProfileData?.taxSituation,
          uploaded: true,
        },
      ])
    }

    if (financeProfileData?.addressProofFile) {
      const file = getFileNameAndExtension(
        financeProfileData?.addressProofFile || '',
      )

      setDataFilesAddressProof([
        {
          id: file.fileName,
          name: `${file.fileName}.${file.extension}`,
          url: financeProfileData?.addressProofFile,
          preview: financeProfileData?.addressProofFile,
          uploaded: true,
        },
      ])
    }

    if (financeProfileData?.constitutiveActFile) {
      const file = getFileNameAndExtension(
        financeProfileData?.constitutiveActFile || '',
      )

      setDataFilesConstitutiveAct([
        {
          id: file.fileName,
          name: `${file.fileName}.${file.extension}`,
          url: financeProfileData?.constitutiveActFile,
          preview: financeProfileData?.constitutiveActFile,
          uploaded: true,
        },
      ])
    }
  }, [financeProfileData])

  return (
    <>
      <div className={styles.content}>
        <Text variant="text-large-bold">{t('profileCreditGeneralInfo')}</Text>
        <div className={styles['content-group']}>
          <div>
            <Label>{t('profileCreditStatusLabel')}</Label>
            <Select
              options={Object.keys(FinanceProfileCreditStatusEnum).map(
                (financeProfileKey) => ({
                  label: tEnums(`profile_credit_status_${financeProfileKey}`),
                  value: financeProfileKey,
                }),
              )}
              value={values.creditStatus}
              onChange={(value) => onHandleValues('creditStatus', value)}
              placeholder={t('profileCreditStatusPlaceholder')}
              error={errors?.creditStatus as string}
            />
          </div>

          <div>
            <Label>{t('profileCreditGatewayLabel')}</Label>
            <Select
              options={Object.keys(FinanceProfileCreditGatewayEnum).map(
                (financeProfileKey) => ({
                  label: tEnums(`profile_credit_gateway_${financeProfileKey}`),
                  value: financeProfileKey,
                }),
              )}
              value={values.creditGateway}
              onChange={(value) => onHandleValues('creditGateway', value)}
              placeholder={t('profileCreditGatewayPlaceholder')}
              error={errors?.creditGateway as string}
            />
          </div>

          {values.creditGateway === 'internal' && (
            <div>
              <Label>{t('profileCreditCreditBureauUrlLabel')}</Label>
              <Input
                value={values.creditBureauUrl}
                onChange={(value) => onHandleValues('creditBureauUrl', value)}
                placeholder={t('profileCreditCreditBureauUrlPlaceholder')}
                error={errors?.creditBureauUrl as string}
              />
            </div>
          )}

          <div>
            <Label>{t('profileCreditTotalCreditAmountLabel')}</Label>
            <InputNumber
              value={values.totalCreditAmount}
              onChange={(value) => onHandleValues('totalCreditAmount', value)}
              min={0}
              prefix="$ "
              showIcons
              placeholder={t('profileCreditTotalCreditAmountPlaceholder')}
              error={errors?.totalCreditAmount as string}
            />
          </div>

          <div>
            <Label>{t('profileCreditPersonTypeLabel')}</Label>
            <Select
              options={Object.keys(FinanceProfilePersonTypeEnum).map(
                (financeProfileKey) => ({
                  label: tEnums(
                    `profile_credit_person_type_${financeProfileKey}`,
                  ),
                  value: financeProfileKey,
                }),
              )}
              value={values.personType}
              onChange={(value) => onHandleValues('personType', value)}
              placeholder={t('profileCreditPersonTypePlaceholder')}
              error={errors?.personType as string}
            />
          </div>
        </div>

        {financeProfileData?.createdAt && values?.personType && (
          <>
            <Text variant="text-large-bold">{t('profileCreditDocuments')}</Text>
            <div className={styles['content-group']}>
              <div>
                <Label>
                  {values?.personType ===
                  FinanceProfilePersonTypeEnum.individual
                    ? t('profileCreditGovernmentIdLabel')
                    : t('profileCreditLegalRepresentativeIdLabel')}
                </Label>

                <DragDropFileList
                  translations={{
                    clickToUpload: t('uploadButton'),
                    dragFile: t('uploadDrag'),
                    sizeFile: t('uploadSize'),
                  }}
                  urlAdd={FINANCE_PROFILE_URL(profileUuid)}
                  configsRequest={{
                    fileField: 'government_id_file',
                    method: 'PATCH',
                  }}
                  data={dataFilesGovernmentId}
                  onChangeData={setDataFilesGovernmentId}
                  axiosInstance={api}
                  successedUpload={onSuccessUpdated}
                />
              </div>

              <div>
                <Label>{t('profileCreditTaxSituationLabel')}</Label>
                <DragDropFileList
                  translations={{
                    clickToUpload: t('uploadButton'),
                    dragFile: t('uploadDrag'),
                    sizeFile: t('uploadSize'),
                  }}
                  urlAdd={FINANCE_PROFILE_URL(profileUuid)}
                  configsRequest={{
                    fileField: 'tax_situation',
                    method: 'PATCH',
                  }}
                  data={dataFilesTaxSituation}
                  onChangeData={setDataFilesTaxSituation}
                  axiosInstance={api}
                  successedUpload={onSuccessUpdated}
                />
              </div>

              <div>
                <Label>{t('profileCreditAddressProofLabel')}</Label>
                <DragDropFileList
                  translations={{
                    clickToUpload: t('uploadButton'),
                    dragFile: t('uploadDrag'),
                    sizeFile: t('uploadSize'),
                  }}
                  urlAdd={FINANCE_PROFILE_URL(profileUuid)}
                  configsRequest={{
                    fileField: 'address_proof_file',
                    method: 'PATCH',
                  }}
                  data={dataFilesAddressProof}
                  onChangeData={setDataFilesAddressProof}
                  axiosInstance={api}
                  successedUpload={onSuccessUpdated}
                />
              </div>

              {values?.personType === FinanceProfilePersonTypeEnum.legal && (
                <div>
                  <Label>{t('profileCreditConstitutiveActLabel')}</Label>
                  <DragDropFileList
                    translations={{
                      clickToUpload: t('uploadButton'),
                      dragFile: t('uploadDrag'),
                      sizeFile: t('uploadSize'),
                    }}
                    urlAdd={FINANCE_PROFILE_URL(profileUuid)}
                    configsRequest={{
                      fileField: 'constitutive_act_file',
                      method: 'PATCH',
                    }}
                    data={dataFilesConstitutiveAct}
                    onChangeData={setDataFilesConstitutiveAct}
                    axiosInstance={api}
                    successedUpload={onSuccessUpdated}
                  />
                </div>
              )}
            </div>
          </>
        )}
      </div>
      <div className={styles.footer}>
        <Button variant="secondary" onClick={() => onHandleOpen(false)}>
          {t('profileCreditButtonClose')}
        </Button>
        <Button onClick={onHandleSubmit}>
          {isLoadingUpdate || isLoadingCreate
            ? t('profileCreditButtonSubmitLoading')
            : t('profileCreditButtonSubmit')}
        </Button>
        {!isNewRecord && (
          <Button onClick={onHandleShow} variant="light">
            {t('profileCreditButtonShow')}
          </Button>
        )}
      </div>
    </>
  )
}
