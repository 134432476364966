import { useTranslation } from 'react-i18next'
import {
  Text,
  Skeleton,
  Accordion,
  MonetizationIcon,
  CheckIcon,
} from '@mercai/clever'

import styles from './summary-payment-condition.module.css'
import type { QuotationSummaryPaymentConditionProps } from './summary-payment-condition.type'
import { useState } from 'react'

const QuotationSummaryPaymentConditionDefault = ({
  financeProfileData,
}: QuotationSummaryPaymentConditionProps) => {
  const { t } = useTranslation('screenQuotationList')
  const { t: tEnums } = useTranslation('enums')
  const [activeItems, setActiveItems] = useState<string[]>([])

  return (
    <Accordion.Root
      activeItems={activeItems}
      onHandleActiveItems={setActiveItems}
      className={styles.core}
    >
      <Accordion.Item
        id="info-list"
        header={
          <div className={styles['box-title']}>
            <MonetizationIcon />
            <Text variant="title-base" className={styles.title}>
              {t('quotationShowTitleSummaryPaymentCondition')}
            </Text>
          </div>
        }
      >
        <div className={styles.content}>
          <div>
            <Text>
              <strong>
                {t('quotationShowSummaryPaymentConditionFormsTitle')}
              </strong>
            </Text>
            {financeProfileData?.enabledPaymentForms.map((val) => (
              <div key={val} className={styles.item}>
                <CheckIcon height={16} />
                {tEnums(`financial_profile_payment_forms_${val}`)}
              </div>
            ))}
          </div>
          <div>
            <Text>
              <strong>
                {t('quotationShowSummaryPaymentConditionMethodsTitle')}
              </strong>
            </Text>
            {financeProfileData?.enabledPaymentMethods.map((val) => (
              <div key={val} className={styles.item}>
                <CheckIcon height={16} />
                {tEnums(`financial_profile_transaction_payment_methods_${val}`)}
              </div>
            ))}
          </div>
        </div>
      </Accordion.Item>
    </Accordion.Root>
  )
}

const QuotationSummaryPaymentConditionLoading = () => {
  return (
    <div className={styles.core}>
      <div className={styles.title}>
        <Skeleton height="1.5rem" width="1.5rem" />
        <Skeleton height="1rem" width="8rem" />
      </div>

      <div className={styles.content}>
        <Skeleton height="1rem" width="90%" />
        <Skeleton height="1rem" width="80%" />
        <Skeleton height="1rem" width="100%" />
      </div>
    </div>
  )
}

export const QuotationSummaryPaymentCondition = (
  props: QuotationSummaryPaymentConditionProps,
) => {
  if (props.isLoading) {
    return <QuotationSummaryPaymentConditionLoading />
  }

  return <QuotationSummaryPaymentConditionDefault {...props} />
}
