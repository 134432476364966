import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Card } from '../card'
import styles from './best-sellers.module.css'

import { productsLink } from '@/constants'

import { BestsSellersProps } from './best-sellers.type'
import { Text } from '@mercai/clever'

export const BestSellers = ({ values = [] }: BestsSellersProps) => {
  const { t } = useTranslation('componentsBestSellers')

  const formattedValues = values.filter((_, i) => i < 3)

  return (
    <div className={styles.wrapper}>
      <Text variant="title-medium">
        <strong>{t('title')}</strong>
      </Text>

      <div className={styles.products}>
        {formattedValues.map(({ attributes, uuid, reference }) => (
          <Link key={uuid} to={productsLink(reference)} target="_blank">
            <Card className={styles.product}>
              <div
                className={styles.image}
                style={{ backgroundImage: `url("${attributes?.mainImage}")` }}
              ></div>
              <Text className={styles.title}>{attributes?.title}</Text>
            </Card>
          </Link>
        ))}
      </div>
    </div>
  )
}
