import { useRef, useEffect, useState } from 'react'

import { ProgressBar } from '@mercai/clever'

import ConfettiExplosion from 'react-confetti-explosion'
import Confetti from 'react-confetti'

import {
  generateOverlayClasses,
  generateMainClasses,
} from './notification-popup.constants'
import { Notification } from '../notification'

import styles from './notification-popup.module.css'

import { NotificationPopupProps } from './notification-popup.type'

import { NotificationTypeEnum } from '@/types'

export const NotificationPopup = ({
  data,
  currentUserEmail,
  onLike,
  isOpen,
  onClose,
}: NotificationPopupProps) => {
  const overlayClasses = generateOverlayClasses(isOpen ? 'open' : 'close')
  const mainClasses = generateMainClasses(isOpen ? 'open' : 'close')

  const audioRef = useRef<any>(null) // eslint-disable-line @typescript-eslint/no-explicit-any

  const [progress, setProgress] = useState(0)
  const [audioTime, setAudioTime] = useState(0)

  const isAudioNotification = !!data?.metadata?.audioUrl

  useEffect(() => {
    const limit = isAudioNotification ? audioTime : 100
    const limitProgress = isAudioNotification ? 100 / limit : 20

    if (limit && isOpen && progress < 100) {
      const timer = setTimeout(() => {
        const newProgress = progress + limitProgress
        setProgress(newProgress)
        if (newProgress >= 100) {
          onClose()
          setProgress(0)
        }
      }, 1000)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [isOpen, progress, audioTime])

  const sounds: Record<NotificationTypeEnum, string> = {
    new_feature: '/sounds/new-feature.mp3',
    rfq_status_self_approved: '/sounds/default-notification.mp3',
    quotation_status_accepted: '/sounds/default-notification.mp3',
    saleorder__completed: '/sounds/default-notification.mp3',
    ai_comment: '/sounds/ai-comment.mp3',
    project_notification: '/sounds/project-notification.mp3',
  }

  const filePath = isAudioNotification
    ? data?.metadata?.audioUrl
    : sounds?.[data?.type]

  const handleSoundControl = (action: 'play' | 'stop') => {
    if (action === 'play') {
      setProgress(0)

      if (filePath) {
        if (audioRef?.current) {
          audioRef.current.pause()
          audioRef.current = null
        }

        audioRef.current = new Audio(filePath)

        audioRef.current.addEventListener('loadedmetadata', () => {
          if (isAudioNotification) {
            setAudioTime(audioRef.current.duration)
          } else {
            setAudioTime(0)
          }
        })

        audioRef.current.play()

        if (!isAudioNotification) {
          setAudioTime(0)
        }
      } else {
        console.error('Invalid file path')
      }
    } else if (action === 'stop') {
      if (audioRef.current) {
        audioRef.current.pause()
        audioRef.current = null
      }
    }
  }

  useEffect(() => {
    if (isOpen) {
      handleSoundControl('play')
    } else {
      handleSoundControl('stop')
    }

    return () => {
      handleSoundControl('stop')
    }
  }, [isOpen])

  return (
    <button className={overlayClasses} onClick={onClose}>
      {isOpen && <Confetti />}

      <div className={mainClasses}>
        {isOpen && (
          <ConfettiExplosion className={styles['confetti-explosion']} />
        )}

        <Notification
          {...data}
          currentUserEmail={currentUserEmail}
          onLike={onLike}
          size="large"
        />

        <ProgressBar className={styles.progress} progress={progress} />
      </div>
    </button>
  )
}
