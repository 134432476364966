import { deepCopy } from '@/helpers'
import { TransportCompanyProps } from '@/types'

export const formatDataForUpdate = (
  transportCompany?: TransportCompanyProps,
) => {
  if (!transportCompany) return {}
  const transportCompanyCopy = deepCopy(transportCompany)

  delete transportCompanyCopy.createdAt
  delete transportCompanyCopy.updatedAt
  delete transportCompanyCopy.logisticRoutes

  transportCompanyCopy.transportCompanyRoutes?.forEach((route) => {
    delete route.createdAt
    delete route.updatedAt
    route.logisticRouteUuid = route.logisticRoute.uuid
    route.vehicleUuid = route.vehicle.uuid
  })

  return transportCompanyCopy
}
