import { isAxiosError } from 'axios'
import { useMutation, useQuery } from '@tanstack/react-query'
import { convertObjectToCamelCase, convertObjectToSnakeCase } from '@/helpers'

import { DEFAULT_LIMIT_PAGE } from '@/constants'

import {
  CatalogProductProps,
  CatalogProductSnakeCaseProps,
  CatalogProductWithPagesProps,
} from '@/types'

import { QUERIES_ADMIN } from './api.constants'

import { api } from './api.service'
import {
  CATALOG_PRODUCTS_URL,
  CATALOG_PRODUCT_URL,
  CATALOG_PRODUCT_UPDATE_IMAGE_URL,
} from './api.urls'

export const useCatalogProductList = (
  params: Record<string, string | number> = {},
) => {
  const getCatalogProductList = async (): Promise<{
    success: boolean
    message: string
    data?: CatalogProductWithPagesProps
  }> => {
    const copyParams = { ...params }

    const offset = copyParams?.page
      ? (+(copyParams?.page || 0) - 1) * DEFAULT_LIMIT_PAGE
      : 0

    delete copyParams.page

    const formattedParams = Object.keys(copyParams).reduce((acc, key) => {
      if (copyParams?.[key]) {
        if (key === 'category' && copyParams?.[key] === ['ALL'].join(',')) {
          return acc
        }

        return {
          ...acc,
          [key]: copyParams?.[key],
        }
      }

      return acc
    }, {})

    try {
      const { data } = await api.get(CATALOG_PRODUCTS_URL, {
        params: {
          ...formattedParams,
          limit: params?.limit || DEFAULT_LIMIT_PAGE,
          offset,
        },
      })

      return {
        success: true,
        message: 'Catalog products fetched successfully!',
        data: convertObjectToCamelCase(data) as CatalogProductWithPagesProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get catalog products!',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [QUERIES_ADMIN.CATALOG_PRODUCT.module, params],
    queryFn: () => getCatalogProductList(),
  })
}

export const useCreateCatalogProduct = () => {
  const createCatalogProduct = async (
    payload: Partial<CatalogProductProps>,
  ): Promise<{
    success: boolean
    message: string
    data?: CatalogProductProps
  }> => {
    try {
      const { data } = await api.post(
        CATALOG_PRODUCTS_URL,
        convertObjectToSnakeCase(payload),
      )

      return {
        success: true,
        message: 'Catalog product created successfully!',
        data: convertObjectToCamelCase(data) as CatalogProductProps,
      }
    } catch (err) {
      const message = 'Failed to create catalog product!'

      if (isAxiosError(err) && err.response && err.response.data) {
        return {
          success: false,
          message,
          data: err.response.data,
        }
      }

      return {
        success: false,
        message,
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.CATALOG_PRODUCT.module],
    mutationFn: (payload: Partial<CatalogProductProps>) =>
      createCatalogProduct(payload),
  })
}

export const useUpdateCatalogProduct = (uuid: string) => {
  const updateCatalogProduct = async (
    payload: Partial<CatalogProductProps>,
  ): Promise<{
    success: boolean
    message: string
    data?: CatalogProductProps
  }> => {
    try {
      const { data } = await api.patch(
        CATALOG_PRODUCT_URL(`${uuid}`),
        convertObjectToSnakeCase(payload),
      )

      return {
        success: true,
        message: 'Catalog product updated successfully!',
        data: convertObjectToCamelCase(data) as CatalogProductProps,
      }
    } catch (err) {
      const message = 'Failed to update catalog product!'

      if (isAxiosError(err) && err.response && err.response.data) {
        return {
          success: false,
          message,
          data: err.response.data,
        }
      }

      return {
        success: false,
        message,
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.CATALOG_PRODUCT.module, uuid],
    mutationFn: (payload: Partial<CatalogProductProps>) =>
      updateCatalogProduct(payload),
  })
}

export const useCatalogProductDetail = (
  uuid?: string,
  convertToCamelCase = true,
  params: Record<string, string | number> = {},
) => {
  const getCatalogProductDetail = async (): Promise<{
    success: boolean
    message: string
    data?: CatalogProductProps | CatalogProductSnakeCaseProps
  }> => {
    try {
      const copyParams = { ...params }

      const formattedParams = Object.keys(copyParams).reduce((acc, key) => {
        if (copyParams?.[key]) {
          return {
            ...acc,
            [key]: copyParams?.[key],
          }
        }

        return acc
      }, {})

      const { data } = await api.get(CATALOG_PRODUCT_URL(`${uuid}`), {
        params: formattedParams,
      })

      return {
        success: true,
        message: 'Catalog product fetched successfully!',
        data: convertToCamelCase
          ? (convertObjectToCamelCase(data) as CatalogProductProps)
          : (data as CatalogProductSnakeCaseProps),
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get catalog product!',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [QUERIES_ADMIN.CATALOG_PRODUCT.module, uuid, params],
    queryFn: () => getCatalogProductDetail(),
    enabled: !!uuid,
  })
}

export const useUpdateImageCatalogProduct = () => {
  const updateCatalogProduct = async (
    uuid: string,
  ): Promise<{
    success: boolean
    message: string
    data?: CatalogProductProps
  }> => {
    try {
      const { data } = await api.post(
        `${CATALOG_PRODUCT_UPDATE_IMAGE_URL(uuid)}`,
      )

      return {
        success: true,
        message: 'Catalog product updated successfully!',
        data: convertObjectToCamelCase(data) as CatalogProductProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to update catalog product!',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.CATALOG_PRODUCT.module],
    mutationFn: (uuid: string) => updateCatalogProduct(uuid),
  })
}
