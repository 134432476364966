import { ReactsProps } from './reacts.type'

import styles from './reacts.module.css'
import { ReactBadge } from '../react-badge'

export const Reacts = ({ reacts }: ReactsProps) => {
  return (
    <div className={styles.core}>
      {reacts.map((el) => (
        <ReactBadge key={el.id} x={el.x} author={el.author} />
      ))}
    </div>
  )
}
