import { UserProps } from '@/types'
import * as Yup from 'yup'

export const shemaValidation = (t: any) => ({ // eslint-disable-line
  name: Yup.string().required(t('userTableDrawerRequiredField')),
  phone: Yup.string()
    .min(10, t('userTableDrawerRequiredField'))
    .required(t('userTableDrawerRequiredField')),
  email: Yup.string()
    .email(t('userTableDrawerInvalidFormatField'))
    .required(t('userTableDrawerRequiredField')),
  type: Yup.string(),
  status: Yup.string(),
})

export const defaultValues = {
  name: '',
  email: '',
  phone: '+52',
  isActive: true,
} as UserProps
