import { useTranslation } from 'react-i18next'

import { useSupplierList } from '@/services'

import styles from './supplier-form.module.css'

import type { SupplierFormProps } from './supplier-form.type'
import { SupplierProps } from '@/types'
import { useState } from 'react'
import { InputSearch, Skeleton, Text } from '@mercai/clever'

export const SupplierForm = ({
  supplierUuid,
  setSupplierUuid,
  errors,
}: SupplierFormProps) => {
  const { t } = useTranslation('screenCatalogProduct')

  const [search, setSearch] = useState('')

  const { data, isLoading } = useSupplierList({ params: { search, limit: 10 } })

  const onHandleSelect = (uuid: string) => {
    setSupplierUuid(uuid)
  }

  const Loading = () => {
    return [1, 2, 3].map((val) => (
      <div key={val} className={styles.supplier}>
        <Skeleton height="1.5rem" width="100%" />
        <Skeleton height="1.2rem" width="40%" />
        <Skeleton height="1.2rem" width="45%" />
        <Skeleton height="1.2rem" width="25%" />
      </div>
    ))
  }

  const SupplierButton = ({ supplier }: { supplier: SupplierProps }) => {
    return (
      <button
        key={supplier?.uuid}
        className={`${styles.supplier} ${supplier?.uuid === supplierUuid ? styles.active : ''}`}
        onClick={() => onHandleSelect(supplier?.uuid || '')}
      >
        <div>
          <Text variant="text-base">
            <strong>{supplier?.name}</strong>
          </Text>
        </div>
        <div className={styles.row}>
          <Text>{supplier?.email}</Text>
        </div>
      </button>
    )
  }

  return (
    <div className={styles.core}>
      <InputSearch
        value={search}
        onChange={setSearch}
        translations={{
          inputPlaceholder: t('detailSupplierFormInputSearchPlaceholder'),
        }}
      />

      {isLoading && <Loading />}

      {!isLoading && (
        <div className={styles.suppliers}>
          {!!errors?.supplierUuid && (
            <Text className={styles.error}>
              {errors?.supplierUuid as string}
            </Text>
          )}
          {data?.data?.results?.map((supplier) => (
            <SupplierButton supplier={supplier} key={supplier?.uuid} />
          ))}
        </div>
      )}
    </div>
  )
}
