import { AddressProps } from './address.type'
import { AccountLevelProps } from './account-level.type'
import { UserProps } from './user.type'
import { FinanceProfileProps } from './finance-profile.type'

export enum ProfileTaxStatusEnum {
  pending = 'pending',
  review = 'review',
  invalid = 'invalid',
  completed = 'completed',
}

export enum ProfileMoscowsClassificationEnum {
  'must' = 'must',
  'should' = 'should',
  'could' = 'could',
  'wont' = 'wont',
}

export enum ProfileCustomerStatusEnum {
  active = 'active',
  churn = 'churn',
  passive_churn = 'passive_churn',
}

export enum ProfileTypeEnum {
  inbound = 'inbound',
  outbound = 'outbound',
  inbound_reactivation = 'inbound_reactivation',
  outbound_reactivation = 'outbound_reactivation',
}

export interface ProfileProps {
  uuid: string
  name: string
  phone: string
  email: string
  companyName: string
  attributes: Record<string, string | string[]>
  createdAt: string
  addresses: AddressProps[]
  accountLevel: AccountLevelProps
  accountLevelSlug?: string
  moscowClassification: string
  keyAccountManager: UserProps
  keyAccountManagerId: string
  customerLevel: AccountLevelProps
  customerLevelSlug?: string
  statusHistory: Record<string, string>
  isFraudlent: boolean
  cfdi: string
  paymentMethod: string
  paymentForm: string

  financeProfile: FinanceProfileProps

  updatedAt: string
  taxSituation: string
  taxStatus: keyof typeof ProfileTaxStatusEnum
  usersNames: string[]
  users: UserProps[]
  customerStatus: keyof typeof ProfileCustomerStatusEnum
  churnedAt: string
  type: keyof typeof ProfileTypeEnum
}

export interface ProfileListWithPagesProps {
  count: number
  next?: string
  previous?: string
  results?: ProfileProps[]
}
