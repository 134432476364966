'use client'
import React, { useContext, createContext } from 'react'
import styles from './modal.module.css'

import { generateOverlayClasses, generateMainClasses } from './modal.constants'

import { Text } from '../text'
import { CloseIcon } from '../../../icons/close-icon'

import type {
  ModalRootProps,
  ModalHeaderProps,
  ModalBodyProps,
  ModalContextProps,
} from './modal.type'

const ModalContext = createContext<ModalContextProps>({
  onHandleClose: () => null,
})

export const useModalContext = () => useContext(ModalContext)

const Root = ({
  children,
  isOpen,
  onHandleOpen,
  size = 'large',
  overlayClassName,
  mainClassName,
}: ModalRootProps) => {
  const generatedOverlayClasses = generateOverlayClasses(
    isOpen ? 'open' : 'close',
    overlayClassName,
  )

  const generatedMainClasses = generateMainClasses(
    isOpen ? 'open' : 'close',
    size,
    mainClassName,
  )

  return (
    <ModalContext.Provider value={{ onHandleClose: () => onHandleOpen(false) }}>
      <section>
        <div
          className={generatedOverlayClasses}
          onClick={() => onHandleOpen(false)}
        />
        <main className={generatedMainClasses}>{children}</main>
      </section>
    </ModalContext.Provider>
  )
}

const Header = ({ title, description }: ModalHeaderProps) => {
  const { onHandleClose } = useModalContext()

  return (
    <header className={styles.header}>
      <div className={styles['header-content']}>
        <Text variant="title-large">{title}</Text>
        {description && <Text>{description}</Text>}
      </div>

      <button
        className={styles['header-close-button']}
        type="button"
        onClick={onHandleClose}
      >
        <CloseIcon />
      </button>
    </header>
  )
}

const Body = ({ children, className }: ModalBodyProps) => {
  return <div className={`${styles.body} ${className || ''}`}>{children}</div>
}

const Footer = ({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) => {
  return (
    <footer className={`${styles.footer} ${className || ''}`}>
      {children}
    </footer>
  )
}

const Modal = {
  Root,
  Header,
  Body,
  Footer,
}

export default Modal
