import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useFileSign } from '@/services'
import { downloadFile, getFileNameAndExtension, trimString } from '@/helpers'
import { Button, Card, DownloadIcon, Skeleton, Text } from '@mercai/clever'

import { CatalogProductDetailProps } from './detail.types'

import styles from './detail.module.css'

export const CatalogProductDetail = ({
  catalogProduct,
  isLoading,
}: CatalogProductDetailProps) => {
  const { t } = useTranslation('screenCatalogProduct')

  const [showDetails, setShowDetails] = useState(false)

  const { mutateAsync, isPending } = useFileSign()

  const onHandleDownloadDataSheet = async () => {
    if (catalogProduct?.attributes?.dataSheet) {
      let url = catalogProduct?.attributes?.dataSheet

      if (!url.includes('http')) {
        const response = await mutateAsync({
          filePath: url || '',
        })

        if (!response?.data?.signedUrl) return

        url = response?.data?.signedUrl
      }

      const { fileName, extension } = getFileNameAndExtension(
        catalogProduct?.attributes?.dataSheet || '',
      )

      downloadFile(url, `${fileName}.${extension}`)
    }
  }

  const getDescription = () => {
    if (
      catalogProduct?.attributes?.description &&
      catalogProduct?.attributes?.description?.length > 600
    ) {
      return (
        <Text>
          {showDetails
            ? catalogProduct?.attributes?.description
            : trimString(
                catalogProduct?.attributes?.description || '',
                600,
                '... ',
              )}
          <Text
            onClick={() => setShowDetails(!showDetails)}
            className={styles['description-handle-open-button']}
          >
            {showDetails
              ? t('detailDescriptionReadLess')
              : t('detailDescriptionReadMore')}
          </Text>
        </Text>
      )
    }

    return <Text>{catalogProduct?.attributes?.description}</Text>
  }

  if (isLoading) {
    return (
      <Card className={styles.core}>
        <div className={styles.image}>
          <Skeleton height="100%" width="100%" />
        </div>
        <div className={styles.content}>
          <div className={styles['content-attributes']}>
            <div className={styles['content-attributes-item']}>
              <Skeleton height="2rem" width="8rem" />
            </div>
            <div className={styles['content-attributes-item']}>
              <Skeleton height="2rem" width="8rem" />
            </div>
            <div className={styles['content-attributes-item']}>
              <Skeleton height="2rem" width="8rem" />
            </div>
            <div className={styles['content-attributes-item']}>
              <Skeleton height="2rem" width="8rem" />
            </div>
            <div className={styles['content-attributes-item']}>
              <Skeleton height="2rem" width="8rem" />
            </div>
            <div className={styles['content-attributes-item']}>
              <Skeleton height="2rem" width="8rem" />
            </div>
            <div className={styles['content-attributes-item']}>
              <Skeleton height="2rem" width="8rem" />
            </div>
          </div>
          <div>
            <Skeleton height="6rem" />
          </div>
          <div>
            <Skeleton height="2rem" width="10rem" />
          </div>
        </div>
      </Card>
    )
  }

  return (
    <Card className={styles.core}>
      <div className={styles.image}>
        <img
          src={
            catalogProduct?.attributes?.mainImage ||
            '/images/product-placeholder.webp'
          }
          alt="product image"
        />
      </div>
      <div className={styles.content}>
        <div className={styles['content-attributes']}>
          <div className={styles['content-attributes-item']}>
            <Text variant="title-base">{t('detailCategoryLabel')}: </Text>
            <Text>
              {catalogProduct?.category?.name} -{' '}
              {catalogProduct?.category?.shortName}
            </Text>
          </div>

          {catalogProduct?.attributes?.process && (
            <div className={styles['content-attributes-item']}>
              <Text variant="title-base">{t('detailProcessLabel')}: </Text>
              <Text>
                {Array.isArray(catalogProduct?.attributes?.process)
                  ? catalogProduct?.attributes?.process
                      ?.map((item) => item?.value)
                      .join(', ')
                  : '-'}
              </Text>
            </div>
          )}

          {catalogProduct?.attributes?.color && (
            <div className={styles['content-attributes-item']}>
              <Text variant="title-base">{t('detailColorLabel')}: </Text>
              <Text>
                {catalogProduct?.attributes?.color
                  ? catalogProduct?.attributes?.color?.value
                  : '-'}
              </Text>
            </div>
          )}

          <div className={styles['content-attributes-item']}>
            <Text variant="title-base">{t('detailBrandLabel')}: </Text>
            <Text>
              {catalogProduct?.attributes?.brand
                ? catalogProduct?.attributes?.brand?.value
                : '-'}
            </Text>
          </div>

          <div className={styles['content-attributes-item']}>
            <Text variant="title-base">{t('detailSKULabel')}: </Text>
            <Text>{catalogProduct?.reference || '-'}</Text>
          </div>

          {catalogProduct?.attributes?.size && (
            <div className={styles['content-attributes-item']}>
              <Text variant="title-base">{t('detailSizeLabel')}: </Text>
              <Text>
                {catalogProduct?.attributes?.size
                  ? catalogProduct?.attributes?.size
                  : '-'}
              </Text>
            </div>
          )}

          {catalogProduct?.attributes?.quantityPerPackage && (
            <div className={styles['content-attributes-item']}>
              <Text variant="title-base">
                {t('detailQuantityPerPackageLabel')}:{' '}
              </Text>
              <Text>
                {catalogProduct?.attributes?.quantityPerPackage
                  ? catalogProduct?.attributes?.quantityPerPackage
                  : '-'}
              </Text>
            </div>
          )}

          {catalogProduct?.attributes?.certifications && (
            <div className={styles['content-attributes-item']}>
              <Text variant="title-base">
                {t('detailCertificationLabel')}:{' '}
              </Text>
              <Text>
                {catalogProduct?.attributes?.certifications
                  ?.map((item) => item?.value)
                  .join(', ') || '-'}
              </Text>
            </div>
          )}
        </div>

        <div>
          <Text
            variant="title-base"
            className={styles['content-attributes-item-description']}
          >
            {t('detailDescriptionLabel')}:{' '}
          </Text>
          <Text>{getDescription()}</Text>
        </div>
        <div>
          <Button
            variant="secondary"
            onClick={onHandleDownloadDataSheet}
            disabled={!catalogProduct?.attributes?.dataSheet}
          >
            <DownloadIcon />{' '}
            {isPending
              ? t('detailButtonDataSheetDownloading')
              : t('detailButtonDataSheet')}
          </Button>
        </div>
      </div>
    </Card>
  )
}
