import { Button, Input, Label, Select } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import { useValidationErrors } from '@/helpers'

import { schemaQuotationRequest } from './quotation-request-form.constants'

import type { QuotationRequestFormProps } from './quotation-request-form.type'

import { QuotationRequestStatusEnum } from '@/types'

import styles from './quotation-request-form.module.css'

export const QuotationRequestForm = ({
  onChangeValues,
  values,
  onBack,
  onHandleSubmit,
  onHandleSubmitCompleted,
  isNewRegister,
}: QuotationRequestFormProps) => {
  const { t } = useTranslation('screenQuotationRequestList')
  const { t: tEnums } = useTranslation('enums')

  const showRefusedReason =
    values?.status === 'invalid' || values?.status === 'disqualified'

  const { isValid, errors, onHandleListenErrors } = useValidationErrors(
    schemaQuotationRequest(t),
    {
      ...values,
      ...(showRefusedReason ? {} : { refusedReason: 'true' }),
    },
  )

  const onHandleValues = (key: string, value: string | number | boolean) => {
    if (key === 'status' && value !== 'invalid' && value !== 'disqualified') {
      values.refusedReason = ''
    }
    onChangeValues({ ...values, [key]: value })
  }

  const onHandleSubmitLocal = () => {
    if (!isValid) {
      onHandleListenErrors(true)
      return
    }

    onHandleListenErrors(false)
    onHandleSubmit()
  }

  const onHandleSubmitCompletedLocal = () => {
    if (!isValid) {
      onHandleListenErrors(true)
      return
    }

    onHandleListenErrors(false)
    onHandleSubmitCompleted()
  }

  const getStatusValues = () => {
    return Object.keys(QuotationRequestStatusEnum).map((status) => {
      return {
        label: tEnums(`quotation_request_status_${status}`),
        value: status,
      }
    })
  }

  return (
    <>
      <div className={styles.core}>
        <div>
          <Label>{t('quotationRequestFormStatusLabel')}</Label>
          <Select
            placeholder={t('quotationRequestFormStatusPlaceholder')}
            value={values?.status}
            error={errors?.status as string}
            onChange={(newValue) => onHandleValues('status', newValue)}
            options={getStatusValues()}
          />
        </div>
        {showRefusedReason && (
          <div>
            <Label>{t('quotationRequestFormRefusedReasonLabel')}</Label>
            <Input
              placeholder={t('quotationRequestFormRefusedReasonPlaceholder')}
              value={values?.refusedReason}
              error={errors?.refusedReason as string}
              onChange={(newValue) => onHandleValues('refusedReason', newValue)}
              isTextArea
            />
          </div>
        )}
      </div>
      <div className={styles.footer}>
        <Button variant="secondary" onClick={onBack}>
          {t('navigateButtonBack')}
        </Button>

        <Button onClick={onHandleSubmitLocal}>{t('navigateButtonNext')}</Button>
        {!isNewRegister && (
          <Button onClick={onHandleSubmitCompletedLocal} variant="light">
            {t('navigateButtonFinish')}
          </Button>
        )}
      </div>
    </>
  )
}
