import { Text } from '@mercai/clever'

import styles from './kai-message.module.css'

import type { KaiMessageProps } from './kai-message.type'

export const KaiMessage = ({ message, onClick }: KaiMessageProps) => {
  return (
    <button className={styles.wrapper} onClick={onClick}>
      <Text variant="text-xsmall" className={styles.content}>
        {message}
      </Text>

      <img src="/images/kai.png" alt="kai-message" className={styles.avatar} />
    </button>
  )
}
