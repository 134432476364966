import { Button, Label, InputMask } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import { useValidationErrors } from '@/helpers'

import { schemaOrder } from './order-date-form.constants'

import type { OrderDateFormProps } from './order-date-form.type'

import styles from './order-date-form.module.css'

export const OrderDateForm = ({
  onChangeValues,
  values,
  onBack,
  onHandleSubmit,
}: OrderDateFormProps) => {
  const { t } = useTranslation('componentsOrderDateForm')

  const { isValid, errors, onHandleListenErrors } = useValidationErrors(
    schemaOrder(t),
    values,
  )

  const onHandleValues = (key: string, value: string | number | boolean) => {
    onChangeValues({ ...values, [key]: value })
  }

  const onHandleSubmitLocal = () => {
    if (!isValid) {
      onHandleListenErrors(true)
      return
    }

    onHandleListenErrors(false)
    onHandleSubmit()
  }

  return (
    <>
      <div className={styles.core}>
        <div>
          <Label>
            {t('orderFormInvoiceAtLabel')} <span>{t('optionalLabel')}</span>
          </Label>
          <InputMask
            placeholder={t('orderFormInvoiceAtPlaceholder')}
            value={values?.invoicedAt}
            onChange={(newValue) => onHandleValues('invoicedAt', newValue)}
            error={errors?.invoicedAt as string}
            mask={'99/99/9999'}
          />
        </div>

        <div>
          <Label>
            {t('orderFormPaidAtLabel')}
            <span>{t('optionalLabel')}</span>
          </Label>
          <InputMask
            placeholder={t('orderFormPaidAtPlaceholder')}
            value={values?.paidAt}
            onChange={(newValue) => onHandleValues('paidAt', newValue)}
            error={errors?.paidAt as string}
            mask={'99/99/9999'}
          />
        </div>

        <div>
          <Label>
            {t('orderFormExpectedDeliveryAtLabel')}
            <span>{t('optionalLabel')}</span>
          </Label>
          <InputMask
            placeholder={t('orderFormExpectedDeliveryAtPlaceholder')}
            value={values?.expectedDeliveryAt}
            onChange={(newValue) =>
              onHandleValues('expectedDeliveryAt', newValue)
            }
            error={errors?.expectedDeliveryAt as string}
            mask={'99/99/9999'}
          />
        </div>

        <div>
          <Label>
            {t('orderFormDeliveredAtLabel')}
            <span>{t('optionalLabel')}</span>
          </Label>
          <InputMask
            placeholder={t('orderFormDeliveredAtPlaceholder')}
            value={values?.deliveredAt}
            onChange={(newValue) => onHandleValues('deliveredAt', newValue)}
            error={errors?.deliveredAt as string}
            mask={'99/99/9999'}
          />
        </div>
      </div>

      <div className={styles.footer}>
        <Button variant="secondary" onClick={onBack}>
          {t('buttonBack')}
        </Button>

        <Button onClick={onHandleSubmitLocal}>{t('buttonSubmit')}</Button>
      </div>
    </>
  )
}
