import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import {
  Button,
  EditIcon,
  Pagination,
  PlusIcon,
  Select,
  TableColumns,
  TableColumnsColumnProps,
  Text,
} from '@mercai/clever'

import { SupplierProductProps } from '@/types'
import { formatCurrency, formatNumber, useParamsState } from '@/helpers'
import { useSupplierProductsList } from '@/services'
import { DEFAULT_LIMIT_PAGE } from '@/constants'
import { ROUTES_ADMIN } from '@/router'

import { SupplierProductDrawerForm } from '../supplier-product-drawer-form'

import { SupplierProductTableProps } from './supplier-product-table.types'

import styles from './supplier-product-table.module.css'

export const SupplierProductTable = ({ uuid }: SupplierProductTableProps) => {
  const { t } = useTranslation('screenCatalogProduct')

  const [selectedSupplierProduct, setSelectedSupplierProduct] =
    useState<SupplierProductProps>()

  const [supplierUuid, setSupplierUuid] = useState('')
  const [isAddSupplier, setIsAddSupplier] = useState(false)

  const [filter, setFilter] = useParamsState({
    page: '1',
    limit: `${DEFAULT_LIMIT_PAGE}`,
    catalog_product__uuid: uuid || '',
    formSupplierProduct: '',
  })

  const [totalPages, setTotalPages] = useState(1)

  const handleFilter = (changedKey: string, newValue: string) => {
    setFilter({ ...filter, [changedKey]: newValue })
  }

  const { data, isLoading } = useSupplierProductsList({
    params: {
      ...filter,
    },
  })

  const columns: TableColumnsColumnProps<SupplierProductProps>[] = [
    {
      title: t('detailSupplierProductsTableName'),
      key: 'name',
      dataKey: 'catalogProduct',
      render: ({ record }) => (
        <Link
          to={`/${ROUTES_ADMIN.SUPPLY.SUPPLIERS.path}/${record?.supplierWarehouse?.supplier?.uuid}`}
          className={styles['supplier-name-link']}
        >
          <Text className={styles['supplier-name']}>
            {record?.supplierWarehouse?.supplier?.name}
          </Text>
          <Text>{record?.supplierWarehouse?.supplier?.email}</Text>
        </Link>
      ),
    },
    {
      title: 'SKU',
      key: 'reference',
      dataKey: 'reference',
      width: '8rem',
      render: ({ record }) => record?.reference || '-',
    },
    {
      title: t('detailSupplierProductsTablePriceWithoutIVA'),
      key: 'priceWithoutIVA',
      dataKey: 'price',
      width: '12rem',
      render: ({ record }) =>
        record?.price ? formatCurrency(record.price, 'es-MX', 'USD') : '-',
    },
    {
      title: t('detailSupplierProductsTableMin'),
      key: 'minimumPurchaseQuantity',
      dataKey: 'minimumPurchaseQuantity',
      width: '12rem',
      render: ({ record }) =>
        record?.minimumPurchaseQuantity
          ? `${formatNumber(record?.minimumPurchaseQuantity)} Kg`
          : '-',
    },
    {
      title: t('detailSupplierProductsTableAvailable'),
      key: 'quantity',
      dataKey: 'quantity',
      width: '12rem',
      render: ({ record }) =>
        record?.quantity ? `${formatNumber(record?.quantity)} Kg` : '-',
    },
    {
      title: t('detailSupplierProductsTableLocation'),
      key: 'supplierWarehouseUuid',
      dataKey: 'supplierWarehouseUuid',
      width: '12rem',
      render: ({ record }) => (
        <div className={styles['warehouse-column']}>
          <img
            src={
              record?.supplierWarehouse?.supplier?.isForeign
                ? '/images/flag-us.svg'
                : '/images/flag-mx.svg'
            }
            alt={'flag'}
          />
          {record?.supplierWarehouse?.address?.city}
        </div>
      ),
    },
    {
      title: t(''),
      key: 'actions',
      width: '5rem',
      render: ({ record }) => (
        <Button
          variant="secondary"
          model="square"
          onClick={() => {
            setSelectedSupplierProduct(record)
            setIsAddSupplier(false)
            handleFilter('formSupplierProduct', 'true')
          }}
        >
          <EditIcon />
        </Button>
      ),
    },
  ]

  useEffect(() => {
    if (data?.data?.count) {
      setTotalPages(Math.ceil(data?.data?.count / +filter.limit))
    }
  }, [data])

  return (
    <div className={styles.core}>
      <div className={styles.header}>
        <Text variant="title-large">
          {t('detailSupplierProductsTableTitle')}
        </Text>
        <Button
          variant="success-light"
          onClick={() => {
            setIsAddSupplier(true)
            handleFilter('formSupplierProduct', 'true')
          }}
        >
          <PlusIcon />
          {t('detailAddSupplierButton')}
        </Button>
      </div>
      <TableColumns<SupplierProductProps>
        isLoading={isLoading}
        columns={columns}
        data={data?.data?.results || []}
      />
      <div className={styles.pagination}>
        <div className={styles['pagination-show-registers']}>
          <Text>
            {t('detailSupplierPaginationShowRegisters', {
              showNumber:
                (data?.data?.count || 0) < +filter.limit
                  ? data?.data?.count
                  : +filter.limit,
              totalNumber: data?.data?.count || 0,
            })}
          </Text>

          <Select
            value={filter.limit}
            onChange={(value) => handleFilter('limit', value)}
            options={[
              { label: '10', value: '10' },
              { label: '20', value: '20' },
              { label: '50', value: '50' },
              { label: '100', value: '100' },
            ]}
          />
        </div>

        <Pagination
          totalPages={totalPages}
          onChange={(page) => handleFilter('page', `${page}`)}
          currentPage={+filter.page as number}
        />
      </div>

      <SupplierProductDrawerForm
        isOpen={!!filter?.formSupplierProduct}
        supplierProduct={selectedSupplierProduct as SupplierProductProps}
        onHandleOpen={() => handleFilter('formSupplierProduct', '')}
        supplierUuid={supplierUuid}
        setSupplierUuid={setSupplierUuid}
        isAddSupplier={isAddSupplier}
        catalogProductUuid={uuid}
      />
    </div>
  )
}
