import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Tag, MonetizationIcon } from '@mercai/clever'

import { InfoList } from '@/components'
import { formatNumber } from '@/helpers'

import styles from './order-finance.module.css'

import {
  OrderInvoiceStatusEnum,
  SaleOrderPaymentCalculatedStatusEnum,
} from '@/types'

import type { OrderFinanceProps } from './order-finance.type'

export const OrderFinance = ({
  isLoading,
  invoiceStatus,
  paymentMethod,
  calculatePaymentStatus,
  paymentForm,
  useCfdi,
  financingCost,
}: OrderFinanceProps) => {
  const { t } = useTranslation('componentOrderCenterShow')
  const { t: tEnums } = useTranslation('enums')

  const statusInvoiceObject: Record<OrderInvoiceStatusEnum, ReactNode> = {
    pending: (
      <Tag model="light" variant="warning">
        {tEnums('order_invoice_status_pending')}
      </Tag>
    ),
    uploaded: (
      <Tag model="light" variant="success">
        {tEnums('order_invoice_status_uploaded')}
      </Tag>
    ),
    cancelled: (
      <Tag model="light" variant="danger">
        {tEnums('order_invoice_status_cancelled')}
      </Tag>
    ),
  }

  const statusPaymentObject: Record<
    SaleOrderPaymentCalculatedStatusEnum,
    ReactNode
  > = {
    processing: (
      <Tag variant="warning" model="light">
        {tEnums('order_payment_calculated_status_processing')}
      </Tag>
    ),
    paid: (
      <Tag variant="success" model="light">
        {tEnums('order_payment_calculated_status_paid')}
      </Tag>
    ),
    invalid: (
      <Tag variant="basic" model="light">
        {tEnums('order_payment_calculated_status_invalid')}
      </Tag>
    ),
    cancelled: (
      <Tag variant="danger" model="light">
        {tEnums('order_payment_calculated_status_cancelled')}
      </Tag>
    ),
    expired: (
      <Tag variant="danger" model="light">
        {tEnums('order_payment_calculated_status_expired')}
      </Tag>
    ),
    partially_paid: (
      <Tag variant="warning" model="light">
        {tEnums('order_payment_calculated_status_partially_paid')}
      </Tag>
    ),
  }

  return (
    <div className={styles.core}>
      <InfoList
        isLoading={isLoading}
        iconTitle={<MonetizationIcon />}
        title={t('orderFinanceTitle')}
        list={[
          {
            label: t('orderFinanceInvoiceStatus'),
            value: statusInvoiceObject[invoiceStatus || 'pending'],
          },
          {
            label: t('orderFinancePaymentStatus'),
            value: statusPaymentObject[calculatePaymentStatus || 'processing'],
          },
          {
            label: t('orderFinancePaymentMethod'),
            value: paymentMethod
              ? tEnums(`profile_payment_form_${paymentMethod}`)
              : '-',
          },
          {
            label: t('orderFinancePaymentType'),
            value: paymentForm
              ? tEnums(`profile_payment_method_${paymentForm}`)
              : '-',
          },
          {
            label: t('orderFinanceUseCFDI'),
            value: useCfdi ? tEnums(`profile_cfdi_${useCfdi}`) : '-',
          },
          {
            label: t('orderFinanceFinancingCost'),
            value:
              financingCost && +financingCost > 0
                ? `${formatNumber(+financingCost * 100, 'es-MX')}%`
                : '-',
          },
        ]}
      />
    </div>
  )
}
