import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Checkbox,
  DropDown,
  TableRows,
  SettingIcon,
  TableRowsColumnProps,
  Select,
} from '@mercai/clever'

import { Header } from '@/components'

import {
  useParamsState,
  useCookieState,
  trackEvent,
  formatCurrency,
  formatNumber,
} from '@/helpers'
import { useOrderTrackerList } from '@/services'

import {
  calculateCogs,
  calculateGrossProfit,
  calculateFinancingCost,
  calculateGMVPercentage,
  calculateCM1,
  calculateCM1Percentage,
  calculateCM2,
  calculateCM2Percentage,
  calculateCM3,
  calculateCM3Percentage,
  calculateCOGSByCategory,
  calculateGMVByCategory,
} from './order-tracker.formulas'

import styles from './order-tracker.module.css'

import type { OrderTrackerProps } from '@/types'

export const AnalyticsTotalEconomics = () => {
  const { t } = useTranslation('screenOrderTracker')

  const [keyAggregatedData, setKeyAggregatedData] =
    useState<string>('orderCreationDate')

  const [columnKeys, setColumnsKeys] = useCookieState<string[]>(
    '@mercai/total-economics-rows',
    [
      'orderPerformanceIndicators',
      'orderTransportPrice',
      'orderIva',
      'orderGmv',
      'orderGmvMxn',
      'orderCogs',
      'orderGrossProfitCogs',
      'gmPercentage',
      'cm1',
      'orderCustomsOperations',
      'orderDistributionFirstMile',
      'cm1Percentage',
      'cm2',
      'warehouse',
      'orderDistributionLastMile',
      'cm2Percentage',
      'cm3',
      'orderFinancingCosts',
      'orderReferralCommission',
      'cm3Percentage',
      'polymers',
      'engineering',
      'pe',
      'pp',
      'steel',
      'mildSteel',
      'stainlessSteel',
      'polymersCogs',
      'engineeringCogs',
      'peCogs',
      'ppCogs',
      'steelCogs',
      'mildSteelCogs',
      'stainlessSteelCogs',
    ],
  )

  const [filter] = useParamsState({
    page: '1',
    limit: '500',
    search: '',
    status: '',
    createdAtAfter: '',
    createdAtBefore: '',
  })

  const onHandleRowsShow = (key: string) => {
    let newRowsKeys = columnKeys

    if (columnKeys.includes(key)) {
      newRowsKeys = columnKeys.filter((item) => item !== key)
    } else {
      newRowsKeys = [...columnKeys, key]
    }

    setColumnsKeys(newRowsKeys)
  }

  const getColumnsShow = useCallback(
    (key: string) => columnKeys.includes(key),
    [columnKeys],
  )

  const { data, isLoading } = useOrderTrackerList({ params: filter })

  useEffect(() => {
    trackEvent('adminOrderTrackerPageView')
  }, [])

  const getValueFormattedOrEmpty = (
    value: number | null | undefined | string,
    currency: 'MXN' | 'USD' | '%' | 'KGs',
    showZero = false,
  ) => {
    if (
      (value === null ||
        value === undefined ||
        isNaN(+value) ||
        +value === 0) &&
      !showZero
    ) {
      return '-'
    }

    const newValue = value ? +value : 0

    if (currency === '%') {
      return `${formatNumber(newValue, 'es-MX')}%`
    }

    if (currency === 'KGs') {
      return `${formatNumber(newValue, 'es-MX')} KGs`
    }

    return formatCurrency(newValue, 'es-MX', currency)
  }

  const convertStringToNumber = (value: string | number): number => {
    if (isNaN(Number(value))) {
      return 0
    }
    return Number(value)
  }

  const aggregateDataByMonth = (): OrderTrackerProps[] => {
    if (!data?.data?.results) return []

    const groupedData = data.data.results.reduce(
      (accumulator, currentItem: OrderTrackerProps) => {
        let date = currentItem.cashFlowData.orderCreationDate

        if (
          keyAggregatedData === 'orderExpectedDeliveryDate' &&
          currentItem.cashFlowData.orderExpectedDeliveryDate
        ) {
          date = currentItem.cashFlowData.orderExpectedDeliveryDate
        }

        if (
          currentItem.cashFlowData.orderCreationDate ===
          currentItem.cashFlowData.orderExpectedDeliveryDate
        ) {
          date = currentItem.cashFlowData.orderCreationDate
        }

        const [year, month] = date.split('-')

        const key = `${year}-${month}`

        if (!accumulator[key]) {
          accumulator[key] = {
            grossProfitData: {
              orderCustomsFreightCost: '0',
              orderFinancingCost: '0',
              orderFirstMileFreightCost: '0',
              orderFreightPrice: '0',
              orderGmv: '0',
              orderIvaDue: '0',
              orderLastMileFreightCost: '0',
              orderTotalProductCost: '0',
              orderTotalTraderCommission: '0',
            },
            cashFlowData: {
              orderCreationDate: key,
            },
            itemsData: [] as OrderTrackerProps['itemsData'],
          } as OrderTrackerProps
        }

        const orderCustomsFreightCost =
          convertStringToNumber(
            currentItem.grossProfitData.orderCustomsFreightCost,
          ) +
          convertStringToNumber(
            accumulator[key].grossProfitData.orderCustomsFreightCost,
          )

        accumulator[key].grossProfitData.orderCustomsFreightCost =
          orderCustomsFreightCost.toString()

        const orderFinancingCost =
          convertStringToNumber(
            currentItem.grossProfitData.orderFinancingCost,
          ) +
          convertStringToNumber(
            accumulator[key].grossProfitData.orderFinancingCost,
          )

        accumulator[key].grossProfitData.orderFinancingCost =
          orderFinancingCost.toString()

        const orderFirstMileFreightCost =
          convertStringToNumber(
            currentItem.grossProfitData.orderFirstMileFreightCost,
          ) +
          convertStringToNumber(
            accumulator[key].grossProfitData.orderFirstMileFreightCost,
          )

        accumulator[key].grossProfitData.orderFirstMileFreightCost =
          orderFirstMileFreightCost.toString()

        const orderFreightPrice =
          convertStringToNumber(currentItem.grossProfitData.orderFreightPrice) +
          convertStringToNumber(
            accumulator[key].grossProfitData.orderFreightPrice,
          )

        accumulator[key].grossProfitData.orderFreightPrice =
          orderFreightPrice.toString()

        const orderGmv =
          convertStringToNumber(currentItem.grossProfitData.orderGmv) +
          convertStringToNumber(accumulator[key].grossProfitData.orderGmv)

        accumulator[key].grossProfitData.orderGmv = orderGmv.toString()

        const orderIvaDue =
          convertStringToNumber(currentItem.grossProfitData.orderIvaDue) +
          convertStringToNumber(accumulator[key].grossProfitData.orderIvaDue)

        accumulator[key].grossProfitData.orderIvaDue = orderIvaDue.toString()

        const orderLastMileFreightCost =
          convertStringToNumber(
            currentItem.grossProfitData.orderLastMileFreightCost,
          ) +
          convertStringToNumber(
            accumulator[key].grossProfitData.orderLastMileFreightCost,
          )

        accumulator[key].grossProfitData.orderLastMileFreightCost =
          orderLastMileFreightCost.toString()

        const orderTotalProductCost =
          convertStringToNumber(
            currentItem.grossProfitData.orderTotalProductCost,
          ) +
          convertStringToNumber(
            accumulator[key].grossProfitData.orderTotalProductCost,
          )

        accumulator[key].grossProfitData.orderTotalProductCost =
          orderTotalProductCost.toString()

        const orderTotalTraderCommission =
          convertStringToNumber(
            currentItem.grossProfitData.orderTotalTraderCommission,
          ) +
          convertStringToNumber(
            accumulator[key].grossProfitData.orderTotalTraderCommission,
          )

        accumulator[key].grossProfitData.orderTotalTraderCommission =
          orderTotalTraderCommission.toString()

        currentItem?.itemsData?.forEach((element) => {
          accumulator[key].itemsData.push(element)
        })

        return accumulator
      },
      {} as Record<string, OrderTrackerProps>,
    )

    return Object.values(groupedData)
  }

  const aggregateData = aggregateDataByMonth()

  const columns: TableRowsColumnProps<OrderTrackerProps>[] = [
    {
      key: 'orderPerformanceIndicators',
      title: t('tablePerformanceIndicatorsLabel'),
      dataKey: 'grossProfitData',
      isHighlight: true,
      render: ({ record }) => {
        const date = record.cashFlowData.orderCreationDate
        const [year, month] = date.split('-')

        return `${month}/${year}`
      },
    },
    {
      key: 'orderDiscount',
      title: t('tableDiscountLabel'),
      dataKey: 'grossProfitData.orderDiscount',
      render: ({ value }) => getValueFormattedOrEmpty(value, 'USD'),
    },
    {
      key: 'orderTransportPrice',
      title: t('tableTransportPriceLabel'),
      dataKey: 'grossProfitData.orderFreightPrice',
      render: ({ value }) => getValueFormattedOrEmpty(value, 'USD', true),
    },
    {
      key: 'orderIva',
      title: t('tableIvaLabel'),
      dataKey: 'grossProfitData.orderIvaDue',
      render: ({ value }) => getValueFormattedOrEmpty(value, 'USD'),
    },
    {
      key: 'orderGmv',
      title: t('tableGmvLabel'),
      isBold: true,
      dataKey: 'grossProfitData.orderGmv',
      render: ({ value }) => getValueFormattedOrEmpty(value, 'USD'),
    },
    {
      key: 'orderGmvMxn',
      title: t('tableGmvMxnLabel'),
      dataKey: 'grossProfitData.orderGmv',
      render: ({ value, record }) =>
        formatCurrency(
          +(value || 0) * +(record?.cashFlowData?.dollarExchangeRate || 1),
          'es-MX',
          'MXN',
        ),
    },
    {
      key: 'orderCogs',
      title: t('tableCogsLabel'),
      isBold: true,
      dataKey: 'grossProfitData.orderTotalProductCost',
      render: ({ record }) =>
        getValueFormattedOrEmpty(calculateCogs(record), 'USD'),
    },
    {
      key: 'orderGrossProfitCogs',
      title: t('tableGrossProfitLabel'),
      dataKey: 'grossProfitData',
      render: ({ record }) =>
        getValueFormattedOrEmpty(calculateGrossProfit(record), 'USD'),
    },
    {
      key: 'gmPercentage',
      title: t('tableGmPercentageLabel'),
      dataKey: 'grossProfitData',
      render: ({ record }) =>
        getValueFormattedOrEmpty(calculateGMVPercentage(record), '%'),
    },
    {
      key: 'orderCustomsOperations',
      title: t('tableCustomsOperationsLabel'),
      dataKey: 'grossProfitData.orderCustomsFreightCost',
      render: ({ value }) => getValueFormattedOrEmpty(value, 'USD'),
    },
    {
      key: 'orderDistributionFirstMile',
      title: t('tableDistributionFirstMileLabel'),
      dataKey: 'grossProfitData.orderFirstMileFreightCost',
      render: ({ value }) => getValueFormattedOrEmpty(value, 'USD'),
    },
    {
      key: 'cm1',
      title: t('tableCm1Label'),
      dataKey: 'grossProfitData',
      isBold: true,
      render: ({ record }) =>
        getValueFormattedOrEmpty(calculateCM1(record), 'USD'),
    },
    {
      key: 'cm1Percentage',
      title: t('tableCm1PercentageLabel'),
      dataKey: 'grossProfitData',
      render: ({ record }) =>
        getValueFormattedOrEmpty(calculateCM1Percentage(record), '%'),
    },

    {
      key: 'warehouse',
      title: t('tableWarehouseLabel'),
      dataKey: 'grossProfitData.orderWarehouseFreightCost',
    },
    {
      key: 'orderDistributionLastMile',
      title: t('tableDistributionLastMileLabel'),
      dataKey: 'grossProfitData.orderLastMileFreightCost',
      render: ({ value }) => getValueFormattedOrEmpty(value, 'USD'),
    },
    {
      key: 'cm2',
      title: t('tableCm2Label'),
      isBold: true,
      dataKey: 'grossProfitData',
      render: ({ record }) =>
        getValueFormattedOrEmpty(calculateCM2(record), 'USD'),
    },
    {
      key: 'cm2Percentage',
      title: t('tableCm2PercentageLabel'),
      dataKey: 'grossProfitData',
      render: ({ record }) =>
        getValueFormattedOrEmpty(calculateCM2Percentage(record), '%'),
    },
    {
      key: 'orderFinancingCosts',
      title: t('tableFinancingCostsLabel'),
      dataKey: 'grossProfitData.orderFinancingCost',
      render: ({ record }) =>
        getValueFormattedOrEmpty(calculateFinancingCost(record), 'USD'),
    },
    {
      key: 'orderReferralCommission',
      title: t('tableReferralCommissionLabel'),
      dataKey: 'grossProfitData.orderTotalTraderCommission',
      render: ({ value }) => getValueFormattedOrEmpty(value, 'USD'),
    },
    {
      key: 'cm3',
      title: t('tableCm3Label'),
      isBold: true,
      dataKey: 'grossProfitData',
      render: ({ record }) =>
        getValueFormattedOrEmpty(calculateCM3(record), 'USD'),
    },
    {
      key: 'cm3Percentage',
      title: t('tableCm3PercentageLabel'),
      dataKey: 'grossProfitData',
      render: ({ record }) =>
        getValueFormattedOrEmpty(calculateCM3Percentage(record), '%'),
    },
    {
      key: 'polymers',
      title: t('tablePolymersLabel'),
      dataKey: 'itemsData.productData.productUuid',
      isHighlight: true,
      render: ({ record }) =>
        getValueFormattedOrEmpty(
          calculateGMVByCategory(record, 'polymers'),
          'USD',
        ),
    },
    {
      key: 'engineering',
      title: t('tableEngineeringLabel'),
      dataKey: 'itemsData',
      render: ({ record }) =>
        getValueFormattedOrEmpty(
          calculateGMVByCategory(record, 'polymers-engineering'),
          'USD',
        ),
    },
    {
      key: 'pe',
      title: t('tablePeLabel'),
      dataKey: 'itemsData',
      render: ({ record }) =>
        getValueFormattedOrEmpty(
          calculateGMVByCategory(record, 'polymers-pe'),
          'USD',
        ),
    },
    {
      key: 'pp',
      title: t('tablePpLabel'),
      dataKey: 'itemsData',
      render: ({ record }) =>
        getValueFormattedOrEmpty(
          calculateGMVByCategory(record, 'polymers-pp'),
          'USD',
        ),
    },
    {
      key: 'steel',
      title: t('tableSteelLabel'),
      dataKey: 'itemsData.productData.productUuid',
      isHighlight: true,
      render: ({ record }) =>
        getValueFormattedOrEmpty(
          calculateGMVByCategory(record, 'steel'),
          'USD',
        ),
    },
    {
      key: 'mildSteel',
      title: t('tableMildSteelLabel'),
      dataKey: 'itemsData',
      render: ({ record }) =>
        getValueFormattedOrEmpty(
          calculateGMVByCategory(record, 'mild-steel'),
          'USD',
        ),
    },
    {
      key: 'stainlessSteel',
      title: t('tableStainlessSteelLabel'),
      dataKey: 'itemsData',
      render: ({ record }) =>
        getValueFormattedOrEmpty(
          calculateGMVByCategory(record, 'stainless-steel'),
          'USD',
        ),
    },
    {
      key: 'polymersCogs',
      title: t('tablePolymersCogsLabel'),
      dataKey: 'itemsData.productData.productUuid',
      isHighlight: true,
      render: ({ record }) =>
        getValueFormattedOrEmpty(
          calculateCOGSByCategory(record, 'polymers'),
          'USD',
        ),
    },
    {
      key: 'engineeringCogs',
      title: t('tableEngineeringCogsLabel'),
      dataKey: 'itemsData',
      render: ({ record }) =>
        getValueFormattedOrEmpty(
          calculateCOGSByCategory(record, 'polymers-engineering'),
          'USD',
        ),
    },
    {
      key: 'peCogs',
      title: t('tablePeCogsLabel'),
      dataKey: 'itemsData',
      render: ({ record }) =>
        getValueFormattedOrEmpty(
          calculateCOGSByCategory(record, 'polymers-pe'),
          'USD',
        ),
    },
    {
      key: 'ppCogs',
      title: t('tablePpCogsLabel'),
      dataKey: 'itemsData',
      render: ({ record }) =>
        getValueFormattedOrEmpty(
          calculateCOGSByCategory(record, 'polymers-pp'),
          'USD',
        ),
    },
    {
      key: 'steelCogs',
      title: t('tableSteelCogsLabel'),
      dataKey: 'itemsData.productData.productUuid',
      isHighlight: true,
      render: ({ record }) =>
        getValueFormattedOrEmpty(
          calculateCOGSByCategory(record, 'steel'),
          'USD',
        ),
    },
    {
      key: 'mildSteelCogs',
      title: t('tableMildSteelCogsLabel'),
      dataKey: 'itemsData',
      render: ({ record }) =>
        getValueFormattedOrEmpty(
          calculateCOGSByCategory(record, 'mild-steel'),
          'USD',
        ),
    },
    {
      key: 'stainlessSteelCogs',
      title: t('tableStainlessSteelCogsLabel'),
      dataKey: 'itemsData',
      render: ({ record }) =>
        getValueFormattedOrEmpty(
          calculateCOGSByCategory(record, 'stainless-steel'),
          'USD',
        ),
    },
  ]

  const memoizedColumns = useMemo(
    () =>
      columns
        .filter((column) => getColumnsShow(column.key as string))
        .map((c) => c),
    [columns, getColumnsShow],
  )
  return (
    <div>
      <Header
        title={t('titleTotalEconomics')}
        description={t('titleTotalEconomicsDescription')}
      />

      <div className={styles.content}>
        <div>
          <div className={styles.filter}>
            <Select
              options={[
                { label: 'Created At', value: 'orderCreationDate' },
                {
                  label: 'Expected Delivery Date',
                  value: 'orderExpectedDeliveryDate',
                },
              ]}
              value={keyAggregatedData}
              onChange={setKeyAggregatedData}
            />

            <div className={styles['filter-group']}>
              <DropDown.Root>
                <DropDown.ButtonTrigger
                  variant="secondary"
                  model="square"
                  hasArrow={false}
                >
                  <SettingIcon />
                </DropDown.ButtonTrigger>

                <DropDown.Content orientation="bottom-right">
                  {columns?.map((column) => (
                    <DropDown.Item key={column?.key as string}>
                      <Checkbox
                        onChange={() => onHandleRowsShow(column?.key as string)}
                        value={getColumnsShow(column?.key as string)}
                      >
                        {column.title}
                      </Checkbox>
                    </DropDown.Item>
                  ))}
                </DropDown.Content>
              </DropDown.Root>
            </div>
          </div>

          <div className={styles.table}>
            <TableRows
              columns={memoizedColumns}
              data={aggregateData || []}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
