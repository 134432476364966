import { useTranslation } from 'react-i18next'

import { Text, Radio } from '@mercai/clever'

import { formatLongDate, formatCurrency, formatNumber } from '@/helpers'

import { Progress } from './components'

import { generateClasses } from './select-order-item.constants'

import styles from './select-order-item.module.css'

import type { SelectOrderItemProps } from './select-order-item.type'

export const SelectOrderItem = ({
  saleOrder,
  isActive,
  onSelect,
}: SelectOrderItemProps) => {
  const { t } = useTranslation('screenOrderCenterPage')

  const currencySaleOrder = saleOrder?.payments[0]?.currency
  const dollarExchangeRate = saleOrder?.payments?.[0]?.dollarExchangeRate

  const getCurrencyValue = (value: string = '0') => {
    return currencySaleOrder === 'USD'
      ? +value
      : Number(value) * Number(dollarExchangeRate || 0)
  }

  const totalOrderPrice = getCurrencyValue(saleOrder?.totalOrderPrice)
  const totalPaid = getCurrencyValue(saleOrder?.totalPaidValue)

  const calculatePercentagePaid = () => {
    const calculated = (+(totalPaid || 0) / +(totalOrderPrice || 0)) * 100

    return isNaN(calculated) ? 0 : calculated
  }

  const percentagePaid = calculatePercentagePaid()

  return (
    <div
      className={generateClasses(isActive)}
      onClick={() => onSelect(isActive)}
    >
      <div className={styles.row}>
        <div className={styles['row-item']}>
          <Radio value={isActive} onChange={() => onSelect(isActive)} />

          <Text variant="title-base">#{saleOrder.reference}</Text>
        </div>

        <Text variant="title-base">{formatLongDate(saleOrder.createdAt)}</Text>
      </div>

      <div className={styles.content}>
        <div className={styles.row}>
          <Text variant="title-base">
            {t('formTransactionStepSelectOrderItemProgress')}
          </Text>

          <Text>{formatNumber(percentagePaid)}%</Text>
        </div>

        <Progress percentage={percentagePaid} />

        <div className={styles['progress-values']}>
          <div className={styles['progress-values-row']}>
            <div className={styles['square-active']} />

            <Text variant="title-base">
              {t('formTransactionStepSelectOrderItemPaid')}
              <Text>
                {formatCurrency(totalPaid, 'es-MX', currencySaleOrder)}
              </Text>
            </Text>
          </div>

          <div className={styles['progress-values-row']}>
            <div className={styles['square-inactive']} />

            <Text variant="title-base">
              {t('formTransactionStepSelectOrderItemPaidRest')}{' '}
              <Text>
                {formatCurrency(
                  +(totalOrderPrice || 0) - totalPaid,
                  'es-MX',
                  currencySaleOrder,
                )}
              </Text>
            </Text>
          </div>
        </div>
      </div>

      <div className={styles['payment-conditions']}>
        <Text variant="title-base">
          {t('formTransactionStepSelectOrderItemPaymentConditions')}
        </Text>

        <div className={styles['payment-conditions-list']}>
          {saleOrder?.payments?.map((item, index) => (
            <div
              className={styles['payment-conditions-list-row']}
              key={item.uuid}
            >
              <Text variant="title-small">Pago {index + 1}: </Text>

              <Text variant="text-small">
                {formatCurrency(item.value, 'es-MX', currencySaleOrder)}
              </Text>
            </div>
          ))}

          <div className={styles['payment-conditions-list-row']}>
            <Text variant="title-small">
              {t('formTransactionStepSelectOrderItemPaymentConditionsAmount')}
            </Text>

            <Text variant="text-small">
              {formatCurrency(totalOrderPrice, 'es-MX', currencySaleOrder)}
            </Text>
          </div>
        </div>
      </div>
    </div>
  )
}
