import { Text } from '@mercai/clever'
import { ReactBadgeProps } from './react-badge.type'

import styles from './react-badge.module.css'

export const ReactBadge = ({ author, x }: ReactBadgeProps) => {
  return (
    <div className={styles.core} style={{ left: `${x}px` }}>
      <img src="/images/like-badge.svg" alt={`like-badge-${author}`} />
      <Text className={styles.author}>{author}</Text>
    </div>
  )
}
