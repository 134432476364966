import { useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { Button, DrawerStepper } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import {
  useValidationErrors,
  onSuccessMessage,
  onErrorMessage,
  deepCopy,
} from '@/helpers'

import {
  useAddressList,
  useCreateAddress,
  useUpdateProfile,
  QUERIES_ADMIN,
  useCreateProfile,
  useProfileDetail,
  useUserList,
} from '@/services'

import { AddressProps, ProfileProps } from '@/types'

import { shemaAddressItem, schemaProfile } from './drawer-form.constants'

import { AddressForm, AddressList, ProfileForm } from './components'

import type { DrawerProfileFormProps } from './drawer-form.type'

export const ProfileDrawerForm = ({
  isOpen,
  onHandleOpen,
  profileUuid,
}: DrawerProfileFormProps) => {
  const { t } = useTranslation('screenProfileList')

  const isNewRegister = !profileUuid

  const queryClient = useQueryClient()

  const [data, setData] = useState<Partial<ProfileProps>>({})

  const {
    isValid: isValidProfile,
    errors: profileErrors,
    onHandleListenErrors: onHandleListenErrorsProfile,
  } = useValidationErrors(schemaProfile(t), data)

  const { data: dataProfile } = useProfileDetail(profileUuid || '')

  const [addressItem, setAddressItem] = useState<Partial<AddressProps>>({})

  const {
    isValid: isValidAddressItem,
    errors: addressItemErrors,
    onHandleListenErrors: onHandleListenErrorsAddressItem,
  } = useValidationErrors(shemaAddressItem(t), addressItem)

  const { data: dataAddresses } = useAddressList(data?.uuid || '')

  const onHandleNewAddress = ({
    onNavigateToNextStep,
  }: {
    onNavigateToNextStep: (route: string) => void
  }) => {
    onNavigateToNextStep('addressForm')
  }

  const { mutateAsync } = useCreateAddress(data?.uuid || '')

  const onSubmitNewAddress = async ({
    onNavigateToPreviousStep,
  }: {
    onNavigateToPreviousStep: () => void
  }) => {
    if (isValidAddressItem) {
      const response = await mutateAsync(addressItem)

      if (response.success) {
        onHandleListenErrorsAddressItem(false)
        onNavigateToPreviousStep()

        setAddressItem(response?.data || {})

        queryClient.invalidateQueries({
          queryKey: [QUERIES_ADMIN.ADDRESS.module, data?.uuid],
        })
      }
    } else {
      onHandleListenErrorsAddressItem(true)
    }
  }

  const { mutateAsync: mutateAsyncUpdateProfile } = useUpdateProfile()
  const { mutateAsync: mutateAsyncCreateProfile } = useCreateProfile()

  const onSubmitCompleted = async () => {
    let response: {
      success: boolean
      message: string
      data?: ProfileProps
    } = {
      success: false,
      message: '',
      data: {} as ProfileProps,
    }

    if (isNewRegister) {
      response = await mutateAsyncCreateProfile(data as ProfileProps)
    } else {
      response = await mutateAsyncUpdateProfile(data as ProfileProps)
    }

    if (response.success) {
      if (isNewRegister) {
        onSuccessMessage(
          t('profileFormSuccessCreateTitle'),
          t('profileFormSuccessCreateMessage'),
        )

        onHandleOpen(false)
      } else {
        onSuccessMessage(
          t('profileFormSuccessUpdateTitle'),
          t('profileFormSuccessUpdateMessage'),
        )
      }

      queryClient.invalidateQueries({
        queryKey: [QUERIES_ADMIN.PROFILE.module],
      })

      setData({})
      onHandleOpen(false)
    } else {
      if (isNewRegister) {
        onErrorMessage(
          t('profileFormErrorCreateTitle'),
          t('profileFormErrorCreateMessage'),
        )
      } else {
        onErrorMessage(
          t('profileFormErrorUpdateTitle'),
          t('profileFormErrorUpdateMessage'),
        )
      }
    }
  }

  const onSubmitProfileForm = ({
    onNavigateToNextStep,
  }: {
    onNavigateToNextStep: (route: string) => void
  }) => {
    if (isValidProfile) {
      onHandleListenErrorsProfile(false)

      if (isNewRegister) {
        onSubmitCompleted()
      } else {
        onNavigateToNextStep('addressList')
      }
    } else {
      onHandleListenErrorsProfile(true)
    }
  }

  useEffect(() => {
    if (isOpen && !isNewRegister && dataProfile?.data) {
      const deepDataCopy = deepCopy(dataProfile.data)

      if (deepDataCopy && deepDataCopy.taxSituation) {
        delete (deepDataCopy as { taxSituation?: string }).taxSituation
      }

      setData({
        ...deepDataCopy,
        keyAccountManagerId: dataProfile?.data?.keyAccountManager?.id
          ? `${dataProfile?.data?.keyAccountManager?.id}`
          : undefined,
        customerLevelSlug: dataProfile?.data?.customerLevel?.slug
          ? dataProfile?.data?.customerLevel?.slug
          : undefined,
        taxStatus: dataProfile?.data?.taxStatus || 'pending',
      })
    }

    if (!isOpen || isNewRegister) {
      setData({})
    }
  }, [isOpen, dataProfile?.data, isNewRegister])

  const { data: dataUsers } = useUserList({
    params: {
      limit: 100,
      page: 1,
      isStaff: 'true',
    },
  })

  return (
    <DrawerStepper
      translations={{
        optional: t('profileFormOptional'),
      }}
      isOpen={isOpen}
      onHandleOpen={onHandleOpen}
      title={
        isNewRegister
          ? t('profileFormTitleCreate')
          : t('profileFormTitleUpdate')
      }
      description={
        isNewRegister
          ? t('profileFormSubTitleCreate')
          : t('profileFormSubTitleUpdate')
      }
      initialStep="root"
      steps={{
        root: {
          title: t('profileFormGeneralFormTitle'),
          isOptional: false,
          backNavigate: 'root',
          component: (
            <ProfileForm
              errors={profileErrors as Record<string, string>}
              values={data || {}}
              onChangeValues={setData}
              usersData={dataUsers?.data?.results || []}
            />
          ),
          footer: ({ onNavigateToNextStep }) => (
            <>
              <Button variant="secondary" onClick={() => onHandleOpen(false)}>
                {t('profileFormNavigateButtonBack')}
              </Button>
              <Button
                variant="light"
                onClick={() => onSubmitProfileForm({ onNavigateToNextStep })}
              >
                {t('profileFormNavigateButtonNext')}
              </Button>

              {!isNewRegister && (
                <Button onClick={() => onSubmitCompleted()}>
                  {t('profileFormNavigateButtonFinish')}
                </Button>
              )}
            </>
          ),
        },
        addressList: {
          title: t('profileFormAddressListTitle'),
          isOptional: false,
          backNavigate: 'root',
          component: ({ onNavigateToNextStep }) => (
            <AddressList
              onClickNewAddress={() =>
                onHandleNewAddress({ onNavigateToNextStep })
              }
              addresses={dataAddresses?.data?.addresses || []}
            />
          ),
          footer: ({ onNavigateToPreviousStep }) => (
            <>
              <Button variant="secondary" onClick={onNavigateToPreviousStep}>
                {t('profileFormNavigateButtonBack')}
              </Button>

              <Button onClick={() => onSubmitCompleted()}>
                {t('profileFormNavigateButtonFinish')}
              </Button>
            </>
          ),
        },
        addressForm: {
          title: t('profileFormAddressFormTitle'),
          isOptional: false,
          backNavigate: 'addressList',
          component: (
            <AddressForm
              onChangeValues={setAddressItem}
              values={addressItem}
              errors={addressItemErrors as Record<string, string>}
            />
          ),
          footer: ({ onNavigateToPreviousStep }) => (
            <>
              <Button variant="secondary" onClick={onNavigateToPreviousStep}>
                {t('profileFormNavigateButtonBack')}
              </Button>

              <Button
                onClick={() => onSubmitNewAddress({ onNavigateToPreviousStep })}
              >
                {t('profileFormNavigateButtonFinish')}
              </Button>
            </>
          ),
        },
      }}
    />
  )
}
