import { useEffect, useState, createContext, useContext } from 'react'

import { getAuthToken } from '@/helpers'
import { refreshToken } from '@/services'

import type {
  WebSocketProviderProps,
  WebSocketContextDataProps,
  WebSocketMessageProps,
} from './websocket.type'

export const WebSocketContext = createContext({} as WebSocketContextDataProps)

let socket: WebSocket

const WebSocketProvider = ({ children }: WebSocketProviderProps) => {
  const [isConnected, setIsConnected] = useState(false)
  const [messages, setMessages] = useState<WebSocketMessageProps[]>([])

  let onMessage: ((message: WebSocketMessageProps) => void) | null = null

  const setOnMessage = (callback: (message: WebSocketMessageProps) => void) => {
    onMessage = callback
  }

  const sendMessage = (payload: WebSocketMessageProps) => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(
        JSON.stringify({ type: payload.type, message: payload.message }),
      )
    } else {
      console.error('WebSocket disconnected')
    }
  }

  useEffect(() => {
    const connectWebSocket = async () => {
      const token = getAuthToken()

      try {
        socket = new WebSocket(
          `wss://websocket-app-production.up.railway.app/client?token=${token}`,
        )

        socket.onopen = () => {
          console.log('websocket connected')
          setIsConnected(true)
        }

        socket.onmessage = (event) => {
          console.log('websocket message')
          const message = JSON.parse(event.data)
          setMessages((prevMessages) => [...prevMessages, message])

          if (onMessage) {
            onMessage(message)
          }
        }

        socket.onerror = async () => {
          try {
            const newToken = await refreshToken()

            socket = new WebSocket(
              `wss://websocket-app-production.up.railway.app/client?token=${newToken}`,
            )
          } catch (error) {
            console.error('Failed to reconnect websocket:', error)
          }
        }
      } catch (error) {
        console.error('Failed to connect websocket:', error)
      }
    }

    connectWebSocket()
  }, [])

  return (
    <WebSocketContext.Provider
      value={{
        isConnected,
        sendMessage,
        messages,
        registerOnMessage: setOnMessage,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  )
}

const useWebsocket = (): WebSocketContextDataProps => {
  const context = useContext(WebSocketContext)

  return context
}

export { WebSocketProvider, useWebsocket }
