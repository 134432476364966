import { Card, Text } from '@mercai/clever'
import { MetricsProps } from './metrics.types'

import styles from './metrics.module.css'
import { useTranslation } from 'react-i18next'
import { formatDate } from '@/helpers'

export const Metrics = ({ values }: MetricsProps) => {
  const { t } = useTranslation('screenSupplierShow')

  return (
    <Card>
      <div>
        <Text variant="text-large-bold">{t('supplierMetricsTitle')}</Text>
        <div className={styles['supplier-infos']}>
          <Text>
            <strong>{t('supplierMetricsEmail')}</strong>
            {`${values?.email || t('supplierMetricsEmpty')}`}
          </Text>
          <Text>
            <strong>{t('supplierMetricsPhone')}</strong>
            {`${values?.phone || t('supplierMetricsEmpty')}`}
          </Text>
          <Text>
            <strong>{t('supplierMetricsCreatedAt')}</strong>
            {`${values?.createdAt ? formatDate(values?.createdAt) : t('supplierMetricsEmpty')}`}
          </Text>
        </div>
      </div>
    </Card>
  )
}
