/* eslint-disable */
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Accordion, ReceiptIcon, DownloadIcon, Text } from "@mercai/clever";

import { downloadFile, getFileNameAndExtension } from "@/helpers";

import styles from "./order-finance-documents.module.css";

import type { OrderFinanceDocumentsProps } from "./order-finance-documents.type";

export const OrderFinanceDocuments = ({
  invoicePdf,
  invoiceXml,
  taxSituation,
  payments,
}: OrderFinanceDocumentsProps) => {
  const [activeItems, setActiveItems] = useState<string[]>([]);

  const { t } = useTranslation("componentOrderCenterShow");

  const onClickDownload = (url: string) => {

    const { fileName, extension } = getFileNameAndExtension(url)

    downloadFile(url, `${fileName}.${extension}`);
  };

  const orderedPayments = payments.sort((a, b) => a.instalment - b.instalment);

  return (
    <Accordion.Root
      activeItems={activeItems}
      onHandleActiveItems={setActiveItems}
      className={styles.core}
    >
      <Accordion.Item
        id="info-list"
        header={
          <div className={styles["box-title"]}>
            <ReceiptIcon />

            <Text variant="title-base" className={styles.title}>
              {t("orderFinanceDocumentsTitle")}
            </Text>
          </div>
        }
      >
        <div className={styles.content}>
          {orderedPayments.map((payment) => (
            <button
              key={payment.uuid}
              type="button"
              className={styles.item}
              disabled={!payment?.proof}
              onClick={() => onClickDownload(payment.proof || "")}
            >
              {t("orderFinanceDocumentsProof")}
              {t(`orderFinanceDocumentsForms_${payment?.form}`)}
              {` ${payment.instalment}`}
              <DownloadIcon />
            </button>
          ))}
          <button
            type="button"
            className={styles.item}
            disabled={!invoicePdf}
            onClick={() => onClickDownload(invoicePdf || "")}
          >
            {t("orderFinanceDocumentsInvoicePDF")}
            <DownloadIcon />
          </button>
          <button
            type="button"
            className={styles.item}
            disabled={!invoiceXml}
            onClick={() => onClickDownload(invoiceXml || "")}
          >
            {t("orderFinanceDocumentsInvoiceXML")}
            <DownloadIcon />
          </button>
          <button
            type="button"
            className={styles.item}
            disabled={!taxSituation}
            onClick={() => onClickDownload(taxSituation || "")}
          >
            {t("orderFinanceDocumentsTaxSituation")}
            <DownloadIcon />
          </button>
          <button type="button" className={styles.item} disabled>
            {t("orderFinanceDocumentsLogisticInvoice")}
            <DownloadIcon />
          </button>
          <button type="button" className={styles.item} disabled>
            {t("orderFinanceDocumentsSupplierInvoice")}
            <DownloadIcon />
          </button>
          <button type="button" className={styles.item} disabled>
            {t("orderFinanceDocumentsTaxInvoice")}
            <DownloadIcon />
          </button>
        </div>
      </Accordion.Item>
    </Accordion.Root>
  );
};
