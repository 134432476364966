import styles from './table.module.css'

import { TableColumnAlignEnum } from './table.type'

export const alignOptions: Record<TableColumnAlignEnum, string> = {
  left: styles['column-align-left'],
  center: styles['column-align-center'],
  right: styles['column-align-right'],
}

export const generateClassesColumn = (
  align: keyof typeof TableColumnAlignEnum,
  className?: string,
) => {
  return `${alignOptions[align]} ${className || ''}`
}

export const expandableIcon: Record<string, string> = {
  open: styles['section-expandable-icon-state-open'],
  close: '',
}

export const generateClassesExpandableIcon = (
  isOpen: boolean,
  className?: string,
) => {
  return `${styles['section-expandable-icon']} ${
    expandableIcon[isOpen ? 'open' : 'close']
  } ${className || ''}`
}

export const expandableRow: Record<string, string> = {
  even: styles['section-type-body-expandable-row-even'],
  odd: styles['section-type-body-expandable-row-odd'],
}

export const generateClassesExpandableRow = (
  index: number,
  hasBorder = false,
  className?: string,
) => {
  const borderClass = hasBorder
    ? styles['section-type-body-expandable-row-border']
    : ''

  return `${expandableRow[index % 2 === 0 ? 'even' : 'odd']} ${borderClass} ${
    className || ''
  }`
}
