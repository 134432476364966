import { useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Checkbox,
  CloseIcon,
  Drawer,
  Input,
  InputPhone,
  Label,
  Text,
} from '@mercai/clever'
import { UserProps } from '@/types'
import { useValidationErrors } from '@/helpers'
import {
  useCreateUserInProfile,
  useUpdateUserInProfile,
  QUERIES_ADMIN,
} from '@/services'

import { UsersDrawerFormProps } from './drawer-form.type'
import { defaultValues, shemaValidation } from './drawer-form.constants'
import { ModalConfirmDrawerClose } from '../modal-confirm-drawer-close'

import styles from './drawer-form.module.css'

export const UsersDrawerForm = ({
  data,
  isOpen,
  onClose,
  profileUuid,
}: UsersDrawerFormProps) => {
  const { t } = useTranslation('screenProfileShow')

  const queryClient = useQueryClient()

  const isEdit = !!data

  const [isOpenDrawerConfirmClose, setIsOpenDrawerConfirmClose] =
    useState(false)
  const [hasChange, setHasChange] = useState(false)

  const [values, setValues] = useState<UserProps>(defaultValues)

  const { mutateAsync: createUserInProfile, isPending: isCreating } =
    useCreateUserInProfile({
      uuid: profileUuid,
    })

  const { mutateAsync: updateUserInProfile, isPending: isUpdating } =
    useUpdateUserInProfile({
      uuid: profileUuid,
    })

  const { errors, isValid, onHandleListenErrors } = useValidationErrors(
    shemaValidation(t),
    {
      ...values,
    },
  )

  const getErrorMessage = (key: string) => {
    return errors?.[key] as string
  }

  const onChangeValues = (key: string, newValue: string | boolean) => {
    if (
      (!hasChange && key === 'phone' && newValue !== data?.phone) ||
      (!hasChange && key !== 'phone')
    ) {
      setHasChange(true)
    }

    setValues({
      ...values,
      [key]: newValue,
    })
  }

  const onHandleClose = () => {
    if (hasChange) {
      setIsOpenDrawerConfirmClose(true)
    } else {
      onClose()
    }
  }

  const onHandleSubmit = async () => {
    if (!isValid) {
      onHandleListenErrors(true)
      return
    }

    onHandleListenErrors(false)

    const mutateAsync = isEdit ? updateUserInProfile : createUserInProfile

    await mutateAsync(values)

    queryClient.refetchQueries({
      queryKey: [QUERIES_ADMIN.PROFILE.module, profileUuid],
    })

    onClose()
  }

  useEffect(() => {
    if (data) {
      setValues({
        id: data?.id,
        name: data?.name,
        email: data?.email,
        phone: data?.phone || defaultValues.phone,
        isActive: data?.isActive,
      } as UserProps)
    }

    return () => {
      setValues(defaultValues)
      onHandleListenErrors(false)
      setHasChange(false)
    }
  }, [data, isOpen])

  useEffect(() => {
    if (!isOpen) {
      setValues(defaultValues)
      onHandleListenErrors(false)
      setHasChange(false)
    }
  }, [isOpen])

  return (
    <>
      <Drawer isOpen={isOpen} onHandleOpen={onHandleClose}>
        <div className={styles.core}>
          <div className={styles.header}>
            <Text variant="title-large" className={styles['header-title']}>
              {!isEdit
                ? t('userTableDrawerCreateTitle')
                : t('userTableDrawerUpdateTitle')}
            </Text>

            <button
              className={styles['header-button']}
              type="button"
              onClick={onHandleClose}
            >
              <CloseIcon />
            </button>
          </div>
          <div className={styles.content}>
            <div className={styles['space-input']}>
              <Label htmlFor="firstName">{t('userTableDrawerNameLabel')}</Label>
              <Input
                value={values?.name}
                onChange={(val) => onChangeValues('name', val)}
                id="name"
                placeholder={t('userTableDrawerNamePlaceholder')}
                error={getErrorMessage('name')}
              />
            </div>

            <div className={styles['space-input']}>
              <Label htmlFor="email">{t('userTableDrawerEmailLabel')}</Label>
              <Input
                value={values?.email}
                onChange={(val) =>
                  !isEdit ? onChangeValues('email', val) : null
                }
                id="email"
                disabled={isEdit}
                placeholder={t('userTableDrawerEmailPlaceholder')}
                error={getErrorMessage('email')}
                className={styles['input-email']}
              />
            </div>
            <div className={styles['space-input']}>
              <Label htmlFor="phone">{t('userTableDrawerPhoneLabel')}</Label>
              <InputPhone
                value={values?.phone}
                onChange={(val) => onChangeValues('phone', val)}
                error={getErrorMessage('phone')}
                defaultCountry={'+52'}
              />
            </div>
            <div className={styles['space-input']}>
              <Label htmlFor="phone">{t('userTableDrawerStatusLabel')}</Label>
              <Checkbox
                onChange={(val) => onChangeValues('isActive', val)}
                value={values?.isActive}
              >
                {t('userTableDrawerStatusLabel')}
              </Checkbox>
            </div>
          </div>
          <div className={styles.footer}>
            <Button onClick={onHandleClose} variant="secondary">
              {t('userTableDrawerCancelButton')}
            </Button>
            <Button
              onClick={onHandleSubmit}
              disabled={!hasChange || isCreating || isUpdating}
            >
              {t('userTableDrawerConfirmButton')}
            </Button>
          </div>
        </div>
      </Drawer>
      <ModalConfirmDrawerClose
        isOpenModalConfirm={isOpenDrawerConfirmClose}
        setIsOpenModalConfirm={setIsOpenDrawerConfirmClose}
        onCloseDrawerForm={() => {
          setIsOpenDrawerConfirmClose(false)
          onClose()
        }}
      />
    </>
  )
}
