import * as Yup from 'yup'

import { TransportCompanyRouteResponseProps } from '@/types'

export interface RouteVehiclePriceResponseWithIndexProps
  extends TransportCompanyRouteResponseProps {
  index: number
}

export const schemaTransportCompanyRoutes = (t: (key: string) => string) => ({
  routes: Yup.array().of(
    Yup.object().shape({
      price: Yup.number().required(t('requiredMessage')),
      vehicleUuid: Yup.string().required(t('requiredMessage')),
    }),
  ),
})

export const groupedBylogisticRouteUuid = (
  data?: TransportCompanyRouteResponseProps[],
): Record<string, RouteVehiclePriceResponseWithIndexProps[]> | undefined =>
  data?.reduce<Record<string, RouteVehiclePriceResponseWithIndexProps[]>>(
    (acc, item, index) => {
      const { logisticRoute, logisticRouteUuid } = item
      if (!acc[logisticRouteUuid || logisticRoute?.uuid]) {
        acc[logisticRouteUuid || logisticRoute?.uuid] = []
      }
      acc[logisticRouteUuid || logisticRoute?.uuid].push({ ...item, index })
      return acc
    },
    {},
  )
