import { InputSearch, Skeleton, Text } from '@mercai/clever'

import { NewSupplierProductProps } from './new-supplier-product.types'
import { useState } from 'react'
import { useCatalogProductList } from '@/services'

import styles from './new-supplier-product.module.css'
import { CatalogProductProps } from '@/types'
import { useTranslation } from 'react-i18next'

export const NewSupplierProduct = ({
  productItem,
  onSelectProductItem,
}: NewSupplierProductProps) => {
  const { t } = useTranslation('screenSupplierList')

  const [search, setSearch] = useState('')

  const { data, isLoading } = useCatalogProductList({ search, limit: 10 })

  const onHandleSelect = (productItem: CatalogProductProps) => {
    onSelectProductItem(productItem)
  }

  const Loading = () => {
    return [1, 2, 3].map((val) => (
      <div key={val} className={styles.product}>
        <Skeleton height="1.5rem" width="100%" />
        <Skeleton height="1.2rem" width="40%" />
        <Skeleton height="1.2rem" width="45%" />
        <Skeleton height="1.2rem" width="25%" />
      </div>
    ))
  }

  const ProductButton = ({ product }: { product: CatalogProductProps }) => {
    const { attributes, category, reference, uuid } = product
    return (
      <button
        key={uuid}
        className={`${styles.product} ${uuid === productItem?.uuid ? styles.active : ''}`}
        onClick={() => onHandleSelect(product)}
      >
        <div>
          <Text variant="text-base">
            <strong>{attributes?.title}</strong>
          </Text>
        </div>
        <div className={styles.row}>
          <Text>
            <strong>{t('newSupplierProductsFormCategoryLabel')}</strong>{' '}
            {category?.name}
          </Text>
        </div>

        {reference && (
          <div className={styles.row}>
            <Text>
              <strong>{t('newSupplierProductsFormReferenceLabel')}</strong>{' '}
              {reference}
            </Text>
          </div>
        )}

        {attributes?.fluidity && (
          <div className={styles.row}>
            <Text>
              <strong>{t('newSupplierProductsFormFluidityLabel')}</strong>{' '}
              {attributes?.fluidity} g/10m
            </Text>
          </div>
        )}

        {attributes?.density && (
          <div className={styles.row}>
            <Text>
              <strong>{t('newSupplierProductsFormDensityLabel')}</strong>{' '}
              {attributes?.density} g/cm³
            </Text>
          </div>
        )}
      </button>
    )
  }

  return (
    <div className={styles.core}>
      <InputSearch
        value={search}
        onChange={setSearch}
        translations={{
          inputPlaceholder: t('newSupplierProductsFormInputSearchPlaceholder'),
        }}
      />

      {isLoading && <Loading />}

      {!isLoading && (
        <div className={styles.products}>
          {!data?.data?.results?.find(
            (product) => product.uuid === productItem?.uuid,
          ) &&
            productItem && <ProductButton product={productItem} />}
          {data?.data?.results?.map((product) => (
            <ProductButton product={product} key={product?.uuid} />
          ))}
        </div>
      )}
    </div>
  )
}
