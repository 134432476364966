import styles from './modal.module.css'

import { ModalSizeEnum } from './modal.type'

export const statesOverlay: Record<'open' | 'close', string> = {
  open: styles['overlay-state-open'],
  close: styles['overlay-state-close'],
}

export const generateOverlayClasses = (
  state: 'open' | 'close',
  className?: string,
) => {
  return `${statesOverlay[state]} ${className || ''}`
}

export const statesMain: Record<'open' | 'close', string> = {
  open: styles['main-state-open'],
  close: styles['main-state-close'],
}

export const variationSize: Record<ModalSizeEnum, string> = {
  large: styles['main-size-large'],
  medium: styles['main-size-medium'],
  small: styles['main-size-small'],
}

export const generateMainClasses = (
  state: 'open' | 'close',
  size: keyof typeof ModalSizeEnum,
  className?: string,
) => {
  return `${statesMain[state]} ${variationSize[size]} ${className || ''}`
}
