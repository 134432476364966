import type { SaleOrderFinanceProps, ProfileProps } from '@/types'

export enum TransactionCurrencyEnum {
  USD = 'USD',
  MXN = 'MXN',
}

export enum TransactionPaymentMethodEnum {
  bank_transfer = 'bank_transfer',
  cash = 'cash',
  credit_card = 'credit_card',
}

export enum TransactionStatusEnum {
  pending = 'pending',
  processed = 'processed',
}

export enum TransactionSourceEnum {
  payment_gateway = 'payment_gateway',
  internal = 'internal',
}

export interface TransactionProps {
  uuid: string
  reference: string
  payer: ProfileProps
  payerUuid?: string
  order: SaleOrderFinanceProps
  orderUuid?: string
  value: string
  currency: keyof typeof TransactionCurrencyEnum
  paymentSource: string
  paymentMethod: keyof typeof TransactionPaymentMethodEnum
  paidAt: string
  status: keyof typeof TransactionStatusEnum
  statusHistory: Record<string, string>
  proof: string
  gatewayId: string
  gateway: string
  createdAt: string
  updatedAt: string
  destinationBank: string
  dollarValue: string
  dollarExchangeRate: string
}

export interface TransactionWithPagesProps {
  count: number
  next?: string
  previous?: string
  results?: TransactionProps[]
}
