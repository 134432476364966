import type { ProgressBarProps } from './progress-bar.type'

import { Text } from '../text'

import styles from './progress-bar.module.css'

export const ProgressBar = ({
  progress,
  label,
  positionLabel = 'top',
  className,
}: ProgressBarProps) => {
  return (
    <div className={className || ''}>
      {positionLabel === 'top' && (
        <Text variant="text-small" className={styles['label-top']}>
          {label}
        </Text>
      )}

      <div className={styles.wrap}>
        <div className={styles.background} />
        <div
          className={styles.progress}
          style={{ width: `${progress}%` }}
        ></div>
      </div>

      {positionLabel === 'bottom' && (
        <Text variant="text-small" className={styles['label-bottom']}>
          {label}
        </Text>
      )}
    </div>
  )
}
