/* eslint-disable @typescript-eslint/no-explicit-any */
import { convertObjectToCamelCase, convertObjectToSnakeCase } from '@/helpers'
import { api, apiNoAuth } from './api.service'
import { useMutation, useQuery } from '@tanstack/react-query'

import {
  SALE_BOARD_BEST_SELLERS_URL,
  SALE_BOARD_METRICS_URL,
  SALE_BOARD_NOTICATIONS_URL,
  WORDPRESS_POSTS_URL,
  SALE_BOARD_NOTIFICATION_URL,
  DOLLAR_EXCHANGE_RATE_URL,
} from './api.urls'

import type {
  NotificationProps,
  NotificationListWithPagesProps,
  DollarQuotationWithPagesProps,
  MetricsProps,
} from '@/types'

import { QUERIES_ADMIN } from './api.constants'

export const useMetrics = () => {
  const getMetrics = async (): Promise<{
    success: boolean
    message: string
    data?: MetricsProps
  }> => {
    try {
      const { data } = await api.get(SALE_BOARD_METRICS_URL, {})

      return {
        success: true,
        message: 'Sale board metrics fetched successfully!',
        data: convertObjectToCamelCase(data) as any,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get Sale board metrics!',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [QUERIES_ADMIN.SALE_BOARD.module, 'metrics'],
    queryFn: () => getMetrics(),
  })
}

export const useNotifications = (params?: Record<string, string | number>) => {
  const getNotifications = async (): Promise<{
    success: boolean
    message: string
    data?: NotificationListWithPagesProps
  }> => {
    try {
      const copyParams = { ...params }

      const offset = copyParams?.page
        ? (+(copyParams?.page || 0) - 1) * +copyParams.limit
        : 0

      delete copyParams.page

      const formattedParams = Object.keys(copyParams).reduce((acc, key) => {
        if (copyParams?.[key]) {
          return {
            ...acc,
            [key]: copyParams?.[key],
          }
        }

        return acc
      }, {})

      const { data } = await api.get(SALE_BOARD_NOTICATIONS_URL, {
        params: {
          ...(convertObjectToSnakeCase(formattedParams) as Record<
            string,
            string
          >),
          offset,
        },
      })

      return {
        success: true,
        message: 'Sale board notifications fetched successfully!',
        data: convertObjectToCamelCase(data) as NotificationListWithPagesProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get Sale board notifications!',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [QUERIES_ADMIN.SALE_BOARD.module, 'notifications', params],
    queryFn: () => getNotifications(),
  })
}

export const useCreateNotification = () => {
  const createNotifications = async (
    payload: NotificationProps,
  ): Promise<{
    success: boolean
    message: string
    data?: any
  }> => {
    try {
      const { data } = await api.post(
        SALE_BOARD_NOTICATIONS_URL,
        convertObjectToSnakeCase(payload),
      )

      return {
        success: true,
        message: 'Sale board notifications updated successfully!',
        data: convertObjectToCamelCase(data) as any,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to update Sale board notifications!',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.SALE_BOARD.module, 'notifications'],
    mutationFn: (payload: NotificationProps) => createNotifications(payload),
  })
}

export const useUpdateNotification = () => {
  const updateNotifications = async (
    payload: NotificationProps,
  ): Promise<{
    success: boolean
    message: string
    data?: any
  }> => {
    try {
      const { data } = await api.patch(
        SALE_BOARD_NOTIFICATION_URL(payload?.uuid),
        convertObjectToSnakeCase(payload),
      )

      return {
        success: true,
        message: 'Sale board notifications updated successfully!',
        data: convertObjectToCamelCase(data) as any,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to update Sale board notifications!',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.SALE_BOARD.module, 'notifications'],
    mutationFn: (payload: NotificationProps) => updateNotifications(payload),
  })
}

export const useBestSellers = (params?: Record<string, string | number>) => {
  const getBestSellers = async (): Promise<{
    success: boolean
    message: string
    data?: any
  }> => {
    try {
      const copyParams = { ...params }

      const offset = copyParams?.page
        ? (+(copyParams?.page || 0) - 1) * +copyParams.limit
        : 0

      delete copyParams.page

      const formattedParams = Object.keys(copyParams).reduce((acc, key) => {
        if (copyParams?.[key]) {
          return {
            ...acc,
            [key]: copyParams?.[key],
          }
        }

        return acc
      }, {})

      const { data } = await api.get(SALE_BOARD_BEST_SELLERS_URL, {
        params: {
          ...(convertObjectToSnakeCase(formattedParams) as Record<
            string,
            string
          >),
          offset,
        },
      })

      return {
        success: true,
        message: 'Sale board best-sellers fetched successfully!',
        data: convertObjectToCamelCase(data) as any,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get Sale board best-sellers!',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [QUERIES_ADMIN.SALE_BOARD.module, 'best-sellers', params],
    queryFn: () => getBestSellers(),
  })
}

export const useBlog = () => {
  const getBlog = async (): Promise<{
    success: boolean
    message: string
    data?: any
  }> => {
    try {
      const { data } = await apiNoAuth.get(WORDPRESS_POSTS_URL, {})

      return {
        success: true,
        message: 'Sale board blog fetched successfully!',
        data: convertObjectToCamelCase(data) as any,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get Sale board blog!',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [QUERIES_ADMIN.SALE_BOARD.module, 'blog'],
    queryFn: () => getBlog(),
  })
}

export const useDollarExchangeRate = (
  params?: Record<string, string | number>,
) => {
  const getDollarExchangeRate = async (): Promise<{
    success: boolean
    message: string
    data?: DollarQuotationWithPagesProps
  }> => {
    try {
      const copyParams = { ...params }

      const offset = copyParams?.page
        ? (+(copyParams?.page || 0) - 1) * +copyParams.limit
        : 0

      delete copyParams.page

      const formattedParams = Object.keys(copyParams).reduce((acc, key) => {
        if (copyParams?.[key]) {
          return {
            ...acc,
            [key]: copyParams?.[key],
          }
        }

        return acc
      }, {})

      const { data } = await api.get(DOLLAR_EXCHANGE_RATE_URL, {
        params: {
          ...(convertObjectToSnakeCase(formattedParams) as Record<
            string,
            string
          >),
          offset,
        },
      })

      return {
        success: true,
        message: 'Sale board dolar exchange rate fetched successfully!',
        data: convertObjectToCamelCase(data) as any,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get Sale board dolar exchange rate!',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [QUERIES_ADMIN.DOLLAR_QUOTATION.module, 'dollar-exchange-rate'],
    queryFn: () => getDollarExchangeRate(),
  })
}
