export const contents: Record<string, string[]> = {
  profile: [
    'order-status',
    'order-profile',
    'order-profile-status-history',
    'order-quotation',
    'order-summary-values',
  ],
  finance: [
    'order-status',
    'order-payments',
    'order-finance',
    'order-finance-documents',
    'order-profile',
    'order-items',
    'order-finance-status',
    'order-quotation',
    // 'order-summary-values',
  ],
  logistic: [
    'order-status',
    'order-logistic',
    'order-logistic-cost',
    'order-logistic-transport-option',
    'order-logistic-documents',
    'order-items',
    'order-finance',
    'order-profile',
    'order-logistic-status',
    'order-quotation',
    'order-summary-values',
  ],
  all: [
    'order-status',
    'order-logistic',
    'order-items',
    'order-finance',
    'order-profile',
    'order-profile-status-history',
    'order-finance-status',
    'order-logistic-status',
    'order-quotation',
    'order-summary-values',
  ],
  sale: [
    'order-status',
    'order-logistic',
    'order-items',
    'order-finance',
    'order-profile',
    'order-profile-status-history',
    'order-finance-status',
    'order-logistic-status',
    'order-quotation',
    'order-summary-values',
  ],
}
