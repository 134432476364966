export const activeTabsItems: Record<string, string[]> = {
  all: ['products'],
  products: ['products'],
  quotations: ['quotations'],
}

export const getTabsItems = (t: (key: string) => string) => [
  {
    key: 'all',
    title: t('supplierTabAll'),
  },
  {
    key: 'products',
    title: t('supplierTabProducts'),
  },
]
