import { useTranslation } from 'react-i18next'
import { Card } from '../card'
import styles from './blog.module.css'

import { BlogProps } from './blog.type'
import { Skeleton, Text } from '@mercai/clever'
import { formatDate } from '@/helpers'

export const Blog = ({ date, isLoading, title }: BlogProps) => {
  const { t } = useTranslation('componentsBlog')

  return (
    <div className={styles.wrapper}>
      <Text variant="title-medium">
        <strong>{t('title')}</strong>
      </Text>
      <Card className={styles.content}>
        {!isLoading ? (
          <>
            <Text className={styles.title}>{title}</Text>
            <Text className={styles['gray-400']}>{formatDate(date)}</Text>
          </>
        ) : (
          <>
            <Skeleton height="14px" width="110px" />
            <Skeleton height="14px" width="64px" />
          </>
        )}
      </Card>
    </div>
  )
}
