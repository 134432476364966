import {
  Button,
  Card,
  Checkbox,
  DownloadIcon,
  Skeleton,
  Tag,
  Text,
} from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import {
  downloadFile,
  formatCurrency,
  getFileNameAndExtension,
} from '@/helpers'

import { useFileSign } from '@/services'

import styles from './item-list-item.module.css'

import type { QuotationRequestItemListItemProps } from './item-list-item.type'
import { QuotationRequestItemCategoryEnum } from '@/types'

export const QuotationRequestItemListItem = ({
  product,
  targetPrice,
  catalogProduct,
  quantity,
  observation,
  purchaseFrequency,
  selected,
  onSelect,
  isSimilarAccepted,
}: QuotationRequestItemListItemProps) => {
  const { t } = useTranslation('screenQuotationRequestShow')
  const { t: tEnums } = useTranslation('enums')

  const { mutateAsync, isPending } = useFileSign()

  const handleDownloadFile = async () => {
    const response = await mutateAsync({
      filePath: product?.itemDataSheetPath || '',
    })

    if (!response?.data?.signedUrl) return

    const file = getFileNameAndExtension(product?.itemDataSheetPath || '')

    downloadFile(
      response?.data?.signedUrl,
      `${file.fileName}.${file.extension}`,
    )
  }

  const correctProduct: Record<string, string | number | undefined> = {
    title: '',
    category: '',
    density: undefined,
    fluidity: undefined,
    image: '',
  }

  if (catalogProduct) {
    correctProduct.title = catalogProduct.attributes?.title || ''
    correctProduct.category =
      catalogProduct?.category?.shortName?.toLowerCase() || ''
    correctProduct.density = catalogProduct.attributes?.density || undefined
    correctProduct.fluidity = catalogProduct.attributes?.fluidity || undefined
    correctProduct.image = catalogProduct.attributes?.mainImage || ''
  } else {
    correctProduct.title = product?.title || ''
    correctProduct.category = product?.category || ''
    correctProduct.density = product?.density || undefined
    correctProduct.fluidity = product?.fluidity || undefined
  }

  return (
    <Card className={styles.core}>
      {onSelect && (
        <Checkbox
          size="large"
          className={styles.checkbox}
          onChange={onSelect}
          value={selected || false}
        />
      )}

      <img
        src={
          (correctProduct?.image as string) ||
          '/images/product-placeholder.webp'
        }
        alt="placeholder"
        className={styles.img}
      />

      <div className={styles.content}>
        <Text variant="title-base">{correctProduct?.title}</Text>

        <div className={styles.row}>
          <Text>
            <strong>{t('quotationRequestItemCategoryLabel')}:</strong>{' '}
            {tEnums(
              `quotation_request_category_${
                String(
                  correctProduct?.category,
                ).toLowerCase() as keyof typeof QuotationRequestItemCategoryEnum
              }`,
            )}
          </Text>
          <Text className={styles.pipe}>|</Text>
          <Text>
            <strong>{t('quotationRequestItemDensityLabel')}:</strong>{' '}
            {correctProduct?.density || ' - '} g/cm³
          </Text>
          <Text className={styles.pipe}>|</Text>
          <Text>
            <strong>{t('quotationRequestItemFluidityLabel')}:</strong>{' '}
            {correctProduct?.fluidity || ' - '} g/10min
          </Text>
          <Text className={styles.pipe}>|</Text>
          <Text>
            <strong>{t('quotationRequestItemQuantityLabel')}:</strong>{' '}
            {quantity} Kg
          </Text>

          <Text className={styles.pipe}>|</Text>

          <Text>
            <strong>{t('quotationRequestItemFrequencyLabel')}:</strong>{' '}
            {purchaseFrequency
              ? tEnums(`quotation_request_frequency_${purchaseFrequency}`)
              : '-'}
          </Text>

          <Text className={styles.pipe}>|</Text>

          <Text>
            <strong>{t('quotationRequestItemTargetPriceLabel')}:</strong>{' '}
            {targetPrice
              ? `${formatCurrency(targetPrice, 'es-MX', 'USD')}`
              : '-'}
          </Text>

          <Text className={styles.pipe}>|</Text>

          <Text>
            <strong>{t('quotationRequestItemObservationLabel')}:</strong>{' '}
            {observation || '-'}
          </Text>

          {+(quantity || 0) < 1000 && (
            <Tag variant="basic" model="light">
              {t('quotationRequestItemSample')}
            </Tag>
          )}

          {isSimilarAccepted && (
            <Tag variant="primary" model="light">
              {t('quotationRequestItemIsSimilarAccepted')}
            </Tag>
          )}

          {product?.itemDataSheetPath && (
            <Button
              variant="secondary"
              size="small"
              onClick={handleDownloadFile}
            >
              <DownloadIcon />
              {isPending
                ? t('quotationRequestItemDownloadingDataSheetFile')
                : t('quotationRequestItemDownloadDataSheetFile')}
            </Button>
          )}
        </div>
      </div>
    </Card>
  )
}

export const QuotationRequestItemListItemLoading = () => {
  return (
    <Card className={styles.core}>
      <Skeleton className={styles.img} height="5rem" width="5rem" />
      <div className={styles.content}>
        <Skeleton className={styles.img} height="1.2rem" width="15rem" />
        <div className={styles.row}>
          <Skeleton className={styles.img} height="1rem" width="8rem" />
          <Skeleton className={styles.img} height="1rem" width="8rem" />
          <Skeleton className={styles.img} height="1rem" width="8rem" />
          <Skeleton className={styles.img} height="1rem" width="8rem" />
          <Skeleton className={styles.img} height="1rem" width="8rem" />
        </div>
      </div>
    </Card>
  )
}
