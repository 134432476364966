import { useEffect, useState } from 'react'
import { Drawer } from '@mercai/clever'
import { useQueryClient } from '@tanstack/react-query'

import { QUERIES_ADMIN } from '@/services'

import {
  FinanceProfileHeader,
  ProfileCreditForm,
  ProfileCreditShow,
} from './components'

import type { ProfileCreditProps } from './profile-credit.type'

export const ProfileCredit = ({
  isOpen,
  onHandleOpen,
  financeProfileData,
  buyerProfileEmail,
  buyerProfileUuid,
}: ProfileCreditProps) => {
  const queryClient = useQueryClient()

  const [isEdit, setIsEdit] = useState(false)

  const onSuccessUpdated = () => {
    queryClient.invalidateQueries({
      queryKey: [QUERIES_ADMIN.PROFILE.module, buyerProfileUuid],
    })
  }

  const getPendingForm = () => {
    if (isEdit) {
      return true
    }

    const documentsObject = {
      individual: {
        governmentIdFile: financeProfileData?.governmentIdFile,
        taxSituation: financeProfileData?.taxSituation,
        addressProofFile: financeProfileData?.addressProofFile,
      },
      legal: {
        governmentIdFile: financeProfileData?.governmentIdFile,
        taxSituation: financeProfileData?.taxSituation,
        addressProofFile: financeProfileData?.addressProofFile,
        constitutiveActFile: financeProfileData?.constitutiveActFile,
      },
    }

    if (!financeProfileData?.personType) {
      return false
    }

    const documents = documentsObject[financeProfileData?.personType]

    if (Object.values(documents).every(Boolean)) {
      return true
    }

    return false
  }

  const isPendingForm = getPendingForm()

  useEffect(() => {
    if (!isOpen) {
      setIsEdit(false)
    }
  }, [isOpen])

  return (
    <Drawer isOpen={isOpen} onHandleOpen={() => onHandleOpen(false)}>
      <FinanceProfileHeader onHandleOpen={onHandleOpen} />

      {isPendingForm ? (
        <ProfileCreditShow
          onHandleOpen={onHandleOpen}
          financeProfileData={financeProfileData}
          buyerProfileEmail={buyerProfileEmail}
          onHandleEdit={() => setIsEdit(!isEdit)}
        />
      ) : (
        <ProfileCreditForm
          onHandleOpen={onHandleOpen}
          financeProfileData={financeProfileData}
          onSuccessUpdated={onSuccessUpdated}
          onHandleShow={() => setIsEdit(!isEdit)}
          profileUuid={buyerProfileUuid}
        />
      )}
    </Drawer>
  )
}
