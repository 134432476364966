'use client'
import { Children, useState } from 'react'
import { ChevronDownIcon } from '../../../icons'

import {
  generateClassesColumn,
  generateClassesExpandableIcon,
  generateClassesExpandableRow,
} from './table.constants'

import styles from './table.module.css'

import type {
  TableRootProps,
  TableSectionProps,
  TableRowProps,
  TableExpandableRowProps,
  TableColumnProps,
} from './table.type'

const Root = ({ children, className }: TableRootProps) => {
  return (
    <table className={`${styles.core} ${className || ''}`}>{children}</table>
  )
}

const Section = ({ children, className, type }: TableSectionProps) => {
  switch (type) {
    case 'header':
      return (
        <thead
          className={`${styles['section-type-header']} ${className || ''}`}
        >
          {children}
        </thead>
      )
    case 'footer':
      return (
        <tfoot
          className={`${styles['section-type-footer']} ${className || ''}`}
        >
          {children}
        </tfoot>
      )
    case 'body':
    default:
      return (
        <tbody className={`${styles['section-type-body']} ${className || ''}`}>
          {children}
        </tbody>
      )
  }
}

const Row = ({ children, className }: TableRowProps) => {
  return (
    <tr
      className={`${className || ''} ${
        styles['section-type-body-standard-row']
      }`}
    >
      {children}
    </tr>
  )
}

const ExpandableRow = ({
  children,
  className,
  details,
  index,
}: TableExpandableRowProps) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const handleToggle = () => {
    setIsExpanded(!isExpanded)
  }

  const generatedColumnClasses = generateClassesColumn('right')

  const classGeneratedIcon = generateClassesExpandableIcon(
    isExpanded,
    className,
  )

  const generatedRowClassesMain = generateClassesExpandableRow(
    index,
    !isExpanded,
    className,
  )
  const generatedRowClassesDetails = generateClassesExpandableRow(
    index,
    isExpanded,
    className,
  )

  return (
    <>
      <tr className={generatedRowClassesMain}>
        {children}

        <td onClick={handleToggle}>
          <div className={generatedColumnClasses}>
            <ChevronDownIcon className={classGeneratedIcon} />
          </div>
        </td>
      </tr>
      {isExpanded && (
        <tr className={generatedRowClassesDetails}>
          <td
            colSpan={Children.count(children) + 1}
            className={styles['section-expandable-details']}
          >
            {details}
          </td>
        </tr>
      )}
    </>
  )
}

const Column = ({
  children,
  className,
  type = 'body',
  colSpan = 1,
  align = 'left',
}: TableColumnProps) => {
  const generatedColumnClasses = generateClassesColumn(align, className)

  if (type === 'header' || type === 'footer')
    return (
      <th colSpan={colSpan}>
        <div className={generatedColumnClasses}>{children}</div>
      </th>
    )

  return (
    <td colSpan={colSpan}>
      <div className={generatedColumnClasses}>{children}</div>
    </td>
  )
}

const Table = {
  Root,
  Section,
  ExpandableRow,
  Row,
  Column,
}

export default Table
