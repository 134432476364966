import {
  Input,
  InputPhone,
  Label,
  Tooltip,
  QuestionFillIcon,
  Radio,
  Text,
  Button,
  PlusIcon,
} from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import type { SupplierFormProps } from './supplier-form.type'

import styles from './supplier-form.module.css'
import { SupplierWarehouseProps } from '@/types'
import { deepCopy } from '@/helpers'

const defaultWarehouse: SupplierWarehouseProps = {
  complement: '',
  number: '',
  reference: '',
  address: {
    city: '',
    country: '',
    neighborhood: '',
    postalCode: '',
    state: '',
    street: '',
  },
}
// const defaultWarehouse: SupplierWarehouseProps = {
//   complement: '',
//   number: '10',
//   reference: '',
//   address: {
//     city: 'Cidade Teste',
//     country: 'Pais Teste',
//     neighborhood: 'Bairro Teste',
//     postalCode: '111',
//     state: 'Estado Teste',
//     street: 'Rua Teste',
//   },
// }

export const SupplierForm = ({
  onChangeValues,
  values,
  errors,
}: SupplierFormProps) => {
  const { t } = useTranslation('screenSupplierList')

  const onHandleValues = (key: string, value: string | number | boolean) => {
    onChangeValues({ ...values, [key]: value })
  }

  const onHandleWarehouseValues = (
    index: number,
    key: string,
    value: string | number,
    inAddress = true,
  ) => {
    const valuesAux = deepCopy(values)

    if (inAddress && valuesAux?.supplierWarehouse?.[index]?.address) {
      valuesAux.supplierWarehouse[index].address = {
        ...valuesAux.supplierWarehouse[index].address,
        [key]: value,
      }
    }

    if (!inAddress && valuesAux?.supplierWarehouse?.[index]) {
      valuesAux.supplierWarehouse[index] = {
        ...valuesAux.supplierWarehouse[index],
        [key]: value,
      }
    }

    onChangeValues({ ...valuesAux })
  }

  const onHandleAddWarehouse = () => {
    const valuesAux = deepCopy(values)

    if (valuesAux?.supplierWarehouse) {
      valuesAux.supplierWarehouse.push(defaultWarehouse)
    } else {
      valuesAux.supplierWarehouse = [defaultWarehouse]
    }

    onChangeValues({ ...valuesAux })
  }

  return (
    <div className={styles.core}>
      <div>
        <Label>{t('supplierFormProfileFormNameLabel')}</Label>
        <Input
          placeholder={t('supplierFormProfileFormNamePlaceholder')}
          value={values?.name}
          onChange={(newValue) => onHandleValues('name', newValue)}
          error={errors?.name as string}
        />
      </div>

      <div>
        <Label>
          {t('supplierFormProfileFormEmailLabel')}{' '}
          <span>{t('optionalLabel')}</span>
        </Label>
        <Input
          placeholder={t('supplierFormProfileFormEmailPlaceholder')}
          value={values?.email}
          onChange={(newValue) => onHandleValues('email', newValue)}
          error={errors?.email as string}
        />
      </div>

      <div>
        <Label>
          {t('supplierFormProfileFormPhoneLabel')}
          <span>{t('optionalLabel')}</span>
        </Label>
        <InputPhone
          placeholder={t('supplierFormProfileFormPhonePlaceholder')}
          value={values?.phone}
          onChange={(newValue) => onHandleValues('phone', newValue)}
          error={errors?.phone as string}
        />
      </div>

      <div>
        <div>
          <Label>
            {t('supplierFormProfileFormForeignLabel')}
            <Tooltip
              text={t('supplierFormProfileFormForeignDescription')}
              widthText="16rem"
            >
              <QuestionFillIcon />
            </Tooltip>
          </Label>
        </div>

        <Radio
          value={!!values?.isForeign}
          onChange={() => onHandleValues('isForeign', true)}
        >
          {t('supplierFormProfileFormForeignOptionsYes')}
        </Radio>

        <Radio
          value={!values?.isForeign}
          onChange={() => onHandleValues('isForeign', false)}
        >
          {t('supplierFormProfileFormForeignOptionsNo')}
        </Radio>
      </div>

      <div>
        {values?.supplierWarehouse?.map((supplierWarehouse, i) => (
          <div key={`supplier-warehouse-${i}`} className={styles.warehouse}>
            <Text
              variant="text-large-bold"
              className={styles['warehouse-title']}
            >
              {t('supplierFormProfileFormWarehouseTitle')}
              {Array.isArray(values?.supplierWarehouse) &&
                values?.supplierWarehouse.length > 1 &&
                ` ${i + 1}`}
            </Text>
            <div className={styles.row}>
              <div>
                <Label>{t('supplierFormProfileFormWarehouseNameLabel')}</Label>
                <Input
                  placeholder={t(
                    'supplierFormProfileFormWarehouseNamePlaceholder',
                  )}
                  value={supplierWarehouse?.name}
                  onChange={(newValue) =>
                    onHandleWarehouseValues(i, 'name', newValue, false)
                  }
                  error={errors?.[`supplierWarehouse[${i}]`]?.name}
                />
              </div>
            </div>
            <div className={styles.row}>
              <div>
                <Label>
                  {t('supplierFormProfileFormWarehouseZipCodeLabel')}
                </Label>
                <Input
                  placeholder={t(
                    'supplierFormProfileFormWarehouseZipCodePlaceholder',
                  )}
                  type="number"
                  value={supplierWarehouse?.address?.postalCode}
                  onChange={(newValue) =>
                    onHandleWarehouseValues(i, 'postalCode', newValue)
                  }
                  error={
                    errors?.[`supplierWarehouse[${i}]`]?.address?.postalCode
                  }
                />
              </div>
            </div>
            <div className={styles.row}>
              <div>
                <Label>
                  {t('supplierFormProfileFormWarehouseCountryLabel')}
                </Label>
                <Input
                  placeholder={t(
                    'supplierFormProfileFormWarehouseCountryPlaceholder',
                  )}
                  value={supplierWarehouse?.address?.country}
                  onChange={(newValue) =>
                    onHandleWarehouseValues(i, 'country', newValue)
                  }
                  error={errors?.[`supplierWarehouse[${i}]`]?.address?.country}
                />
              </div>
              <div>
                <Label>{t('supplierFormProfileFormWarehouseStateLabel')}</Label>
                <Input
                  placeholder={t(
                    'supplierFormProfileFormWarehouseStatePlaceholder',
                  )}
                  value={supplierWarehouse?.address?.state}
                  onChange={(newValue) =>
                    onHandleWarehouseValues(i, 'state', newValue)
                  }
                  error={errors?.[`supplierWarehouse[${i}]`]?.address?.state}
                />
              </div>
            </div>
            <div className={styles.row}>
              <div>
                <Label>{t('supplierFormProfileFormWarehouseCityLabel')}</Label>
                <Input
                  placeholder={t(
                    'supplierFormProfileFormWarehouseCityPlaceholder',
                  )}
                  value={supplierWarehouse?.address?.city}
                  onChange={(newValue) =>
                    onHandleWarehouseValues(i, 'city', newValue)
                  }
                  error={errors?.[`supplierWarehouse[${i}]`]?.address?.city}
                />
              </div>
              <div>
                <Label>
                  {t('supplierFormProfileFormWarehouseNeighborhoodLabel')}
                </Label>
                <Input
                  placeholder={t(
                    'supplierFormProfileFormWarehouseNeighborhoodPlaceholder',
                  )}
                  value={supplierWarehouse?.address?.neighborhood}
                  onChange={(newValue) =>
                    onHandleWarehouseValues(i, 'neighborhood', newValue)
                  }
                  error={
                    errors?.[`supplierWarehouse[${i}]`]?.address?.neighborhood
                  }
                />
              </div>
            </div>
            <div className={styles.row}>
              <div>
                <Label>
                  {t('supplierFormProfileFormWarehouseStreetLabel')}
                </Label>
                <Input
                  placeholder={t(
                    'supplierFormProfileFormWarehouseStreetPlaceholder',
                  )}
                  value={supplierWarehouse?.address?.street}
                  onChange={(newValue) =>
                    onHandleWarehouseValues(i, 'street', newValue)
                  }
                  error={errors?.[`supplierWarehouse[${i}]`]?.address?.street}
                />
              </div>
              <div>
                <Label>
                  {t('supplierFormProfileFormWarehouseNumberLabel')}
                </Label>
                <Input
                  placeholder={t(
                    'supplierFormProfileFormWarehouseNumberPlaceholder',
                  )}
                  value={supplierWarehouse?.number}
                  type="number"
                  onChange={(newValue) =>
                    onHandleWarehouseValues(i, 'number', newValue, false)
                  }
                  error={errors?.[`supplierWarehouse[${i}]`]?.number}
                />
              </div>
            </div>
            <div className={styles.row}>
              <div>
                <Label>
                  {t('supplierFormProfileFormWarehouseComplementLabel')}
                  <span>{t('optionalLabel')}</span>
                </Label>
                <Input
                  placeholder={t(
                    'supplierFormProfileFormWarehouseComplementPlaceholder',
                  )}
                  value={supplierWarehouse?.complement}
                  onChange={(newValue) =>
                    onHandleWarehouseValues(i, 'complement', newValue, false)
                  }
                  error={errors?.[`supplierWarehouse[${i}]`]?.complement}
                />
              </div>
            </div>
            <div className={styles.row}>
              <div>
                <Label>
                  {t('supplierFormProfileFormWarehouseReferenceLabel')}
                  <span>{t('optionalLabel')}</span>
                </Label>
                <Input
                  value={supplierWarehouse?.reference}
                  onChange={(newValue) =>
                    onHandleWarehouseValues(i, 'reference', newValue, false)
                  }
                  error={errors?.[`supplierWarehouse[${i}]`]?.reference}
                />
              </div>
            </div>
          </div>
        ))}

        <div className={styles.row}>
          <Button variant="secondary" onClick={onHandleAddWarehouse}>
            {t('supplierFormProfileFormWarehouseAddButton')}
            <PlusIcon />
          </Button>
        </div>
      </div>
    </div>
  )
}
