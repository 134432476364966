import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { Button, ChevronLeftIcon, Text } from '@mercai/clever'
import { useParamsState, trackEvent } from '@/helpers'
import { useTransportCompanyDetail } from '@/services'
import { ROUTES_ADMIN } from '@/router'

import { TransportCompanyDrawerForm } from '../components'

import { TransportCompanyRoutesTable } from './components'

import { getTabsItems } from './show.constants'

import styles from './show.module.css'

export const SupplyTransportCompaniesShow = () => {
  const { t } = useTranslation('screenTransportCompanies')

  const { uuid } = useParams()

  const [queryParams, setQueryParams] = useParamsState({
    uuid: '',
    show: '',
    form: '',
    tab: 'routes',
  })

  const { data } = useTransportCompanyDetail(uuid)

  const onHandleEditRegister = (uuid: string) => {
    setQueryParams({ ...queryParams, form: 'true', show: '', uuid })
  }

  const tabsComponents: Record<string, React.ReactNode> = {
    routes: <TransportCompanyRoutesTable uuid={uuid || ''} />,
  }

  useEffect(() => {
    trackEvent('adminTransportCompaniesShowPageView')
  }, [])

  return (
    <div>
      <section className={styles['header-section']}>
        <div className={styles['header-left-sidebar']}>
          <a href={`/${ROUTES_ADMIN.SUPPLY.TRANSPORT_COMPANIES.path}`}>
            <ChevronLeftIcon />
          </a>
          <div>
            <Text>
              <strong>{`${t('title')} / `}</strong>
              <Text>{data?.data?.name}</Text>
            </Text>
            <div className={styles.title}>
              <Text variant="title-large">{data?.data?.name}</Text>
            </div>
          </div>
        </div>
        <Button onClick={() => onHandleEditRegister(uuid || '')}>
          {t('transportCompaniesTableUpdateRegister')}
        </Button>
      </section>

      <div className={styles.content}>
        <section className={styles['tab-filter']}>
          {getTabsItems(t).map((tabItem) => (
            <Button
              model="pill"
              variant={
                queryParams.tab === tabItem.key ? 'primary' : 'secondary'
              }
              key={tabItem.key}
              onClick={() =>
                setQueryParams({ ...queryParams, tab: tabItem.key })
              }
            >
              {tabItem.title}
            </Button>
          ))}
        </section>
        <section>{tabsComponents?.[queryParams?.tab || 'routes']}</section>
      </div>

      <TransportCompanyDrawerForm
        isOpen={!!queryParams.form}
        onHandleOpen={() =>
          setQueryParams({ ...queryParams, form: '', uuid: '' })
        }
        transportCompany={data?.data}
      />
    </div>
  )
}
