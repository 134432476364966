import { useCallback, useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Checkbox,
  DropDown,
  TableRows,
  SettingIcon,
  TableRowsColumnProps,
  InputSearch,
  Select,
  Text,
  Tooltip,
  QuestionFillIcon,
  RefreshIcon,
  FilterIcon,
} from '@mercai/clever'
import { startOfMonth, endOfMonth, format } from 'date-fns'

import { Header } from '@/components'

import {
  useParamsState,
  useCookieState,
  trackEvent,
  formatDate,
  formatCurrency,
  formatNumber,
  isSuperUser,
} from '@/helpers'
import { useOrderTrackerList, useRegenerateOrderTracker } from '@/services'

import { useAuth } from '@/providers'

import {
  calculateCogs,
  calculateGrossProfit,
  calculateFinancingCost,
  calculateGMVPercentage,
  calculateCM1,
  calculateCM1Percentage,
  calculateCM2,
  calculateCM2Percentage,
  calculateCM3,
  calculateCM3Percentage,
  calculateCOGSByCategory,
  calculateGMVByCategory,
} from './order-tracker.formulas'

import {
  OrderTableOrderId,
  OrderTablePODate,
  OrderTableColumnStatus,
  OrderTableCustomerName,
  OrderTableProductDescription,
  OrderTableProductIndicators,
  MonthlyFilter,
  SaleOrderTableColumnStatus,
  OrderTableCreditOrder,
  OrderTableColumnCreditOrderStatus,
  OrderTableColumnInternalCreditDaysBetweenDates,
  OrderTableColumnInvoicesStatus,
  OrderTableColumnShipmentsStatus,
  OrderTableColumnPaymentsStatus,
} from './components'
import styles from './order-tracker.module.css'

import type { OrderTrackerProps, OrderTrackerItemsDataProps } from '@/types'
import { ROUTES_ADMIN } from '@/router'

export const AnalyticsOrderTracker = () => {
  const { t } = useTranslation('screenOrderTracker')

  const { whoAmI } = useAuth()

  const [orderReferenceItemsSelected, setOrderReferenceItemsSelected] =
    useState<Record<string, string>>({})

  const [columnKeys, setColumnsKeys] = useCookieState<string[]>(
    '@mercai/order-tracker-table-rows',
    [
      'orderInformation',
      'orderId',
      'kam',
      'emailKam',
      'saleOrderStatus',
      'orderShipmentsStatus',
      'orderInvoicesStatus',
      'orderPaymentsStatus',
      'orderDate',
      'poId',
      'customerInformation',
      'customerName',
      'customerUuid',
      'customerCohort',
      'customerAccountType',
      'customerAccountFraud',
      'customerStatus',
      'cashFlowInformation',
      'invoiceId',
      'invoiceDate',
      'cashCollectionDate',
      'orderDeliveryDate',
      'orderExpectedDeliveryDate',
      'orderMonth',
      'orderQuarter',
      'orderCreationDate',
      'orderProductDescription',
      'orderTotalTraderCommission',
      'orderTotalProductPrice',
      'orderDollarExchangeRate',
      'orderDomesticOrImport',
      'orderSupplierName',
      'orderSupplyPaymentDate',
      'orderPerformanceIndicators',
      'orderTransportPrice',
      'orderIva',
      'orderGmv',
      'orderGmvMxn',
      'orderCogs',
      'orderGrossProfitCogs',
      'gmPercentage',
      'cm1',
      'orderCustomsOperations',
      'orderDistributionFirstMile',
      'cm1Percentage',
      'cm2',
      'warehouse',
      'orderDistributionLastMile',
      'cm2Percentage',
      'cm3',
      'orderFinancingCosts',
      'orderReferralCommission',
      'cm3Percentage',
      'polymers',
      'engineering',
      'pe',
      'pp',
      'steel',
      'mildSteel',
      'stainlessSteel',
      'polymersCogs',
      'engineeringCogs',
      'peCogs',
      'ppCogs',
      'steelCogs',
      'mildSteelCogs',
      'stainlessSteelCogs',
      'internalCredit',
      'internalCreditPaymentTerm',
      'internalCreditInterest',
      'internalCreditOrderAmount',
      'internalCreditTotalCreditInUse',
      'internalCreditTotalCreditAvailable',
      'internalCreditStatus',
      'internalCreditExpectedPaymentDate',
      'internalCreditRealPaymentDate',
      'internalCreditDifferencePaymentDate',
      'internalCreditProfile',
    ],
  )

  const [filter, setFilter] = useParamsState({
    page: '1',
    limit: '500',
    search: '',
    status: '',
    dateAtAfter: format(startOfMonth(new Date()), "yyyy-MM-dd'T'HH:mm:ss'Z'"),
    dateAtBefore: format(endOfMonth(new Date()), "yyyy-MM-dd'T'HH:mm:ss'Z'"),
    dateType: 'createdAt',
  })

  const getMonthFilter = () => {
    const date = filter.dateAtBefore ? new Date(filter.dateAtBefore) : undefined

    return date
  }

  const onSelectMonthFilter = (date: Date) => {
    const selectedDate = new Date(date)
    const startOfMonthDate = startOfMonth(selectedDate)
    const endOfMonthDate = endOfMonth(selectedDate)

    const startOfMonthISO = format(startOfMonthDate, "yyyy-MM-dd'T'HH:mm:ss'Z'")
    const endOfMonthISO = format(endOfMonthDate, "yyyy-MM-dd'T'HH:mm:ss'Z'")

    setFilter({
      ...filter,
      dateAtAfter: startOfMonthISO,
      dateAtBefore: endOfMonthISO,
    })
  }

  const onHandleRowsShow = (key: string) => {
    let newRowsKeys = columnKeys

    if (columnKeys.includes(key)) {
      newRowsKeys = columnKeys.filter((item) => item !== key)
    } else {
      newRowsKeys = [...columnKeys, key]
    }

    setColumnsKeys(newRowsKeys)
  }

  const getColumnsShow = useCallback(
    (key: string) => columnKeys.includes(key),
    [columnKeys],
  )

  const handleSearchFilter = (newValue: string) => {
    if (newValue) {
      setFilter({
        search: newValue,
        limit: '500',
        offset: '0',
        dateAtAfter: '',
        dateAtBefore: '',
        dateType: 'createdAt',
      })

      return
    }

    const selectedDate = new Date()
    const startOfMonthDate = startOfMonth(selectedDate)
    const endOfMonthDate = endOfMonth(selectedDate)

    const startOfMonthISO = format(startOfMonthDate, "yyyy-MM-dd'T'HH:mm:ss'Z'")
    const endOfMonthISO = format(endOfMonthDate, "yyyy-MM-dd'T'HH:mm:ss'Z'")

    setFilter({
      limit: '500',
      offset: '0',
      search: '',
      dateAtAfter: startOfMonthISO,
      dateAtBefore: endOfMonthISO,
    })
  }

  const getParams = () => {
    const filters = {
      limit: filter.limit,
      offset: filter.offset,
      search: filter.search,
      [`${filter.dateType}After`]: filter.dateAtAfter,
      [`${filter.dateType}Before`]: filter.dateAtBefore,
    }

    return filters
  }

  const {
    data,
    isLoading,
    isFetching,
    refetch: onRefetch,
  } = useOrderTrackerList({ params: getParams() })

  const { mutateAsync: mutateAsyncRegenerate, isPending: isLoadingRegenerate } =
    useRegenerateOrderTracker({ params: getParams() })

  const onHandleRegenerate = async () => {
    const result = await mutateAsyncRegenerate()

    if (result.success) {
      onRefetch()
    }
  }

  const getSelectedProduct = (
    orderRefence: string,
    orderItems: OrderTrackerItemsDataProps[],
  ) => {
    if (orderReferenceItemsSelected[orderRefence]) {
      return orderItems.find(
        (item) =>
          orderReferenceItemsSelected[orderRefence] === item.saleOrderItemUuid,
      )
    }

    return orderItems?.[0]
  }

  useEffect(() => {
    trackEvent('adminOrderTrackerPageView')
  }, [])

  const getDateFormattedOrEmpty = (
    value: string | null | undefined,
    format: string = 'es-MX',
  ) => {
    if (!value || value === 'None') {
      return '-'
    }

    return formatDate(value, format)
  }

  const getValueFormattedOrEmpty = (
    value: number | null | undefined | string,
    currency: 'MXN' | 'USD' | '%' | 'KGs',
    showZero = false,
  ) => {
    let newValue = Number(value)

    if (isNaN(newValue) || !isFinite(newValue)) {
      newValue = 0
    }

    if (!showZero && newValue === 0) {
      return '-'
    }

    if (currency === '%') {
      return `${formatNumber(newValue, 'es-MX')}%`
    }

    if (currency === 'KGs') {
      return `${formatNumber(newValue, 'es-MX')} KGs`
    }

    return formatCurrency(newValue, 'es-MX', currency)
  }

  const columns: TableRowsColumnProps<OrderTrackerProps>[] = [
    {
      key: 'orderInformation',
      title: t('tableOrderInformationLabel'),
      isHighlight: true,
    },
    {
      key: 'orderId',
      title: t('tableOrderIdLabel'),
      dataKey: 'trackerId',
      render: ({ record }) => (
        <OrderTableOrderId
          onSuccessEdit={() => onRefetch()}
          orderId={record.trackerId}
          saleOrderUuid={record.orderData.saleOrderUuid}
          saleOrderStatus={record.orderData.saleOrderStatus}
        />
      ),
    },
    {
      key: 'kam',
      title: t('tableKamLabel'),
      dataKey: 'orderData.keyAccManager.name',
    },
    {
      key: 'emailKam',
      title: t('tableEmailKamLabel'),
      dataKey: 'orderData.keyAccManager.email',
    },
    {
      key: 'orderStatus',
      title: t('tableOrderStatusLabel'),
      dataKey: 'orderData.orderStatus',
      render: ({ value }) => <OrderTableColumnStatus value={value} />,
    },
    {
      key: 'saleOrderStatus',
      title: t('tableSaleOrderStatusLabel'),
      dataKey: 'orderData.orderStatus',
      render: ({ record }) => (
        <SaleOrderTableColumnStatus
          saleOrderStatus={record.orderData.saleOrderStatus}
          onSuccessEdit={() => onRefetch()}
          saleOrderUuid={record.orderData.saleOrderUuid}
        />
      ),
    },
    {
      key: 'orderShipmentsStatus',
      title: t('tableOrderShipmentsStatusLabel'),
      render: ({ record }) => (
        <OrderTableColumnShipmentsStatus
          value={record?.cashFlowData?.shipmentsStatus}
        />
      ),
    },
    {
      key: 'orderInvoicesStatus',
      title: t('tableOrderInvoicesStatusLabel'),
      render: ({ record }) => (
        <OrderTableColumnInvoicesStatus
          value={record?.cashFlowData?.invoicesStatus}
        />
      ),
    },
    {
      key: 'orderPaymentsStatus',
      title: t('tableOrderPaymentsStatusLabel'),
      render: ({ record }) => (
        <OrderTableColumnPaymentsStatus
          value={record?.cashFlowData?.paymentsStatus}
        />
      ),
    },
    {
      key: 'orderDate',
      title: t('tableFolioFacturaLabel'),
      dataKey: 'cashFlowData.invoiceId',
    },
    {
      key: 'poId',
      title: t('tablePoIdLabel'),
      dataKey: 'orderData.orderReference',
    },
    {
      key: 'customerInformation',
      title: t('tableCustomerInformationLabel'),
      isHighlight: true,
    },
    {
      key: 'customerName',
      title: t('tableCustomerNameLabel'),
      dataKey: 'customerData.customerName',
      render: ({ value, record }) => (
        <OrderTableCustomerName
          profileName={value}
          onSuccessEdit={() => onRefetch()}
          saleOrderUuid={record.orderData.saleOrderUuid}
          profileUuid={record.customerData?.customerUuid}
          saleOrderStatus={record.orderData.saleOrderStatus}
        />
      ),
    },
    {
      key: 'customerUuid',
      title: t('tableCustomerCompanyNameLabel'),
      dataKey: 'customerData.customerCompanyName',
      render: ({ value }) => (value ? value.toLocaleUpperCase() : '-'),
    },
    {
      key: 'customerCohort',
      title: (
        <div className={styles['tooltip-wrap']}>
          {t('tableCohortLabel')}
          <Tooltip text={t('tableCohortDescription')} widthText="16rem">
            <QuestionFillIcon />
          </Tooltip>
        </div>
      ),
      dataKey: 'customerData.customerCohort',
    },
    {
      key: 'customerAccountType',
      title: t('tableAccountTypeLabel'),
      dataKey: 'customerData.customerAccountType',
    },
    {
      key: 'customerAccountFraud',
      title: t('tableFraudLabel'),
      dataKey: 'customerData.customerFraudStatus',
    },
    {
      key: 'customerStatus',
      title: t('tableCustomerStatusLabel'),
      dataKey: 'customerData.customerStatus',
    },
    {
      key: 'cashFlowInformation',
      title: t('tableCashFlowInformationLabel'),
      isHighlight: true,
    },
    {
      key: 'invoiceId',
      title: t('tablePoDateLabel'),
      dataKey: 'cashFlowData.orderCreationDate',
      render: ({ record }) => (
        <OrderTablePODate
          createdAt={record?.cashFlowData?.orderCreationDate || ''}
          onSuccessEdit={() => onRefetch()}
          saleOrderUuid={record.orderData.saleOrderUuid}
          saleOrderStatus={record.orderData.saleOrderStatus}
        />
      ),
    },
    {
      key: 'invoiceDate',
      title: t('tableInvoiceDateLabel'),
      dataKey: 'cashFlowData.invoiceCreationDate',
      render: ({ value }) => getDateFormattedOrEmpty(value),
    },
    {
      key: 'cashCollectionDate',
      title: t('tableCashCollectionDateLabel'),
      dataKey: 'cashFlowData.orderPaidAt',
      render: ({ value }) => getDateFormattedOrEmpty(value),
    },
    {
      key: 'orderDeliveryDate',
      title: t('tableRealDeliveryDateLabel'),
      dataKey: 'cashFlowData.orderDeliveryDate',
      render: ({ value }) => getDateFormattedOrEmpty(value),
    },
    {
      key: 'orderExpectedDeliveryDate',
      title: t('tableExpectedDeliveryDateLabel'),
      dataKey: 'cashFlowData.orderExpectedDeliveryDate',
      render: ({ value }) => getDateFormattedOrEmpty(value),
    },
    {
      key: 'orderMonth',
      title: t('tableOrderMonthCohortLabel'),
      dataKey: 'cashFlowData.orderMonth',
    },
    {
      key: 'orderQuarter',
      title: t('tableOrderQuarterCohortLabel'),
      dataKey: 'cashFlowData.orderQuarter',
    },
    {
      key: 'orderCreationDate',
      title: t('tableProductInformationLabel'),
      dataKey: 'itemsData',
      isHighlight: true,
      render: ({ record }) => (
        <OrderTableProductIndicators
          orderReference={record?.orderData?.orderReference}
          selectedItems={orderReferenceItemsSelected}
          items={record.itemsData}
          onSelectItems={(orderItemUuid) =>
            setOrderReferenceItemsSelected({
              ...orderReferenceItemsSelected,
              [record?.orderData?.orderReference]: orderItemUuid,
            })
          }
        />
      ),
    },
    {
      key: 'orderProductDescription',
      title: t('tableProductDescriptionLabel'),
      dataKey: 'itemsData',
      height: '5rem',
      render: ({ record }) => (
        <OrderTableProductDescription
          orderReference={record.orderData.orderReference}
          selectedItems={orderReferenceItemsSelected}
          items={record.itemsData}
          onSuccessEditItem={() => onRefetch()}
          saleOrderStatus={record.orderData.saleOrderStatus}
          saleOrderUuid={record.orderData.saleOrderUuid}
        />
      ),
    },
    {
      key: 'orderTotalTraderCommission',
      title: t('tableQuantityLabel'),
      dataKey: 'itemsData',
      render: ({ record }) =>
        getValueFormattedOrEmpty(
          getSelectedProduct(record.orderData.orderReference, record.itemsData)
            ?.productData?.productQuantity || 0,
          'KGs',
        ),
    },
    {
      key: 'orderTotalProductPrice',
      title: t('tableProductPriceLabel'),
      dataKey: 'itemsData',
      render: ({ record }) =>
        getValueFormattedOrEmpty(
          getSelectedProduct(record.orderData.orderReference, record.itemsData)
            ?.productData?.productPrice || 0,
          'USD',
        ),
    },
    {
      key: 'tableProductTotalPrice',
      title: t('tableProductTotalPriceLabel'),
      dataKey: 'itemsData',
      render: ({ record }) => {
        const selectedProduct = getSelectedProduct(
          record.orderData.orderReference,
          record.itemsData,
        )
        const productPrice = selectedProduct?.productData?.productPrice || 0
        const productQuantity =
          selectedProduct?.productData?.productQuantity || 0

        return getValueFormattedOrEmpty(+productPrice * productQuantity, 'USD')
      },
    },
    {
      key: 'orderTotalProductCost',
      title: t('tableProductCostLabel'),
      dataKey: 'itemsData',
      render: ({ record }) =>
        getValueFormattedOrEmpty(
          getSelectedProduct(record.orderData.orderReference, record.itemsData)
            ?.productData?.productCost || 0,
          'USD',
        ),
    },
    {
      key: 'tableProductTotalCost',
      title: t('tableProductTotalCostLabel'),
      dataKey: 'itemsData',
      render: ({ record }) => {
        const selectedProduct = getSelectedProduct(
          record.orderData.orderReference,
          record.itemsData,
        )
        const productCost = selectedProduct?.productData?.productCost || 0
        const productQuantity =
          selectedProduct?.productData?.productQuantity || 0

        return getValueFormattedOrEmpty(+productCost * productQuantity, 'USD')
      },
    },
    {
      key: 'orderDomesticOrImport',
      title: t('tableDomesticOrImportLabel'),
      dataKey: 'itemsData',
      render: ({ record }) =>
        getSelectedProduct(record.orderData.orderReference, record.itemsData)
          ?.supplierData?.supplierForeignStatus || '-',
    },
    {
      key: 'orderSupplierName',
      title: t('tableSupplierNameLabel'),
      dataKey: 'itemsData',
      render: ({ record }) =>
        getSelectedProduct(record.orderData.orderReference, record.itemsData)
          ?.supplierData?.productSupplierName || '-',
    },
    {
      key: 'orderSupplyPaymentDate',
      title: t('tableSupplyPaymentDateLabel'),
      dataKey: 'itemsData',
      render: ({ record }) =>
        getDateFormattedOrEmpty(
          getSelectedProduct(record.orderData.orderReference, record.itemsData)
            ?.supplierData?.supplierPaymentDate || '',
        ),
    },
    {
      key: 'orderPerformanceIndicators',
      title: t('tablePerformanceIndicatorsLabel'),
      dataKey: 'grossProfitData',
      isHighlight: true,
    },
    {
      key: 'orderDiscount',
      title: t('tableDiscountLabel'),
      dataKey: 'grossProfitData.orderDiscount',
      render: ({ value }) => getValueFormattedOrEmpty(value, 'USD'),
    },
    {
      key: 'orderTransportPrice',
      title: t('tableTransportPriceLabel'),
      dataKey: 'grossProfitData.orderFreightPrice',
      render: ({ value }) => getValueFormattedOrEmpty(value, 'USD', true),
    },
    {
      key: 'orderIva',
      title: t('tableIvaLabel'),
      dataKey: 'grossProfitData.orderIvaDue',
      render: ({ value }) => getValueFormattedOrEmpty(value, 'USD'),
    },
    {
      key: 'orderGmv',
      title: t('tableGmvLabel'),
      isBold: true,
      dataKey: 'grossProfitData.orderGmv',
      render: ({ value }) => getValueFormattedOrEmpty(value, 'USD'),
    },
    {
      key: 'orderCogs',
      title: t('tableCogsLabel'),
      isBold: true,
      dataKey: 'grossProfitData.orderTotalProductCost',
      render: ({ record }) =>
        getValueFormattedOrEmpty(calculateCogs(record), 'USD'),
    },
    {
      key: 'orderGrossProfitCogs',
      title: t('tableGrossProfitLabel'),
      dataKey: 'grossProfitData',
      render: ({ record }) =>
        getValueFormattedOrEmpty(calculateGrossProfit(record), 'USD'),
    },
    {
      key: 'gmPercentage',
      title: t('tableGmPercentageLabel'),
      dataKey: 'grossProfitData',
      render: ({ record }) =>
        getValueFormattedOrEmpty(calculateGMVPercentage(record), '%'),
    },
    {
      key: 'orderCustomsOperations',
      title: t('tableCustomsOperationsLabel'),
      dataKey: 'grossProfitData.orderCustomsFreightCost',
      render: ({ value }) => getValueFormattedOrEmpty(value, 'USD'),
    },
    {
      key: 'orderDistributionFirstMile',
      title: t('tableDistributionFirstMileLabel'),
      dataKey: 'grossProfitData.orderFirstMileFreightCost',
      render: ({ value }) => getValueFormattedOrEmpty(value, 'USD'),
    },
    {
      key: 'cm1',
      title: t('tableCm1Label'),
      dataKey: 'grossProfitData',
      isBold: true,
      render: ({ record }) =>
        getValueFormattedOrEmpty(calculateCM1(record), 'USD'),
    },
    {
      key: 'cm1Percentage',
      title: t('tableCm1PercentageLabel'),
      dataKey: 'grossProfitData',
      render: ({ record }) =>
        getValueFormattedOrEmpty(calculateCM1Percentage(record), '%'),
    },

    {
      key: 'warehouse',
      title: t('tableWarehouseLabel'),
      dataKey: 'grossProfitData.orderWarehouseFreightCost', // TODO: Check if this is correct
    },
    {
      key: 'orderDistributionLastMile',
      title: t('tableDistributionLastMileLabel'),
      dataKey: 'grossProfitData.orderLastMileFreightCost',
      render: ({ value }) => getValueFormattedOrEmpty(value, 'USD'),
    },
    {
      key: 'cm2',
      title: t('tableCm2Label'),
      isBold: true,
      dataKey: 'grossProfitData',
      render: ({ record }) =>
        getValueFormattedOrEmpty(calculateCM2(record), 'USD'),
    },
    {
      key: 'cm2Percentage',
      title: t('tableCm2PercentageLabel'),
      dataKey: 'grossProfitData',
      render: ({ record }) =>
        getValueFormattedOrEmpty(calculateCM2Percentage(record), '%'),
    },
    {
      key: 'orderFinancingCosts',
      title: t('tableFinancingCostsLabel'),
      dataKey: 'grossProfitData.orderFinancingCost',
      render: ({ record }) =>
        getValueFormattedOrEmpty(calculateFinancingCost(record), 'USD'),
    },
    {
      key: 'orderReferralCommission',
      title: t('tableReferralCommissionLabel'),
      dataKey: 'grossProfitData.orderTotalTraderCommission',
      render: ({ value }) => getValueFormattedOrEmpty(value, 'USD'),
    },
    {
      key: 'cm3',
      title: t('tableCm3Label'),
      isBold: true,
      dataKey: 'grossProfitData',
      render: ({ record }) =>
        getValueFormattedOrEmpty(calculateCM3(record), 'USD'),
    },
    {
      key: 'cm3Percentage',
      title: t('tableCm3PercentageLabel'),
      dataKey: 'grossProfitData',
      render: ({ record }) =>
        getValueFormattedOrEmpty(calculateCM3Percentage(record), '%'),
    },
    {
      key: 'polymers',
      title: t('tablePolymersLabel'),
      dataKey: 'itemsData.productData.productUuid',
      isHighlight: true,
      render: ({ record }) =>
        getValueFormattedOrEmpty(
          calculateGMVByCategory(record, 'polymers'),
          'USD',
        ),
    },
    {
      key: 'engineering',
      title: t('tableEngineeringLabel'),
      dataKey: 'itemsData',
      render: ({ record }) =>
        getValueFormattedOrEmpty(
          calculateGMVByCategory(record, 'polymers-engineering'),
          'USD',
        ),
    },
    {
      key: 'pe',
      title: t('tablePeLabel'),
      dataKey: 'itemsData',
      render: ({ record }) =>
        getValueFormattedOrEmpty(
          calculateGMVByCategory(record, 'polymers-pe'),
          'USD',
        ),
    },
    {
      key: 'pp',
      title: t('tablePpLabel'),
      dataKey: 'itemsData',
      render: ({ record }) =>
        getValueFormattedOrEmpty(
          calculateGMVByCategory(record, 'polymers-pp'),
          'USD',
        ),
    },
    {
      key: 'steel',
      title: t('tableSteelLabel'),
      dataKey: 'itemsData.productData.productUuid',
      isHighlight: true,
      render: ({ record }) =>
        getValueFormattedOrEmpty(
          calculateGMVByCategory(record, 'steel'),
          'USD',
        ),
    },
    {
      key: 'mildSteel',
      title: t('tableMildSteelLabel'),
      dataKey: 'itemsData',
      render: ({ record }) =>
        getValueFormattedOrEmpty(
          calculateGMVByCategory(record, 'mild-steel'),
          'USD',
        ),
    },
    {
      key: 'stainlessSteel',
      title: t('tableStainlessSteelLabel'),
      dataKey: 'itemsData',
      render: ({ record }) =>
        getValueFormattedOrEmpty(
          calculateGMVByCategory(record, 'stainless-steel'),
          'USD',
        ),
    },
    {
      key: 'polymersCogs',
      title: t('tablePolymersCogsLabel'),
      dataKey: 'itemsData.productData.productUuid',
      isHighlight: true,
      render: ({ record }) =>
        getValueFormattedOrEmpty(
          calculateCOGSByCategory(record, 'polymers'),
          'USD',
        ),
    },
    {
      key: 'engineeringCogs',
      title: t('tableEngineeringCogsLabel'),
      dataKey: 'itemsData',
      render: ({ record }) =>
        getValueFormattedOrEmpty(
          calculateCOGSByCategory(record, 'polymers-engineering'),
          'USD',
        ),
    },
    {
      key: 'peCogs',
      title: t('tablePeCogsLabel'),
      dataKey: 'itemsData',
      render: ({ record }) =>
        getValueFormattedOrEmpty(
          calculateCOGSByCategory(record, 'polymers-pe'),
          'USD',
        ),
    },
    {
      key: 'ppCogs',
      title: t('tablePpCogsLabel'),
      dataKey: 'itemsData',
      render: ({ record }) =>
        getValueFormattedOrEmpty(
          calculateCOGSByCategory(record, 'polymers-pp'),
          'USD',
        ),
    },
    {
      key: 'steelCogs',
      title: t('tableSteelCogsLabel'),
      dataKey: 'itemsData.productData.productUuid',
      isHighlight: true,
      render: ({ record }) =>
        getValueFormattedOrEmpty(
          calculateCOGSByCategory(record, 'steel'),
          'USD',
        ),
    },
    {
      key: 'mildSteelCogs',
      title: t('tableMildSteelCogsLabel'),
      dataKey: 'itemsData',
      render: ({ record }) =>
        getValueFormattedOrEmpty(
          calculateCOGSByCategory(record, 'mild-steel'),
          'USD',
        ),
    },
    {
      key: 'stainlessSteelCogs',
      title: t('tableStainlessSteelCogsLabel'),
      dataKey: 'itemsData',
      render: ({ record }) =>
        getValueFormattedOrEmpty(
          calculateCOGSByCategory(record, 'stainless-steel'),
          'USD',
        ),
    },
    {
      key: 'internalCredit',
      title: t('tableInternalCreditLabel'),
      isHighlight: true,
    },
    {
      key: 'internalCreditPaymentTerm',
      title: t('tableInternalCreditPaymentTermLabel'),
      render: ({ record }) => (
        <OrderTableCreditOrder
          orderTrackerData={record}
          onSuccessEdit={() => onRefetch()}
          saleOrderStatus={record.orderData.saleOrderStatus}
        />
      ),
    },
    {
      key: 'internalCreditInterest',
      title: t('tableInternalCreditInterestLabel'),
      render: ({ record }) =>
        record?.creditData?.interest
          ? `${+record?.creditData?.interest * 100}%`
          : '-',
    },
    {
      key: 'internalCreditOrderAmount',
      title: t('tableInternalCreditOrderAmountLabel'),
      render: ({ record }) =>
        getValueFormattedOrEmpty(record?.creditData?.creditOrderAmount, 'USD'),
    },
    {
      key: 'internalCreditTotalCreditInUse',
      title: t('tableInternalCreditTotalCreditInUseLabel'),
      render: ({ record }) =>
        getValueFormattedOrEmpty(
          record?.customerFinanceData?.totalCreditInUse,
          'USD',
        ),
    },
    {
      key: 'internalCreditTotalCreditAvailable',
      title: t('tableInternalCreditTotalCreditAvailableLabel'),
      render: ({ record }) =>
        getValueFormattedOrEmpty(
          record?.customerFinanceData?.totalCreditAmount,
          'USD',
        ),
    },
    {
      key: 'internalCreditStatus',
      title: t('tableInternalCreditStatusLabel'),
      render: ({ record }) => (
        <OrderTableColumnCreditOrderStatus
          value={record?.creditData?.creditOrderStatus}
        />
      ),
    },
    {
      key: 'internalCreditExpectedPaymentDate',
      title: t('tableInternalCreditExpectedPaymentDateLabel'),
      render: ({ record }) =>
        getDateFormattedOrEmpty(record?.creditData?.expectedPaymentDate),
    },
    {
      key: 'internalCreditRealPaymentDate',
      title: t('tableInternalCreditRealPaymentDateLabel'),
      render: ({ record }) =>
        getDateFormattedOrEmpty(record?.creditData?.realPaymentDate),
    },
    {
      key: 'internalCreditDifferencePaymentDate',
      title: t('tableInternalCreditDifferencePaymentDateLabel'),
      render: ({ record }) => (
        <OrderTableColumnInternalCreditDaysBetweenDates
          expectedPaymentDate={record?.creditData?.expectedPaymentDate}
          realPaymentDate={record?.creditData?.realPaymentDate}
        />
      ),
    },
    {
      key: 'internalCreditProfile',
      title: t('tableInternalCreditProfileLabel'),
      render: ({ record }) => (
        <Text
          className={styles.link}
          href={ROUTES_ADMIN.PROFILE.SHOW.fullPath(
            record?.customerData?.customerUuid || '',
          )}
          target="_blank"
          variant="link"
        >
          {t('tableInternalCreditProfileButtonLabel')}
        </Text>
      ),
    },
  ]

  const memoizedColumns = useMemo(
    () =>
      columns
        .filter((column) => getColumnsShow(column.key as string))
        .map((c) => c),
    [columns, getColumnsShow],
  )
  return (
    <div>
      <Header title={t('title')} description={t('description')} />

      <div className={styles.content}>
        <div>
          <div className={styles.filter}>
            <MonthlyFilter
              selectedMonth={getMonthFilter()}
              onSelectedMonth={onSelectMonthFilter}
            />

            <div className={styles['filter-group']}>
              <Select
                iconLeft={<FilterIcon />}
                options={[
                  {
                    label: t('searchFilterCreatedAtOption'),
                    value: 'createdAt',
                  },
                  {
                    label: t('searchFilterDeliveryAtOption'),
                    value: 'deliveredAt',
                  },
                ]}
                value={filter.dateType}
                onChange={(value) => setFilter({ ...filter, dateType: value })}
              />
              <InputSearch
                value={filter.search as string}
                onChange={handleSearchFilter}
                className={styles['search-input']}
                translations={{
                  inputPlaceholder: t('searchFilterPlaceholder'),
                }}
              />

              <DropDown.Root>
                <DropDown.ButtonTrigger
                  variant="secondary"
                  model="square"
                  hasArrow={false}
                >
                  <SettingIcon />
                </DropDown.ButtonTrigger>

                <DropDown.Content orientation="bottom-right">
                  {columns?.map((column) => (
                    <DropDown.Item key={column?.key as string}>
                      <Checkbox
                        onChange={() => onHandleRowsShow(column?.key as string)}
                        value={getColumnsShow(column?.key as string)}
                      >
                        {column.title}
                      </Checkbox>
                    </DropDown.Item>
                  ))}
                </DropDown.Content>
              </DropDown.Root>
              {isSuperUser(whoAmI?.email) && (
                <Button
                  variant="secondary"
                  model="square"
                  onClick={onHandleRegenerate}
                >
                  <RefreshIcon
                    className={
                      isLoadingRegenerate || isLoading || isFetching
                        ? styles.animation
                        : ''
                    }
                  />
                </Button>
              )}
            </div>
          </div>

          <div className={styles.table}>
            <TableRows
              columns={memoizedColumns}
              data={data?.data?.results || []}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
