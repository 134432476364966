import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Text, PlusIcon } from '@mercai/clever'

import { Card } from '../card'
import { Notification } from '../notification'
import { NotificationForm } from '../notification-form'

import styles from './last-notifications.module.css'

import { LastNotificationProps } from './last-notification.type'

export const LastNotifications = ({
  notifications,
  currentUserEmail,
  onLike,
  onClickNotification,
}: LastNotificationProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const { t } = useTranslation('saleBoardScreen')

  return (
    <Card className={styles.wrapper}>
      <div className={styles.header}>
        <Text variant="title-base" className={styles.title}>
          {t('lastNotifications')}
        </Text>

        <Button
          variant="secondary"
          size="small"
          onClick={() => setIsOpen(true)}
        >
          <PlusIcon />
        </Button>
      </div>

      {notifications?.map((notification) => (
        <Notification
          key={notification.uuid}
          {...notification}
          currentUserEmail={currentUserEmail}
          onLike={onLike}
          onClick={() => onClickNotification(notification)}
        />
      ))}

      <NotificationForm isOpen={isOpen} onHandleOpen={setIsOpen} />
    </Card>
  )
}
