import {
  OrderDetailItemProps,
  OrderListItemProps,
  SaleOrderListItemProps,
  SaleOrderItemProps,
} from '@/types'

export const calculateOrderValues = (
  items: OrderDetailItemProps[] | OrderListItemProps[] | SaleOrderItemProps[],
  totalFreight: number,
  discount: number,
  includeIva = true,
) => {
  const subTotal =
    items?.reduce((acc, product) => {
      const productPrice = product?.price ? +product?.price : 0
      const productQuantity = product?.quantity ? +product?.quantity : 0

      return acc + productPrice * productQuantity
    }, 0) || 0

  let iva = 0

  if (includeIva) {
    iva = (subTotal - discount) * 0.16 // add discount after
  }

  let total = subTotal - discount + iva + totalFreight
  total = total < 0 ? 0 : total

  return { discount, subTotal, iva, total }
}

export const getOrderItemDescription = (
  item:
    | OrderDetailItemProps
    | OrderListItemProps
    | SaleOrderListItemProps
    | SaleOrderItemProps,
) => {
  const { catalogProduct, nonCatalogProduct } = item

  if (catalogProduct) {
    return `${
      catalogProduct?.attributes?.title
        ? `${catalogProduct?.attributes?.title} - `
        : ''
    }${catalogProduct?.category?.name || ''}${
      catalogProduct?.attributes?.density
        ? `${catalogProduct?.attributes?.density} g/cm³ - `
        : ''
    }${
      catalogProduct?.attributes?.fluidity
        ? `${catalogProduct?.attributes?.fluidity} g/min`
        : ''
    }`
  }

  return nonCatalogProduct?.description || ''
}
