import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, CloseIcon, Drawer, Input, Label, Text } from '@mercai/clever'

import { trackEvent } from '@/helpers'
import { useCreateKaiSmartSearch } from '@/services'

import { DragDropFileList } from './components/drag-drop-file-list/drag-drop-file-list'

import { DrawerSmartSearchProps } from './drawer-smart-search.type'

import styles from './drawer-smart-search.module.css'

export const DrawerSmartSearch = ({
  isOpen,
  onHandleOpen,
  onHandleSubmit,
}: DrawerSmartSearchProps) => {
  const { t } = useTranslation('screenCatalogProduct')

  const [data, setData] = useState<{ rawInformation: string; file: File[] }>({
    rawInformation: '',
    file: [],
  })
  const [showError, setShowError] = useState(false)
  const { mutateAsync, isPending } = useCreateKaiSmartSearch()

  const isValid = useMemo(() => {
    if ((data?.file && data.file?.length > 0) || data.rawInformation) {
      return true
    }
    return false
  }, [data])

  const onHandleSubmitLocal = async () => {
    setShowError(true)
    if (!isValid) {
      return
    }

    const { data: smartSearchData } = await mutateAsync(data)

    trackEvent('adminProductsListSmartSearchPageView')
    onHandleSubmit(smartSearchData?.uuid || '')
  }

  return (
    <Drawer isOpen={isOpen} onHandleOpen={onHandleOpen}>
      <header className={styles.header}>
        <div className={styles['header-box-content']}>
          <Text className={styles['header-title']} variant="title-large">
            {t('drawerSmartSearchTitle')}
          </Text>
          <Text className={styles['header-subtitle']}>
            {t('drawerSmartSearchSubTitle')}
          </Text>
        </div>

        <button
          type="button"
          onClick={onHandleOpen}
          className={styles['header-button']}
        >
          <CloseIcon />
        </button>
      </header>
      <main className={styles.content}>
        <h1 className={styles['content-title']}>
          {t('drawerSmartSearchContentTitle')}
        </h1>
        <div>
          <Label>{t('drawerSmartSearchTextLabel')}</Label>
          <Input
            placeholder={t('drawerSmartSearchTextPlaceholder')}
            value={data.rawInformation}
            onChange={(rawInformation) =>
              setData((state) => ({ ...state, rawInformation }))
            }
            error={!isValid && showError ? ' ' : ''}
            isTextArea
          />
        </div>
        <div>
          <Label>{t('drawerSmartSearchArchivoLabel')}</Label>
          <DragDropFileList
            translations={{
              clickToUpload: t('drawerSmartSearchUploadClickToUpload'),
              dragFile: t('drawerSmartSearchUploadDragFile'),
              sizeFile: '',
            }}
            data={data.file}
            onDrop={(file) => setData((state) => ({ ...state, file }))}
            onRemove={(file) => setData((state) => ({ ...state, file }))}
            error={!isValid && showError ? ' ' : ''}
          />
          {!isValid && showError && (
            <span className={styles['error-message']}>
              {t('drawerSmartSearchFormValidation')}
            </span>
          )}
        </div>
      </main>

      <div className={styles.footer}>
        <Button
          variant="primary"
          onClick={onHandleSubmitLocal}
          disabled={isPending}
        >
          {t('drawerSmartSearchButtonSearch')}
        </Button>
      </div>
    </Drawer>
  )
}
