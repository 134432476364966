import { useState } from 'react'
import {
  Button,
  Select,
  CloseIcon,
  Modal,
  Input,
  Label,
  Text,
} from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import { useValidationErrors, onErrorMessage } from '@/helpers'
import { NotificationProps } from '@/types'
import { useCreateNotification } from '@/services'

import { schemaNotification } from './notification-form.constants'
import styles from './notification-form.module.css'
import type { DrawerNotificationProps } from './notification-form.type'

export const NotificationForm = ({
  isOpen,
  onHandleOpen,
  onSubmitSuccess,
}: DrawerNotificationProps) => {
  const { t } = useTranslation('saleBoardScreen')

  const [values, setValues] = useState<Partial<NotificationProps>>({})

  const { isValid, errors, onHandleListenErrors } = useValidationErrors(
    schemaNotification(t),
    values,
  )

  const { mutateAsync: createNotification } = useCreateNotification()

  const onHandleValues = (key: string, value?: string | number | boolean) => {
    setValues({ ...values, [key]: value })
  }

  const onHandleAddTag = () => {
    setValues({ ...values, tags: [...(values?.tags || []), ''] })
  }

  const onHandleRemoveTag = (index: number) => {
    setValues({
      ...values,
      tags: (values?.tags || []).filter((_, indexTag) => indexTag !== index),
    })
  }

  const onHandleChangeTag = (index: number, value: string) => {
    setValues({
      ...values,
      tags: (values?.tags || []).map((t, indexTag) =>
        indexTag === index ? value : t,
      ),
    })
  }

  const onHandleSubmit = async () => {
    if (!isValid) {
      onHandleListenErrors(true)

      return
    }

    onHandleListenErrors(false)

    const response = await createNotification(values as NotificationProps)

    if (response?.success) {
      onHandleOpen(false)
      onSubmitSuccess?.()
    } else {
      onErrorMessage(t('errorTitle'), t('errorMessage'))
    }
  }

  const getErrorsValues = (key: string) => {
    return errors?.[key] as string
  }

  return (
    <Modal.Root isOpen={isOpen} onHandleOpen={onHandleOpen} size="small">
      <Modal.Header title={t('notificationFormTitle')} />

      <Modal.Body className={styles.body}>
        <div>
          <Label>{t('notificationFormTitleLabel')}</Label>
          <Input
            value={values?.title}
            error={getErrorsValues('title')}
            onChange={(newValue) => onHandleValues('title', newValue)}
          />
        </div>

        <div>
          <Label>{t('notificationFormTypeLabel')}</Label>
          <Select
            options={[
              {
                label: t('notificationFormTypeNewFeature'),
                value: 'new_feature',
              },
              {
                label: t('notificationFormTypeProjectNotification'),
                value: 'project_notification',
              },
            ]}
            value={values?.type}
            error={getErrorsValues('type')}
            onChange={(newValue) => onHandleValues('type', newValue)}
          />
        </div>

        <div>
          <Label>{t('notificationFormContentLabel')}</Label>
          <Input
            value={values?.content}
            error={getErrorsValues('content')}
            onChange={(newValue) => onHandleValues('content', newValue)}
            isTextArea
          />
        </div>

        <div>
          <Label>{t('notificationFormTagsLabel')}</Label>
          <div className={styles['tags-container']}>
            {values?.tags?.map((tag, index) => (
              <div key={`tag-${index}`} className={styles.tag}>
                <Input
                  key={`tag-input-${index}`}
                  value={tag}
                  onChange={(newValue) => onHandleChangeTag(index, newValue)}
                />
                <Button
                  onClick={() => onHandleRemoveTag(index)}
                  model="square"
                  variant="danger"
                >
                  <CloseIcon />
                </Button>
              </div>
            ))}

            {getErrorsValues('tags') && (
              <Text className={styles['tags-error']}>
                {getErrorsValues('tags')}
              </Text>
            )}

            <Button
              onClick={onHandleAddTag}
              variant="secondary"
              className={styles['add-tag']}
            >
              {t('notificationFormAddTag')}
            </Button>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer className={styles.footer}>
        <Button variant="secondary" onClick={() => onHandleOpen(false)}>
          {t('buttonBack')}
        </Button>
        <Button onClick={onHandleSubmit}>{t('buttonSubmit')}</Button>
      </Modal.Footer>
    </Modal.Root>
  )
}
