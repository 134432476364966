import { useTranslation } from 'react-i18next'
import { DragDropFileList, Label, Text } from '@mercai/clever'

import { getFileNameAndExtension } from '@/helpers'
import { api, ORDER_CENTER_SHIPMENT_URL } from '@/services'

import type { LogisticFormDocumentsProps } from './documents.type'
import { LogisticShipmentProps, SaleOrderProps } from '@/types'

import styles from './documents.module.css'

export const LogisticFormDocuments = ({
  onChange,
  values = {} as SaleOrderProps,
}: LogisticFormDocumentsProps) => {
  const { t } = useTranslation('componentOrderCenterForm')

  const onChangeShipmentValues = (
    keyParam: keyof LogisticShipmentProps,
    value?: string | number,
  ) => {
    const shipment = { ...values?.shipments?.[0] }
    const updatedShipment = { ...shipment, [keyParam]: value }

    onChange({
      ...values,
      shipments: [updatedShipment, ...values?.shipments.slice(1)],
    })
  }

  const getFileValue = (keyParam: keyof LogisticShipmentProps) => {
    if (!values?.shipments?.[0]?.[keyParam]) {
      return []
    }

    const { fileName, extension } = getFileNameAndExtension(
      values?.shipments?.[0]?.[keyParam] as string,
    )

    return [
      {
        id: new Date().getTime(),
        name: `${fileName}.${extension}`,
        url: values?.shipments?.[0]?.[keyParam] as string,
        uploaded: true,
        preview: values?.shipments?.[0]?.[keyParam] as string,
      },
    ]
  }

  return (
    <div className={styles.content}>
      <Text variant="text-large-bold">{t('logisticDocumentsTitle')}</Text>

      <div className={styles.field}>
        <Label>{t('logisticDocumentsInvoiceLabel')}</Label>

        <DragDropFileList
          axiosInstance={api}
          urlAdd={ORDER_CENTER_SHIPMENT_URL(values?.shipments?.[0]?.uuid)}
          configsRequest={{
            fileField: 'shipment_invoice',
            method: 'PATCH',
          }}
          initialData={getFileValue('shipmentInvoice')}
          successedUpload={(value) =>
            onChangeShipmentValues(
              'shipmentInvoice',
              value?.shipment_invoice ? `${value?.shipment_invoice}` : '',
            )
          }
          translations={{
            clickToUpload: t('logisticDocumentsUploadButton'),
            dragFile: t('logisticDocumentsUploadDrag'),
            sizeFile: t('logisticDocumentsUploadSize'),
          }}
        />
      </div>

      <div className={styles.field}>
        <Label>{t('logisticDocumentsDeliveryListLabel')}</Label>

        <DragDropFileList
          axiosInstance={api}
          urlAdd={ORDER_CENTER_SHIPMENT_URL(values?.shipments?.[0]?.uuid)}
          configsRequest={{
            fileField: 'delivery_list',
            method: 'PATCH',
          }}
          initialData={getFileValue('deliveryList')}
          successedUpload={(value) =>
            onChangeShipmentValues(
              'deliveryList',
              value?.delivery_list ? `${value?.delivery_list}` : '',
            )
          }
          translations={{
            clickToUpload: t('logisticDocumentsUploadButton'),
            dragFile: t('logisticDocumentsUploadDrag'),
            sizeFile: t('logisticDocumentsUploadSize'),
          }}
        />
      </div>

      <div className={styles.field}>
        <Label>{t('logisticDocumentsCustomsCertificateLabel')}</Label>

        <DragDropFileList
          axiosInstance={api}
          urlAdd={ORDER_CENTER_SHIPMENT_URL(values?.shipments?.[0]?.uuid)}
          configsRequest={{
            fileField: 'customs_certificate',
            method: 'PATCH',
          }}
          initialData={getFileValue('customsCertificate')}
          successedUpload={(value) =>
            onChangeShipmentValues(
              'customsCertificate',
              value?.customs_certificate ? `${value?.customs_certificate}` : '',
            )
          }
          translations={{
            clickToUpload: t('logisticDocumentsUploadButton'),
            dragFile: t('logisticDocumentsUploadDrag'),
            sizeFile: t('logisticDocumentsUploadSize'),
          }}
        />
      </div>

      <div className={styles.field}>
        <Label>{t('logisticDocumentsOriginCertificateLabel')}</Label>

        <DragDropFileList
          axiosInstance={api}
          urlAdd={ORDER_CENTER_SHIPMENT_URL(values?.shipments?.[0]?.uuid)}
          configsRequest={{
            fileField: 'origin_certificate',
            method: 'PATCH',
          }}
          initialData={getFileValue('originCertificate')}
          successedUpload={(value) =>
            onChangeShipmentValues(
              'originCertificate',
              value?.origin_certificate ? `${value?.origin_certificate}` : '',
            )
          }
          translations={{
            clickToUpload: t('logisticDocumentsUploadButton'),
            dragFile: t('logisticDocumentsUploadDrag'),
            sizeFile: t('logisticDocumentsUploadSize'),
          }}
        />
      </div>

      <div className={styles.field}>
        <Label>{t('logisticDocumentsCustomsInvoiceLabel')}</Label>

        <DragDropFileList
          axiosInstance={api}
          urlAdd={ORDER_CENTER_SHIPMENT_URL(values?.shipments?.[0]?.uuid)}
          configsRequest={{
            fileField: 'shipment_receipt',
            method: 'PATCH',
          }}
          initialData={getFileValue('shipmentReceipt')}
          successedUpload={(value) =>
            onChangeShipmentValues(
              'shipmentReceipt',
              value?.shipment_receipt ? `${value?.shipment_receipt}` : '',
            )
          }
          translations={{
            clickToUpload: t('logisticDocumentsUploadButton'),
            dragFile: t('logisticDocumentsUploadDrag'),
            sizeFile: t('logisticDocumentsUploadSize'),
          }}
        />
      </div>
    </div>
  )
}
