import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, Tag, Text, ArrowRightIcon } from '@mercai/clever'

import { formatLongDate, formatLongTime, formatCurrency } from '@/helpers'

import { ROUTES_ADMIN } from '@/router'

import type {
  TableColumnPayerProps,
  TableColumnCreatedAtProps,
  TableColumnPaymentStatusProps,
  TableColumnActionsProps,
  TableColumnPricesProps,
  TableColumnPaymentMethodProps,
} from './table-columns.type'

import styles from './table-columns.module.css'

export const TableColumnPayer = ({
  buyerName,
  companyName,
  uuid,
}: TableColumnPayerProps) => {
  const { t } = useTranslation('screenOrderCenterPage')

  const getInitials = () => {
    const name = companyName || buyerName

    const splittedName = name.split(' ')

    let initials = ''

    if (splittedName?.[0]) {
      initials = `${splittedName[0][0]}`
    }

    if (splittedName?.[splittedName?.length - 1]) {
      initials = `${initials}${splittedName[splittedName?.length - 1][0]}`
    }

    return initials
  }

  if (!uuid) {
    return (
      <div className={styles.buyer}>
        <Text variant="title-small" className={styles['initials-inactive']}>
          NS
        </Text>
        <div className={styles.content}>
          <Text variant="title-base">
            {t('tableTransactionPayerNotSelected')}
          </Text>

          <Text variant="text-small">
            {t('tableTransactionPayerNotSelected')}
          </Text>
        </div>
      </div>
    )
  }

  return (
    <Link
      target="_blank"
      to={uuid ? ROUTES_ADMIN.PROFILE.SHOW.fullPath(uuid) : ''}
      className={styles.buyer}
    >
      <Text variant="title-small" className={styles.initials}>
        {getInitials()}
      </Text>
      <div className={styles.content}>
        <Text variant="title-base">{companyName || '-'}</Text>

        {buyerName && <Text variant="text-small">{buyerName || '-'}</Text>}
      </div>
    </Link>
  )
}

export const TableColumnPaidAt = ({ paidAt }: TableColumnCreatedAtProps) => {
  return (
    <div className={styles.paid}>
      <Text variant="title-base">{formatLongDate(paidAt)}</Text>
      <Text variant="text-small">{formatLongTime(paidAt)}</Text>
    </div>
  )
}

export const TableColumnPaymentMethod = ({
  paymentMethod,
  destinationBank,
}: TableColumnPaymentMethodProps) => {
  const { t: tEnums } = useTranslation('enums')

  return (
    <div className={styles.paid}>
      <Text variant="title-base">
        {tEnums(`transaction_payment_method_${paymentMethod}`)}
      </Text>
      <Text variant="text-small">{destinationBank}</Text>
    </div>
  )
}

export const TableColumnPaymentStatus = ({
  saleOrderReference,
  status,
}: TableColumnPaymentStatusProps) => {
  const { t } = useTranslation('screenOrderCenterPage')

  if (status === 'processed') {
    return (
      <Link
        to={`${ROUTES_ADMIN.ORDER_CENTER.FINANCE.fullPath()}?search=${saleOrderReference}`}
        target="_blank"
      >
        <Tag variant="success" model="light">
          #{saleOrderReference}
        </Tag>
      </Link>
    )
  }

  return (
    <Tag variant="danger" model="light">
      {t('tableTransactionStatePending')}
    </Tag>
  )
}

export const TableColumnPrices = ({
  currency,
  dollarExchangeRate,
  dollarValue,
  value,
}: TableColumnPricesProps) => {
  const mxnValue = +(currency === 'MXN' ? value : value * dollarExchangeRate)

  return (
    <div className={styles.prices}>
      <Text variant="title-base">
        {formatCurrency(dollarValue, 'es-MX', 'USD')}
      </Text>
      <Text variant="text-small">
        {formatCurrency(mxnValue, 'es-MX', 'MXN')}
      </Text>
    </div>
  )
}

export const TableColumnActions = ({ onClick }: TableColumnActionsProps) => {
  return (
    <div className={styles.actions}>
      <Button onClick={onClick} model="square">
        <ArrowRightIcon />
      </Button>
    </div>
  )
}
