import { Link } from 'react-router-dom'
import { ChevronLeftIcon, Card, Text, Skeleton } from '@mercai/clever'

import { HeaderProps } from './header.type'

import styles from './header.module.css'

const HeaderDefault = ({
  description,
  link,
  title,
  className,
}: HeaderProps) => {
  return (
    <Card className={`${styles.core} ${className}`}>
      {link && (
        <Link to={link}>
          <ChevronLeftIcon />
        </Link>
      )}

      <div className={styles.content}>
        <Text variant="title-large" className={styles.title}>
          {title}
        </Text>

        <Text className={styles.description}>{description}</Text>
      </div>
    </Card>
  )
}

const HeaderLoading = () => {
  return (
    <Card className={styles.core}>
      <div className={styles.content}>
        <Skeleton width="6.25rem" height="1.8rem" />
        <Skeleton width="12.5rem" height="1rem" />
      </div>
    </Card>
  )
}

export const Header = (props: HeaderProps) => {
  if (props.isLoading) {
    return <HeaderLoading />
  }

  return <HeaderDefault {...props} />
}
