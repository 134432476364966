import { Card, Text, Skeleton, InfoIcon } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import type { InternalInformationProps } from './internal-information.type'

import styles from './internal-information.module.css'

export const InternalInformation = ({
  refusedBy,
  refusedReason,
  isLoading,
}: InternalInformationProps) => {
  const { t } = useTranslation('screenQuotationRequestShow')

  if (isLoading) {
    return (
      <Card>
        <div className={styles.title}>
          <Skeleton height="1.5rem" width="1.5rem" />

          <Skeleton height="1.5rem" width="10rem" />
        </div>

        <div className={styles.content}>
          <div className={styles['content-row']}>
            <Skeleton height="1rem" width="8rem" />
          </div>

          <div className={styles['content-row']}>
            <Skeleton height="1rem" width="8rem" />
            <Skeleton height="1rem" width="8rem" />
          </div>

          <div className={styles['content-row']}>
            <Skeleton height="1rem" width="8rem" />
            <Skeleton height="1rem" width="8rem" />
          </div>

          <div className={styles['content-row']}>
            <Skeleton height="1rem" width="8rem" />
            <Skeleton height="1rem" width="8rem" />
          </div>
        </div>
      </Card>
    )
  }

  return (
    <Card>
      <div className={styles.title}>
        <InfoIcon />
        <Text variant="title-large">{t('internalInformationTitle')}</Text>
      </div>

      <div className={styles.content}>
        <div className={styles['content-row']}>
          <Text variant="title-medium">
            {t('internalInformationRefusedSubtitle')}
          </Text>
        </div>
        <div className={styles['content-row']}>
          <Text variant="title-base">
            {t('internalInformationRefusedByNameLabel')}:{' '}
          </Text>
          <Text>{refusedBy?.name || '-'}</Text>
        </div>
        <div className={styles['content-row']}>
          <Text variant="title-base">
            {t('internalInformationRefusedByEmailLabel')}:{' '}
          </Text>
          <Text>{refusedBy?.email || '-'}</Text>
        </div>

        <div className={styles['content-row']}>
          <Text variant="title-base">
            {t('internalInformationRefusedReasonLabel')}:{' '}
          </Text>
          <Text>{refusedReason || '-'}</Text>
        </div>
      </div>
    </Card>
  )
}
