import { CatalogProductProps } from './catalog-product.type'
import { SupplierWarehouseProps } from './supplier.type'

export enum SupplierProductPackageEnum {
  '25kg_bags' = '25kg_bags',
  'super_sacks' = 'super_sacks',
  'in_bulk' = 'in_bulk',
  'rolls' = 'rolls',
  'gaylord_boxes' = 'gaylord_boxes',
  '25kg_boxes' = '25kg_boxes',
}

export interface SupplierProductProps {
  uuid?: string
  supplierWarehouseUuid?: string
  supplierWarehouse?: SupplierWarehouseProps
  productUuid?: string
  catalogProduct?: CatalogProductProps
  quantity?: number
  minimumPurchaseQuantity?: number
  price?: number
  package?: keyof typeof SupplierProductPackageEnum
  reference?: string
  createdAt?: string
  updatedAt?: string
}

export interface SupplierProductListWithPagesProps {
  count: number
  next?: string
  previous?: string
  results?: SupplierProductProps[]
}
