import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { ProfileIcon, Tag, Button } from '@mercai/clever'

import { InfoList } from '@/components'
import { ROUTES_ADMIN } from '@/router'

import type { OrderProfileProps } from './order-profile.type'

import styles from './order-profile.module.css'

import { ProfileTaxStatusEnum } from '@/types'

export const OrderProfile = ({
  isLoading,
  companyName,
  customerLevel,
  email,
  usersNames,
  moscowClassification,
  phone,
  taxStatus,
  uuid,
}: OrderProfileProps) => {
  const { t } = useTranslation('componentOrderCenterShow')
  const { t: tEnums } = useTranslation('enums')

  const statusObject: Record<ProfileTaxStatusEnum, ReactNode> = {
    completed: (
      <Tag model="light" variant="success">
        {tEnums('profile_tax_status_completed')}
      </Tag>
    ),
    review: (
      <Tag model="light" variant="danger">
        {tEnums('profile_tax_status_review')}
      </Tag>
    ),
    pending: (
      <Tag model="light" variant="warning">
        {tEnums('profile_tax_status_pending')}
      </Tag>
    ),
    invalid: (
      <Tag model="light" variant="danger">
        {tEnums('profile_tax_status_invalid')}
      </Tag>
    ),
  }

  return (
    <InfoList
      isLoading={isLoading}
      iconTitle={<ProfileIcon />}
      title={t('orderProfileTitle')}
      actions={statusObject[taxStatus || 'pending']}
      list={[
        {
          label: t('orderProfileLevel'),
          value: customerLevel || '-',
        },
        {
          label: t('orderProfileMoscow'),
          value: tEnums(`profile_moscow_${moscowClassification || 'unknown'}`),
        },
        {
          label: t('orderProfileName'),
          value: usersNames ? usersNames?.join(', ') : '-',
        },
        {
          label: t('orderProfileCompanyName'),
          value: companyName || '-',
        },
        {
          label: t('orderProfileEmail'),
          value: email || '-',
        },
        {
          label: t('orderProfilePhone'),
          value: phone || '-',
        },
        {
          value: '',
          label: (
            <Button
              size="small"
              variant="primary-outline"
              href={ROUTES_ADMIN.PROFILE.SHOW.fullPath(uuid || '')}
              target="_blank"
              className={styles.link}
            >
              {t('orderProfileLink')}
            </Button>
          ),
        },
      ]}
    />
  )
}
