import { useCallback, useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Checkbox,
  DropDown,
  TableColumns,
  Text,
  Select,
  Skeleton,
  Pagination,
  SettingIcon,
  TableColumnsColumnProps,
  PlusIcon,
  InputSearch,
  EditIcon,
  ArrowRightIcon,
  Tag,
} from '@mercai/clever'

import { DEFAULT_LIMIT_PAGE } from '@/constants'
import { Header } from '@/components'
import {
  formatDate,
  useParamsState,
  useCookieState,
  trackEvent,
} from '@/helpers'
import { useSupplierList } from '@/services'
import { SupplierCategoriesEnum, SupplierProps } from '@/types'

import { SupplierDrawerForm } from '../components'

import styles from './list.module.css'

export const SupplySupplierList = () => {
  const { t } = useTranslation('screenSupplierList')

  const [totalPages, setTotalPages] = useState(1)

  const [columnsKeys, setColumnsKeys] = useCookieState<string[]>(
    '@mercai/supply-list-columns',
    ['name', 'phone', 'categories', 'email', 'createdAt'],
  )

  const [filter, setFilter] = useParamsState({
    page: '1',
    limit: `${DEFAULT_LIMIT_PAGE}`,
    search: '',
    status: '',
    createdAtBefore: '',
    createdAtAfter: '',
  })

  const [queryParams, setQueryParams] = useParamsState({
    uuid: '',
    show: '',
    form: '',
  })

  const onHandleColumnsShow = (key: string) => {
    let newColumnsKeys = columnsKeys

    if (columnsKeys.includes(key)) {
      newColumnsKeys = columnsKeys.filter((item) => item !== key)
    } else {
      newColumnsKeys = [...columnsKeys, key]
    }

    setColumnsKeys(newColumnsKeys)
  }

  const getColumnsShow = useCallback(
    (key: string) => columnsKeys.includes(key),
    [columnsKeys],
  )

  const handleFilter = (changedKey: string, newValue: string) => {
    setFilter({ ...filter, [changedKey]: newValue })
  }

  const { data, isLoading } = useSupplierList({ params: filter })

  useEffect(() => {
    if (data?.data?.count) {
      setTotalPages(Math.ceil(data?.data?.count / +filter.limit))
    }
  }, [data])

  useEffect(() => {
    trackEvent('adminTransportSupplierListPageView')
  }, [])

  const onHandleNewRegister = () => {
    setQueryParams({ ...queryParams, show: '', form: 'true', uuid: '' })
  }

  const onHandleEditRegister = (uuid: string) => {
    setQueryParams({ ...queryParams, form: 'true', show: '', uuid })
  }

  const supplierCategoriesObject = {
    metais: (
      <Tag variant="basic" model="outline" key="metais">
        {t('supplierTableCategoriesMetais')}
      </Tag>
    ),
    resinas_plasticas: (
      <Tag variant="primary" model="outline" key="resinas_plasticas">
        {t('supplierTableCategoriesResinasPlasticas')}
      </Tag>
    ),
  }

  const SupplierCategoriesColumn = ({
    categories,
  }: {
    categories?: (keyof typeof SupplierCategoriesEnum)[]
  }) => (
    <div className={styles['categories-column']}>
      {categories
        ? categories?.map((category) => supplierCategoriesObject?.[category])
        : '-'}
    </div>
  )

  const columns: TableColumnsColumnProps<SupplierProps>[] = [
    {
      title: t('supplierTableName'),
      key: 'name',
      dataKey: 'name',
      width: '22rem',
    },
    {
      title: t('supplierTablePhone'),
      key: 'phone',
      dataKey: 'phone',
      width: '12rem',
    },
    {
      title: t('supplierTableCategories'),
      key: 'categories',
      dataKey: 'categories',
      width: '12rem',
      render: ({ record }) => (
        <SupplierCategoriesColumn categories={record?.categories} />
      ),
    },
    {
      title: t('supplierTableEmail'),
      key: 'email',
      dataKey: 'email',
      width: '21rem',
    },
    {
      title: t('supplierTableForeign'),
      key: 'isForeign',
      dataKey: 'isForeign',
      width: '10rem',
      render: ({ value }) =>
        value ? t('supplierTableForeignYes') : t('supplierTableForeignNo'),
    },
    {
      title: t('supplierTableCreatedAt'),
      key: 'createdAt',
      dataKey: 'createdAt',
      render: ({ value }) => (value ? formatDate(value) : '-'),
    },
  ]

  const memoizedColumns = useMemo(
    () => columns.filter((column) => getColumnsShow(column.key)).map((c) => c),
    [columns, getColumnsShow],
  )

  return (
    <div>
      <Header title={t('title')} description={t('description')} />

      <div className={styles.content}>
        <div>
          <div className={styles.filter}>
            <div className={styles['filter-group']}>
              <InputSearch
                value={filter.search as string}
                onChange={(value) => handleFilter('search', value)}
                className={styles['search-input']}
                translations={{
                  inputPlaceholder: t('searchFilterPlaceholder'),
                }}
              />
            </div>

            <div className={styles['filter-group']}>
              <Button onClick={onHandleNewRegister}>
                <PlusIcon />
                {t('supplierTableNewRegister')}
              </Button>

              <DropDown.Root>
                <DropDown.ButtonTrigger
                  variant="secondary"
                  model="square"
                  hasArrow={false}
                >
                  <SettingIcon />
                </DropDown.ButtonTrigger>

                <DropDown.Content orientation="bottom-right">
                  {columns?.map((column) => (
                    <DropDown.Item key={column.dataKey}>
                      <Checkbox
                        onChange={() => onHandleColumnsShow(column.key)}
                        value={getColumnsShow(column.key)}
                      >
                        {column.title}
                      </Checkbox>
                    </DropDown.Item>
                  ))}
                </DropDown.Content>
              </DropDown.Root>
            </div>
          </div>

          <TableColumns<SupplierProps>
            isLoading={isLoading}
            columns={memoizedColumns}
            fixedColumns={[
              {
                title: t('supplierTableActions'),
                key: 'uuid',
                dataKey: 'uuid',
                width: '8rem',
                render: ({ record }) => (
                  <div className={styles['action-buttons']}>
                    <Button
                      variant="secondary"
                      onClick={() => onHandleEditRegister(record.uuid || '')}
                      model="square"
                    >
                      <EditIcon />
                    </Button>
                    <Button variant="primary" href={record.uuid} model="square">
                      <ArrowRightIcon />
                    </Button>
                  </div>
                ),
                renderLoading: (
                  <div className={styles['action-buttons']}>
                    <Skeleton height="2.8rem" width="2.8rem" />
                    <Skeleton height="2.8rem" width="2.8rem" />
                  </div>
                ),
              },
            ]}
            data={data?.data?.results || []}
          />

          <div className={styles.pagination}>
            <div className={styles['pagination-show-registers']}>
              <Text>
                {t('supplierTableShowRegisters', {
                  showNumber:
                    (data?.data?.count || 0) < +filter.limit
                      ? data?.data?.count
                      : +filter.limit,
                  totalNumber: data?.data?.count || 0,
                })}
              </Text>

              <Select
                value={filter.limit}
                onChange={(value) => handleFilter('limit', value)}
                options={[
                  { label: '10', value: '10' },
                  { label: '20', value: '20' },
                  { label: '50', value: '50' },
                  { label: '100', value: '100' },
                ]}
              />
            </div>

            <Pagination
              totalPages={totalPages}
              onChange={(page) => handleFilter('page', `${page}`)}
              currentPage={+filter.page as number}
            />
          </div>
        </div>
      </div>

      <SupplierDrawerForm
        isOpen={!!queryParams.form}
        onHandleOpen={() =>
          setQueryParams({ ...queryParams, form: '', uuid: '' })
        }
        supplierUuid={queryParams.uuid}
      />
    </div>
  )
}
