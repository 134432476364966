import { ReactNode } from 'react'
import { Button, CloseIcon, Drawer, Text } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import {
  DrawerFilterCreatedAtIntervalCard,
  DrawerFilterInvoiceStatusCard,
  DrawerFilterKamCard,
  DrawerFilterMoscowCard,
  DrawerFilterPaymentStatusCard,
  DrawerFilterTaxStatusCard,
  DrawerFilterLogisticStatusCard,
  DrawerFilterSalesOrderStatusCard,
  DrawerFilterRootCategoryCard,
  DrawerFilterDeliveryOptionCard,
} from './components'

import { cardsForType } from './drawer-filter.constants'

import styles from './drawer-filter.module.css'

import { DrawerFilterProps } from './drawer-filter.type'

export const OrderDrawerFilter = ({
  isOpen,
  onHandleOpen,
  filter,
  onHandleFilter,
  type,
}: DrawerFilterProps) => {
  const { t } = useTranslation('screenOrderCenterPage')

  const commonProps = {
    filter,
    onChangeFilter: onHandleFilter,
  }

  const cardsComponents: Record<string, ReactNode> = {
    tax_status: <DrawerFilterTaxStatusCard key="tax_status" {...commonProps} />,
    key_account_manager: (
      <DrawerFilterKamCard key="key_account_manager" {...commonProps} />
    ),
    moscow: <DrawerFilterMoscowCard key="moscow" {...commonProps} />,
    created_at_interval: (
      <DrawerFilterCreatedAtIntervalCard
        key="created_at_interval"
        {...commonProps}
      />
    ),
    invoice_status: (
      <DrawerFilterInvoiceStatusCard key="invoice_status" {...commonProps} />
    ),
    payment_status: (
      <DrawerFilterPaymentStatusCard key="payment_status" {...commonProps} />
    ),
    shipment_status: (
      <DrawerFilterLogisticStatusCard key="shipment_status" {...commonProps} />
    ),
    sales_order_status: (
      <DrawerFilterSalesOrderStatusCard
        key="sales_order_status"
        {...commonProps}
      />
    ),
    root_category: (
      <DrawerFilterRootCategoryCard key="root_category" {...commonProps} />
    ),
    delivery_option: (
      <DrawerFilterDeliveryOptionCard key="delivery_option" {...commonProps} />
    ),
  }

  const cards = cardsForType?.[type]

  return (
    <Drawer isOpen={isOpen} onHandleOpen={onHandleOpen}>
      <div className={styles.header}>
        <Text variant="title-large" className={styles['header-title']}>
          {t('drawerFilterTaxStatusTitle')}
        </Text>

        <button
          className={styles['header-button']}
          type="button"
          onClick={onHandleOpen}
        >
          <CloseIcon />
        </button>
      </div>

      <div className={styles.content}>
        {cards?.map((card) => cardsComponents?.[card])}
      </div>

      <div className={styles.footer}>
        <Button variant="secondary" onClick={onHandleOpen}>
          {t('drawerFilterTaxStatusButtonBack')}
        </Button>
      </div>
    </Drawer>
  )
}
