import {
  Button,
  InputNumber,
  Label,
  PlusIcon,
  Select,
  Text,
  TrashIcon,
} from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import type {
  VehiclePricesFormFooterProps,
  VehiclePricesFormProps,
} from './transport-company-routes-form.type'

import styles from './transport-company-routes-form.module.css'
import { groupedBylogisticRouteUuid } from './transport-company-routes-form.constants'
import { TransportCompanyRouteResponseProps } from '@/types'
import { deepCopy } from '@/helpers'

const Root = ({
  onChangeValues,
  values,
  validation,
  vehicles = [],
}: VehiclePricesFormProps) => {
  const { t } = useTranslation('screenTransportCompanies')

  const { errors } = validation

  const groupedValues = groupedBylogisticRouteUuid(
    deepCopy(values?.transportCompanyRoutes),
  )

  const onHandleValues = (
    index: number,
    key: string,
    value: string | number,
  ) => {
    const valuesAux = deepCopy(values)

    if (valuesAux?.transportCompanyRoutes?.[index]) {
      valuesAux.transportCompanyRoutes[index] = {
        ...valuesAux.transportCompanyRoutes[index],
        [key]: value,
      }
    }

    onChangeValues({ ...valuesAux })
  }

  const onHandleDelete = (index: number, key: string) => {
    if (groupedValues && groupedValues?.[key]?.length <= 1) return null

    const valuesAux = deepCopy(values)

    if (valuesAux?.transportCompanyRoutes?.[index]) {
      valuesAux.transportCompanyRoutes.splice(index, 1)
    }

    onChangeValues({ ...valuesAux })
  }

  const onHandleAdd = (uuid: string) => {
    const value: Partial<TransportCompanyRouteResponseProps> = {
      logisticRouteUuid: uuid,
    }

    const valuesAux = deepCopy(values)

    if (valuesAux?.transportCompanyRoutes) {
      valuesAux.transportCompanyRoutes.push(
        value as TransportCompanyRouteResponseProps,
      )
    }

    onChangeValues({ ...valuesAux })
  }

  return (
    <div className={styles.core}>
      {Object.keys(groupedValues || {})?.map((key, j) => (
        <div key={`routes-${j}`} className={styles.route}>
          <Text variant="text-large-bold">
            {groupedValues?.[key]?.[0]?.logisticRoute?.name}
          </Text>
          {groupedValues?.[key]?.map((vehiclePrice, i) => (
            <div key={`route-item-${i}`}>
              <div className={styles['route-item']}>
                <div>
                  <Label>{t('vehiclePricesFormUnitLabel')}</Label>
                  <Select
                    onChange={(value) =>
                      onHandleValues(vehiclePrice.index, 'vehicleUuid', value)
                    }
                    value={vehiclePrice?.vehicleUuid}
                    placeholder={t('vehiclePricesFormUnitLabel')}
                    options={vehicles.map((vehicle) => ({
                      label: vehicle.name,
                      value: vehicle.uuid,
                    }))}
                    error={
                      errors[`routes[${vehiclePrice.index}]`] &&
                      !vehiclePrice?.vehicleUuid
                        ? t('requiredMessage')
                        : undefined
                    }
                  />
                </div>
                <div>
                  <Label>{t('vehiclePricesFormPriceLabel')}</Label>
                  <div className={styles['number-and-add-box']}>
                    <InputNumber
                      placeholder="MXN$ 0.00"
                      decimalPrecision={2}
                      prefix={'MXN$ '}
                      stepSize={1}
                      showIcons
                      onChange={(value) =>
                        onHandleValues(vehiclePrice.index, 'price', value)
                      }
                      value={vehiclePrice.price}
                      error={
                        errors[`routes[${vehiclePrice.index}]`] &&
                        !vehiclePrice?.price
                          ? t('requiredMessage')
                          : undefined
                      }
                    />
                    <Button
                      variant="secondary"
                      model="square"
                      className={styles['route-item-button']}
                      disabled={
                        !!(groupedValues && groupedValues?.[key]?.length <= 1)
                      }
                      onClick={() => onHandleDelete(vehiclePrice.index, key)}
                    >
                      <TrashIcon />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <Button
            onClick={() => onHandleAdd(key)}
            size="small"
            variant="ghost"
            className={styles['add-button']}
          >
            {t('vehiclePricesFormAddLabel')}
            <PlusIcon />
          </Button>
        </div>
      ))}
    </div>
  )
}

const Footer = ({
  onNavigateToPreviousStep,
  onSubmit,
  validation,
}: VehiclePricesFormFooterProps) => {
  const { t } = useTranslation('screenTransportCompanies')

  const { isValid, onHandleListenErrors } = validation

  const onHandleSubmit = () => {
    if (!isValid) {
      onHandleListenErrors(true)
      return
    }

    onHandleListenErrors(false)
    onSubmit()
  }

  return (
    <>
      <Button variant="secondary" onClick={() => onNavigateToPreviousStep()}>
        {t('drawerFormReturnButton')}
      </Button>
      <Button onClick={onHandleSubmit}>{t('drawerFormSubmitButton')}</Button>
    </>
  )
}

export const TransportCompanyRoutesForm = {
  Root,
  Footer,
}
