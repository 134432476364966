import { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'

import {
  BestSellers,
  Blog,
  DollarExchangeRate,
  FeedbackCarousel,
  Goal,
  LastNotifications,
  Metrics,
  KaiMessage,
  NotificationPopup,
  Reacts,
  ReactElementProps,
} from './components'

import { convertObjectToCamelCase, formatNumber } from '@/helpers'

import {
  useBlog,
  useNotifications,
  useUpdateNotification,
  useBestSellers,
  useMetrics,
  QUERIES_ADMIN,
  useDollarExchangeRate,
} from '@/services'

import { useAuth, useWebsocket } from '@/providers'

import {
  targetGMV,
  targetThisWeekGMV,
  targetGM,
  targetCM3,
  feedbacks,
} from './sale-board.constants'

import styles from './sale-board.module.css'

import { NotificationProps } from '@/types'

export const SaleBoard = () => {
  const { whoAmI } = useAuth()

  const { registerOnMessage, sendMessage } = useWebsocket()

  const [isOpen, setIsOpen] = useState(false)

  const queryClient = useQueryClient()

  const [reacts, setReacts] = useState<ReactElementProps[]>([])
  const [notification, setNotification] = useState<
    NotificationProps | undefined
  >(undefined)

  const handleAddReact = (author: string) => {
    const audio = new Audio('/sounds/like.mp3')
    audio.play()

    for (let i = 0; i < 10; i++) {
      setTimeout(() => {
        const id = Math.random().toString(36).slice(2, 11)
        const randomX = Math.random() * window.innerWidth

        setReacts((prev) => [...prev, { id, x: randomX, author }])

        setTimeout(() => {
          setReacts((prev) => prev.filter((el) => el.id !== id))
        }, 5000)
      }, i * 200)
    }
  }

  registerOnMessage((message) => {
    const objectMessage = convertObjectToCamelCase(
      message?.message,
    ) as NotificationProps

    if (message?.type === 'react') {
      handleAddReact(objectMessage?.author)

      queryClient.refetchQueries({
        queryKey: [QUERIES_ADMIN.SALE_BOARD.module, 'notifications'],
      })
    }

    if (message?.type === 'notify') {
      setNotification(objectMessage)
      setIsOpen(true)

      queryClient.refetchQueries({
        queryKey: [QUERIES_ADMIN.SALE_BOARD.module, 'notifications'],
      })
    }
  })

  const onClickNotification = (notification: NotificationProps) => {
    setNotification(notification)
    setIsOpen(true)
  }

  const { data: metricsData, isLoading: metricsIsLoading } = useMetrics()
  const { data: bestSellersData, isLoading: bestSellersIsLoading } =
    useBestSellers({})
  const { data: blogData, isLoading: blogIsLoading } = useBlog()
  const {
    data: dollarExchangeRateData,
    isLoading: dollarExchangeRateIsLoading,
  } = useDollarExchangeRate({ limit: 30 })

  const { data: dataNotifications } = useNotifications()

  const { data: dataCommentNotifications } = useNotifications({
    limit: 1,
    type: 'ai_comment',
  })

  const { mutateAsync: updateNotifications } = useUpdateNotification()

  const handleLike = async (uuid: string) => {
    const notificationFound = dataNotifications?.data?.results?.find(
      (notification) => notification.uuid === uuid,
    )

    if (!notificationFound) return

    const currentLikedList = notificationFound.metadata?.liked || []
    const verifyNotification = currentLikedList.includes(whoAmI?.email || '')

    const likedList = verifyNotification
      ? currentLikedList.filter((email) => email !== whoAmI?.email)
      : [...currentLikedList, whoAmI?.email]

    await updateNotifications({
      ...notificationFound,
      metadata: {
        ...notificationFound.metadata,
        liked: likedList as string[],
      },
    })

    if (!verifyNotification) {
      sendMessage({
        type: 'react',
        message: {
          author: whoAmI?.email || '',
        },
      })
    }

    queryClient.refetchQueries({
      queryKey: [QUERIES_ADMIN.SALE_BOARD.module, 'notifications'],
    })
  }

  const getPercentage = (value: number = 0) => {
    return +formatNumber(value * 100)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <Metrics isLoading={metricsIsLoading} values={metricsData?.data} />
        <div className={styles.columns}>
          <LastNotifications
            notifications={dataNotifications?.data?.results || []}
            currentUserEmail={whoAmI?.email || ''}
            onLike={handleLike}
            onClickNotification={onClickNotification}
          />
          <div className={styles.column}>
            <Goal
              thisWeekGMV={metricsData?.data?.totalWeekGmv || 0}
              targetThisWeekGMV={targetThisWeekGMV}
              currentGMV={metricsData?.data?.totalMonthGmv || 0}
              currentGM={getPercentage(
                metricsData?.data?.totalMonthGrossMargin || 0,
              )}
              currentCM3={getPercentage(
                metricsData?.data?.totalMonthCm3Percentage || 0,
              )}
              targetGMV={targetGMV}
              targetGM={targetGM}
              targetCM3={targetCM3}
              isLoading={metricsIsLoading}
            />
            <FeedbackCarousel feedbacks={feedbacks} milliseconds={10000 * 20} />
          </div>

          <div className={styles.column}>
            <BestSellers
              values={bestSellersData?.data || []}
              isLoading={bestSellersIsLoading}
            />

            <Blog
              isLoading={blogIsLoading}
              title={blogData?.data?.[0]?.title?.rendered || ''}
              date={blogData?.data?.[0]?.date}
            />

            <DollarExchangeRate
              isLoading={dollarExchangeRateIsLoading}
              values={dollarExchangeRateData?.data?.results || []}
            />

            <KaiMessage
              message={
                dataCommentNotifications?.data?.results?.[0]?.content || ''
              }
              onClick={() =>
                onClickNotification(
                  dataCommentNotifications?.data
                    ?.results?.[0] as NotificationProps,
                )
              }
            />
          </div>
        </div>

        <NotificationPopup
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          currentUserEmail={whoAmI?.email || ''}
          onLike={handleLike}
          data={notification as NotificationProps}
        />
        <Reacts reacts={reacts} />
      </div>
    </div>
  )
}
