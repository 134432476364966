import mixpanel, { Dict } from 'mixpanel-browser'

mixpanel.init(`${import.meta.env.VITE_MIXPANEL_TOKEN}`, {
  persistence: 'localStorage',
})

export const mixpanelEvents = {
  adminLeadsListPageView: 'Admin - Leads List - Page - View',
  adminTransportSupplierListPageView:
    'Admin - Transport Supplier List - Page - View',
  adminTransportSupplierShowPageView:
    'Admin - Transport Supplier Show - Page - View',
  adminTransportCompaniesListPageView:
    'Admin - Transport Companies List - Page - View',
  adminTransportCompaniesShowPageView:
    'Admin - Transport Companies Show - Page - View',
  adminBusinessListPageView: 'Admin - Business List - Page - View',
  adminProductsListPageView: 'Admin - Products List - Page - View',
  adminOrderTrackerPageView: 'Admin - Order Tracker - Page - View',
  adminCatalogProcessorListPageView:
    'Admin - Catalog Processor List - Page - View',
  adminOrderCenterPageView: 'Admin - Order Center - Page - View',
  adminProductsListSmartSearchPageView:
    'Admin - Products List - Page - View - Smart Search Filter',
}

export const trackEvent = (
  eventName: keyof typeof mixpanelEvents,
  payload?: Dict,
) => {
  const mixpanelEvent = mixpanelEvents[eventName]

  if (
    process.env.NODE_ENV === 'development' ||
    !import.meta.env.VITE_MIXPANEL_TOKEN
  ) {
    return console.log('Mixpanel Track:', mixpanelEvent, payload || {})
  }

  mixpanel.track(mixpanelEvent, payload)
}

export const identifyEvent = (
  id: string,
  email: string,
  name: string,
  accountLevel: string,
) => {
  if (
    process.env.NODE_ENV === 'development' ||
    !import.meta.env.VITE_MIXPANEL_TOKEN
  ) {
    return console.log(
      'Mixpanel Identify:',
      id,
      name || '',
      email || '',
      accountLevel || '',
    )
  }

  mixpanel.identify(id)

  let payloadPeopleSet = {}

  if (name) payloadPeopleSet = { ...payloadPeopleSet, name }
  if (email) payloadPeopleSet = { ...payloadPeopleSet, email }
  if (accountLevel) payloadPeopleSet = { ...payloadPeopleSet, accountLevel }

  mixpanel.people.set(payloadPeopleSet)
}
