import { Text, Tag } from '@mercai/clever'

import { NotificationProps } from './notification.type'

import { distanceToNow, formatNumber } from '@/helpers'

import styles from './notification.module.css'

import { Avatar } from '../avatar'

export const Notification = ({
  uuid,
  metadata,
  title,
  content,
  tags,
  createdAt,
  currentUserEmail,
  onLike,
  size = 'small',
  onClick,
}: NotificationProps) => {
  const verifyLiked = () => {
    return (
      Array.isArray(metadata?.liked) &&
      metadata?.liked?.some((user) => user === currentUserEmail)
    )
  }

  const handleLike = () => {
    onLike(uuid)
  }

  const isLiked = verifyLiked()

  return (
    <div className={styles.wrap}>
      <Avatar name={metadata?.companyName as string} imageUrl="" size={size} />

      <button className={styles.content} onClick={() => onClick?.()}>
        <Text variant={size === 'small' ? 'title-small' : 'title-large'}>
          {title} ·{' '}
          <Text variant={size === 'small' ? 'text-small' : 'text-base'}>
            {distanceToNow(createdAt)}
          </Text>
        </Text>
        <Text variant={size === 'small' ? 'text-small' : 'text-base'}>
          {content}
        </Text>

        {Array.isArray(metadata?.categories) &&
          metadata?.categories?.length > 0 && (
            <div className={styles.tags}>
              {metadata?.categories?.map((category) => (
                <Tag
                  key={category}
                  model="outline"
                  variant="basic"
                  size={size === 'small' ? 'small' : 'medium'}
                >
                  {category}
                </Tag>
              ))}
            </div>
          )}

        {metadata?.grossProfitData?.orderCm3Margin &&
          metadata?.grossProfitData?.orderGrossMargin && (
            <Text variant={size === 'small' ? 'text-small' : 'text-base'}>
              CM3:{' '}
              {formatNumber(
                ((metadata?.grossProfitData?.orderCm3Margin || 0) as number) *
                  100,
                'es-MX',
                2,
              )}
              % - GM:{' '}
              {formatNumber(
                ((metadata?.grossProfitData?.orderGrossMargin || 0) as number) *
                  100,
                'es-MX',
                2,
              )}
              %
            </Text>
          )}

        {Array.isArray(tags) && (
          <div className={styles.tags}>
            {tags.map((tag) => (
              <Tag
                key={tag}
                variant="primary"
                model="light"
                size={size === 'small' ? 'small' : 'medium'}
              >
                {tag}
              </Tag>
            ))}
          </div>
        )}
      </button>

      <button onClick={handleLike} className={styles['like-button']}>
        <img
          className={styles.liked}
          src={isLiked ? '/images/liked-icon.svg' : '/images/unliked-icon.svg'}
          alt="like"
        />

        <Text variant={size === 'small' ? 'text-xsmall' : 'text-base'}>
          {' '}
          +{Array.isArray(metadata?.liked) ? metadata?.liked?.length : 0}
        </Text>
      </button>
    </div>
  )
}
