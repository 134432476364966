export const columnsForType = {
  all: [
    'reference',
    'buyer',
    'salesOrderStatus',
    'taxStatus',
    'paymentStatus',
    'invoiceStatus',
    'logisticStatus',
    'moscowClassification',
    'expectedDeliveryDate',
    'items',
    'transportPrice',
    'totalQuantity',
    'totalPrice',
    'keyAccountManager',
  ],
  sale: [
    'reference',
    'buyer',
    'salesOrderStatus',
    'taxStatus',
    'paymentStatus',
    'invoiceStatus',
    'logisticStatus',
    'moscowClassification',
    'expectedDeliveryDate',
    'items',
    'transportPrice',
    'totalQuantity',
    'totalPrice',
    'keyAccountManager',
  ],
  profile: [
    'reference',
    'buyer',
    'salesOrderStatus',
    'taxStatus',
    'createdAtDistanceToNow',
    'items',
    'totalPrice',
    'totalQuantity',
    'moscowClassification',
    'keyAccountManager',
  ],
  finance: [
    'reference',
    'buyer',
    'paymentStatus',
    'invoiceStatus',
    'taxStatus',
    'totalPrice',
    'tableActionsButtons',
  ],
  logistic: [
    'reference',
    'buyer',
    'logisticStatus',
    'paymentStatus',
    'expectedDeliveryDate',
    'deliveryOption',
    'tableActionsButtons',
  ],
}
