import { useQuery, useMutation } from '@tanstack/react-query'

import { convertObjectToCamelCase, convertObjectToSnakeCase } from '@/helpers'

import { QUERIES_ADMIN } from './api.constants'
import { api } from './api.service'

import type {
  SaleOrderWithPagesProps,
  SaleOrderProps,
  SaleOrderFinanceWithPagesProps,
  SaleOrderLogisticWithPagesProps,
  OrderCenterLogisticClassificationProps,
  OrderCenterFinanceClassificationProps,
} from '@/types'

import {
  ORDERS_CENTER_FINANCE_URL,
  ORDERS_CENTER_FINANCE_CLASSIFICATION_URL,
  ORDERS_CENTER_PROFILE_URL,
  ORDERS_CENTER_LOGISTIC_URL,
  ORDERS_CENTER_LOGISTIC_CLASSIFICATION_URL,
  ORDERS_CENTER_SALE_ORDER_URL,
  ORDERS_CENTER_SALE_ORDERS_URL,
} from './api.urls'

export const useOrderCenterProfileList = (
  params: Record<string, string | number>,
  isEnabled = true,
) => {
  const getOrderProfileList = async (): Promise<{
    success: boolean
    message: string
    data?: SaleOrderWithPagesProps
  }> => {
    try {
      const copyParams = { ...params }

      const offset = copyParams?.page
        ? (+(copyParams?.page || 0) - 1) * +copyParams.limit
        : 0

      delete copyParams.page

      const formattedParams = Object.keys(copyParams).reduce((acc, key) => {
        if (copyParams?.[key] && copyParams?.[key] !== 'undefined') {
          return {
            ...acc,
            [key]: copyParams?.[key],
          }
        }

        return acc
      }, {})

      const { data } = await api.get(ORDERS_CENTER_PROFILE_URL, {
        params: {
          ...(convertObjectToSnakeCase(formattedParams) as Record<
            string,
            string
          >),
          offset,
        },
      })

      return {
        success: true,
        message: 'Order requests fetched successfully!',
        data: convertObjectToCamelCase(data) as SaleOrderWithPagesProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get order requests!',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [QUERIES_ADMIN.ORDER_CENTER.module, 'profile', params],
    queryFn: () => getOrderProfileList(),
    enabled: isEnabled,
  })
}

export const useOrderCenterFinanceList = (
  params: Record<string, string | number>,
  isEnabled = true,
) => {
  const getOrderFinanceList = async (): Promise<{
    success: boolean
    message: string
    data?: SaleOrderFinanceWithPagesProps
  }> => {
    try {
      const copyParams = { ...params }

      const offset = copyParams?.page
        ? (+(copyParams?.page || 0) - 1) * +copyParams.limit
        : 0

      delete copyParams.page

      const formattedParams = Object.keys(copyParams).reduce((acc, key) => {
        if (copyParams?.[key] && copyParams?.[key] !== 'undefined') {
          return {
            ...acc,
            [key]: copyParams?.[key],
          }
        }

        return acc
      }, {})

      const { data } = await api.get(ORDERS_CENTER_FINANCE_URL, {
        params: {
          ...(convertObjectToSnakeCase(formattedParams) as Record<
            string,
            string
          >),
          offset,
        },
      })

      return {
        success: true,
        message: 'Order finance fetched successfully!',
        data: convertObjectToCamelCase(data) as SaleOrderFinanceWithPagesProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get order finance!',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [QUERIES_ADMIN.ORDER_CENTER.module, 'finance', params],
    queryFn: () => getOrderFinanceList(),
    enabled: isEnabled,
  })
}

export const useOrderCenterFinanceClassification = (
  filter?: Record<string, string | number>,
  isEnabled = true,
) => {
  const getOrderLogisticClassification = async (): Promise<{
    success: boolean
    message: string
    data?: OrderCenterFinanceClassificationProps
  }> => {
    try {
      const { data } = await api.get(ORDERS_CENTER_FINANCE_CLASSIFICATION_URL, {
        params: {
          ...(convertObjectToSnakeCase(filter) as Record<string, string>),
        },
      })

      return {
        success: true,
        message: 'Order finance classification fetched successfully!',
        data: convertObjectToCamelCase(
          data,
        ) as OrderCenterFinanceClassificationProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get order finance classification!',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [
      QUERIES_ADMIN.ORDER_CENTER.module,
      'finance-classification',
      filter,
    ],
    queryFn: () => getOrderLogisticClassification(),
    enabled: isEnabled,
  })
}

export const useOrderCenterLogisticList = (
  params: Record<string, string | number>,
  isEnabled = true,
) => {
  const getOrderLogisticList = async (): Promise<{
    success: boolean
    message: string
    data?: SaleOrderLogisticWithPagesProps
  }> => {
    try {
      const copyParams = { ...params }

      const offset = copyParams?.page
        ? (+(copyParams?.page || 0) - 1) * +copyParams.limit
        : 0

      delete copyParams.page

      const formattedParams = Object.keys(copyParams).reduce((acc, key) => {
        if (copyParams?.[key] && copyParams?.[key] !== 'undefined') {
          return {
            ...acc,
            [key]: copyParams?.[key],
          }
        }

        return acc
      }, {})

      const { data } = await api.get(ORDERS_CENTER_LOGISTIC_URL, {
        params: {
          ...(convertObjectToSnakeCase(formattedParams) as Record<
            string,
            string
          >),
          offset,
        },
      })

      return {
        success: true,
        message: 'Order logistic fetched successfully!',
        data: convertObjectToCamelCase(data) as SaleOrderLogisticWithPagesProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get order logistic!',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [QUERIES_ADMIN.ORDER_CENTER.module, 'logistic', params],
    queryFn: () => getOrderLogisticList(),
    enabled: isEnabled,
  })
}

export const useOrderCenterLogisticClassification = (
  filter?: Record<string, string | number>,
  isEnabled = true,
) => {
  const getOrderLogisticClassification = async (): Promise<{
    success: boolean
    message: string
    data?: OrderCenterLogisticClassificationProps
  }> => {
    try {
      const { data } = await api.get(
        ORDERS_CENTER_LOGISTIC_CLASSIFICATION_URL,
        {
          params: {
            ...(convertObjectToSnakeCase(filter) as Record<string, string>),
          },
        },
      )

      return {
        success: true,
        message: 'Order logistic classification fetched successfully!',
        data: convertObjectToCamelCase(
          data,
        ) as OrderCenterLogisticClassificationProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get order logistic classification!',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [
      QUERIES_ADMIN.ORDER_CENTER.module,
      'logistic-classification',
      filter,
    ],
    queryFn: () => getOrderLogisticClassification(),
    enabled: isEnabled,
  })
}

export const useOrderCenterSaleOrderList = (
  params: Record<string, string | number>,
  isEnabled = true,
) => {
  const getOrderSaleOrderList = async (): Promise<{
    success: boolean
    message: string
    data?: SaleOrderWithPagesProps
  }> => {
    try {
      const copyParams = { ...params }

      const offset = copyParams?.page
        ? (+(copyParams?.page || 0) - 1) * +copyParams.limit
        : 0

      delete copyParams.page

      const formattedParams = Object.keys(copyParams).reduce((acc, key) => {
        if (copyParams?.[key] && copyParams?.[key] !== 'undefined') {
          return {
            ...acc,
            [key]: copyParams?.[key],
          }
        }

        return acc
      }, {})

      const { data } = await api.get(ORDERS_CENTER_SALE_ORDERS_URL, {
        params: {
          ...(convertObjectToSnakeCase(formattedParams) as Record<
            string,
            string
          >),
          offset,
        },
      })

      return {
        success: true,
        message: 'Order sale order fetched successfully!',
        data: convertObjectToCamelCase(data) as SaleOrderWithPagesProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get order sale order!',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [QUERIES_ADMIN.ORDER_CENTER.module, 'sale-order', params],
    queryFn: () => getOrderSaleOrderList(),
    enabled: isEnabled,
  })
}

export const useOrderCenterSaleOrderDetail = (uuid?: string) => {
  const getOrderProfileDetail = async (): Promise<{
    success: boolean
    message: string
    data?: SaleOrderProps
  }> => {
    try {
      const { data } = await api.get(ORDERS_CENTER_SALE_ORDER_URL(uuid || ''))

      return {
        success: true,
        message: 'Sale order fetched successfully!',
        data: convertObjectToCamelCase(data) as SaleOrderProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get sale order!',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [QUERIES_ADMIN.ORDER_CENTER.module, uuid],
    queryFn: () => getOrderProfileDetail(),
    enabled: !!uuid,
  })
}

export const useOrderCenterSaleOrderUpdate = (uuid?: string) => {
  const updateOrder = async (
    payload: Partial<SaleOrderProps>,
  ): Promise<{
    success: boolean
    message: string
    data?: SaleOrderProps
  }> => {
    try {
      const { data } = await api.patch(
        ORDERS_CENTER_SALE_ORDER_URL(`${uuid}`),
        convertObjectToSnakeCase(payload),
      )

      return {
        success: true,
        message: 'Sale order updated successfully!',
        data: convertObjectToCamelCase(data) as SaleOrderProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to update sale order!',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.ORDER_CENTER.module, uuid],
    mutationFn: (payload: Partial<SaleOrderProps>) => updateOrder(payload),
  })
}

export const useOrderCenterSaleOrderRemove = () => {
  const deleteOrder = async (
    uuid: string,
  ): Promise<{
    success: boolean
    message: string
    data?: SaleOrderProps
  }> => {
    try {
      const { data } = await api.delete(ORDERS_CENTER_SALE_ORDER_URL(`${uuid}`))

      return {
        success: true,
        message: 'Sale order deleted successfully!',
        data: convertObjectToCamelCase(data) as SaleOrderProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to delete sale order!',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.ORDER_CENTER.module],
    mutationFn: (uuid: string) => deleteOrder(uuid),
  })
}
