import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, DrawerStepperProgress } from '@mercai/clever'

import {
  QUERIES_ADMIN,
  useCreateSupplierProduct,
  useUpdateSupplierProduct,
} from '@/services'
import { SupplierProductProps } from '@/types'

import {
  onErrorMessage,
  onSuccessMessage,
  useValidationErrors,
} from '@/helpers'

import { useQueryClient } from '@tanstack/react-query'

import {
  schemaSupplierProducts,
  SupplierProductForm,
} from '../supplier-product-form'

import { schemaSupplier, SupplierForm } from '../supplier-form'

import type { SupplierProductDrawerFormProps } from './supplier-product-drawer-form.type'

export const SupplierProductDrawerForm = ({
  isOpen,
  onHandleOpen,
  supplierProduct,
  supplierUuid,
  catalogProductUuid,
  setSupplierUuid,
  isAddSupplier,
}: SupplierProductDrawerFormProps) => {
  const { t } = useTranslation('screenCatalogProduct')

  const queryClient = useQueryClient()

  const [values, setValues] = useState<SupplierProductProps>({
    ...supplierProduct,
    catalogProductUuid,
  } as SupplierProductProps)

  const { mutateAsync: mutateAsyncCreateSupplierProduct } =
    useCreateSupplierProduct()

  const { mutateAsync: mutateAsyncUpdateSupplierProduct } =
    useUpdateSupplierProduct()

  const { isValid, errors, onHandleListenErrors } = useValidationErrors(
    schemaSupplierProducts(t),
    values,
  )

  const {
    isValid: isValidSupplier,
    errors: errorsSupplier,
    onHandleListenErrors: onHandleListenErrorsSupplier,
  } = useValidationErrors(schemaSupplier(t), { supplierUuid })

  const onSupplierFormSubmit = (
    onNavigateToNextStep: (value: string) => void,
  ) => {
    if (!isValidSupplier) {
      onHandleListenErrorsSupplier(true)
      return
    }

    onHandleListenErrorsSupplier(false)

    onNavigateToNextStep('supplier_product')
  }

  const onSubmitForm = async () => {
    if (!isValid) {
      onHandleListenErrors(true)
      return
    }

    onHandleListenErrors(false)

    let response: {
      success: boolean
      message: string
      data?: SupplierProductProps
    } = {
      success: false,
      message: '',
      data: {} as SupplierProductProps,
    }

    if (isAddSupplier) {
      response = await mutateAsyncCreateSupplierProduct(
        values as Partial<SupplierProductProps>,
      )
    } else {
      response = await mutateAsyncUpdateSupplierProduct(
        values as Partial<SupplierProductProps>,
      )
    }

    if (response.success) {
      if (isAddSupplier) {
        onSuccessMessage(
          t('detailSupplierFormSuccessCreateTitle'),
          t('detailSupplierFormSuccessCreateMessage'),
        )
      } else {
        onSuccessMessage(
          t('detailSupplierFormSuccessUpdateTitle'),
          t('detailSupplierFormSuccessUpdateMessage'),
        )
      }

      onHandleOpen(false)

      queryClient.invalidateQueries({
        queryKey: [QUERIES_ADMIN.SUPPLIER.module],
      })
    } else {
      if (isAddSupplier) {
        onErrorMessage(
          t('detailSupplierFormErrorCreateTitle'),
          t('detailSupplierFormErrorCreateMessage'),
        )
      } else {
        onErrorMessage(
          t('detailSupplierFormErrorUpdateTitle'),
          t('detailSupplierFormErrorUpdateMessage'),
        )
      }
    }
  }

  useEffect(() => {
    setSupplierUuid('')
    if (isAddSupplier) {
      setValues({ catalogProductUuid } as SupplierProductProps)
    } else {
      setValues({
        ...supplierProduct,
        catalogProductUuid,
      } as SupplierProductProps)
    }
  }, [supplierProduct, catalogProductUuid, isOpen, isAddSupplier])

  return (
    <DrawerStepperProgress
      isOpen={isOpen}
      onHandleOpen={onHandleOpen}
      translations={{
        optional: t('supplierFormOptional'),
      }}
      initialStep={isAddSupplier ? 'root' : 'supplier_product'}
      totalSteps={isAddSupplier ? 2 : 1}
      title={
        isAddSupplier
          ? t('detailSupplierFormTitle')
          : t('detailSupplierProductsFormTitle')
      }
      description={
        isAddSupplier
          ? t('detailSupplierFormDescription')
          : t('detailSupplierProductsFormDescription')
      }
      steps={{
        root: {
          isOptional: false,
          backNavigate: 'root',
          stepNumber: 0,
          component: (
            <SupplierForm
              errors={errorsSupplier as Record<string, string>}
              supplierUuid={supplierUuid}
              setSupplierUuid={setSupplierUuid}
            />
          ),
          footer: ({ onNavigateToNextStep }) => (
            <>
              <Button variant="secondary" onClick={() => onHandleOpen(false)}>
                {t('detailSupplierProductsFormNavigateButtonBack')}
              </Button>
              <Button
                onClick={() => onSupplierFormSubmit(onNavigateToNextStep)}
              >
                {isAddSupplier
                  ? t('detailSupplierProductsFormNavigateButtonNext')
                  : t('detailSupplierProductsFormNavigateButtonSave')}
              </Button>
            </>
          ),
        },
        supplier_product: {
          isOptional: false,
          backNavigate: isAddSupplier ? 'root' : 'supplier_product',
          stepNumber: 0,
          component: (
            <SupplierProductForm
              errors={errors}
              onChangeValues={setValues}
              values={values}
              supplierUuid={supplierUuid}
            />
          ),
          footer: ({ onNavigateToPreviousStep }) => (
            <>
              <Button
                variant="secondary"
                onClick={() =>
                  isAddSupplier
                    ? onNavigateToPreviousStep()
                    : onHandleOpen(false)
                }
              >
                {t('detailSupplierProductsFormNavigateButtonBack')}
              </Button>
              <Button onClick={onSubmitForm}>
                {t('detailSupplierProductsFormNavigateButtonSave')}
              </Button>
            </>
          ),
        },
      }}
    />
  )
}
