import { Line } from 'react-chartjs-2'

import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
} from 'chart.js'
import 'chart.js/auto'

import { LineChartProps } from './line-chart.type'

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement)

export const LineChart = ({ data = [] }: LineChartProps) => {
  const chartData = {
    labels: data.map((_, index) => index + 1),
    datasets: [
      {
        data: data.map((item) => item.value),
        borderColor: '#22C55E',
        borderWidth: 2,
        tension: 0.4,
        pointRadius: 0,
      },
    ],
  }

  const chartOptions = {
    responsive: true,
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  }

  return <Line data={chartData} options={chartOptions} />
}
