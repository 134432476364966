import { useEffect, useState } from 'react'

import { FeedbackCarouselProps } from './feedback-carousel.type'

import styles from './feedback-carousel.module.css'
import { Text } from '@mercai/clever'

export const FeedbackCarousel = ({
  feedbacks,
  milliseconds = 5000,
}: FeedbackCarouselProps) => {
  const [position, setPosition] = useState(0)

  const [cardHeight, setCardHeight] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setPosition(position < feedbacks.length - 1 ? position + 1 : 0)
    }, milliseconds)

    return () => {
      clearInterval(interval)
    }
  }, [position])

  useEffect(() => {
    const element = document.getElementById(`feedback-card-${position}`)

    if (element?.clientHeight) {
      setCardHeight(element.clientHeight)
    }

    const handleResize = () => {
      if (element?.clientHeight) {
        setCardHeight(element.clientHeight)
      }
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [position])

  return (
    <div className={styles.core} style={{ height: `${cardHeight}px` }}>
      {feedbacks?.map(({ author, content }, i) => (
        <div
          id={`feedback-card-${i}`}
          key={author + i}
          className={`${styles['feedback-card-container']} ${position === i ? styles.active : ''}`}
        >
          <img src="/images/bg-feedback-card.svg" alt="feedback-background" />
          <Text
            variant="text-small"
            className={styles['feedback-card-content']}
          >
            {content}
          </Text>
          <Text
            variant="title-xsmall"
            className={styles['feedback-card-author']}
          >
            <strong>{author}</strong>
          </Text>
        </div>
      ))}
    </div>
  )
}
