import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@mercai/clever'

import { UserProps } from '@/types'

import { UsersTable } from './components/table'
import { UsersDrawerForm } from './components/drawer-form'

import { ProfileUsersProps } from './users.type'

import styles from './users.module.css'
import { useProfileDetail } from '@/services'

export const ProfileShowBuyerUsers = ({ profileUuid }: ProfileUsersProps) => {
  const { t } = useTranslation('screenProfileShow')

  const [isOpenDrawerForm, setIsOpenDrawerForm] = useState(false)
  const [formData, setFormData] = useState<UserProps | undefined>(undefined)

  const { data, isLoading } = useProfileDetail(profileUuid || '')

  const onCloseDrawerForm = () => setIsOpenDrawerForm(false)

  const onEdit = (data: UserProps) => {
    setFormData(data)

    setIsOpenDrawerForm(true)
  }

  const onAdd = () => {
    setFormData(undefined)

    setIsOpenDrawerForm(true)
  }

  return (
    <div>
      <div className={styles.content}>
        <div className={styles.row}>
          <div className={styles['add-button']}>
            <Button variant="primary" onClick={onAdd}>
              {t('userTableAddButton')}
            </Button>
          </div>
        </div>

        <UsersTable
          data={data?.data?.users || []}
          isLoading={isLoading}
          onEdit={onEdit}
        />
      </div>

      <UsersDrawerForm
        onClose={onCloseDrawerForm}
        isOpen={isOpenDrawerForm}
        data={formData}
        profileUuid={profileUuid || ''}
      />
    </div>
  )
}
