'use client'
import { useTranslation } from 'react-i18next'
import {
  Button,
  EditIcon,
  TableColumns,
  TableColumnsColumnProps,
  Tag,
  Text,
} from '@mercai/clever'

import { UserProps } from '@/types'

import { UsersTableProps } from './table.type'

import styles from './table.module.css'

export const UsersTable = ({ data, onEdit, isLoading }: UsersTableProps) => {
  const { t } = useTranslation('screenProfileShow')

  const getStatus = (isActive?: boolean) => {
    if (isActive) {
      return (
        <Tag variant="success" model="light">
          {t('userTableColumnStatusActive')}
        </Tag>
      )
    }
    return (
      <Tag variant="basic" model="light">
        {t('userTableColumnStatusInactive')}
      </Tag>
    )
  }

  const columns: TableColumnsColumnProps<UserProps>[] = [
    {
      key: 'name',
      dataKey: 'name',
      title: t('userTableColumnUser'),
      render: ({ value }) => <Text>{value}</Text>,
    },
    {
      key: 'email',
      dataKey: 'email',
      title: t('userTableColumnEmail'),
      render: ({ value }) => <Text>{value}</Text>,
    },
    {
      key: 'phone',
      dataKey: 'phone',
      title: t('userTableColumnPhone'),
      render: ({ value }) => <Text>{value}</Text>,
    },
    {
      key: 'isActive',
      dataKey: 'isActive',
      title: t('userTableColumnStatus'),
      render: ({ record }) => getStatus(record?.isActive),
    },
    {
      key: 'id',
      dataKey: 'id',
      title: t('userTableColumnActions'),
      width: '6rem',
      render: ({ record }) => (
        <Button
          variant="secondary"
          size="medium"
          onClick={() => onEdit(record)}
          model="square"
        >
          <EditIcon />
        </Button>
      ),
    },
  ]

  return (
    <div className={styles.core}>
      <TableColumns<UserProps>
        columns={columns}
        data={data}
        isLoading={isLoading}
      />
    </div>
  )
}
