import {
  SummaryValues as SummaryValuesDS,
  Skeleton,
  Text,
} from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import { formatCurrency } from '@/helpers'

import styles from './summary-values.module.css'

import type { SummaryValuesProps } from './summary-values.type'

const OrderSummaryValuesDefault = ({
  totalFreight,
  iva,
  subTotal,
  total,
  discount,
  dollarQuotation,
}: SummaryValuesProps) => {
  const { t } = useTranslation('componentOrderCenterShow')

  return (
    <div className={styles.wrap}>
      <div className={styles['dollar-quotation-info']}>
        <Text>{t('orderPaymentShowDollarQuotation')}</Text>
        <Text>
          <strong>
            USD 1.00 = {formatCurrency(dollarQuotation || 0, 'es-MX', 'MXN', 2)}
          </strong>
        </Text>
      </div>

      <SummaryValuesDS.Root>
        <SummaryValuesDS.Row>
          <div></div>
          <SummaryValuesDS.Column type="bookend" direction="right">
            {t('orderPaymentShowSummaryValuesMXNCurrency')}
          </SummaryValuesDS.Column>
          <SummaryValuesDS.Column type="bookend" direction="right">
            {t('orderPaymentShowSummaryValuesUSDCurrency')}
          </SummaryValuesDS.Column>
        </SummaryValuesDS.Row>

        <SummaryValuesDS.Row>
          <SummaryValuesDS.Column type="value">
            {t('orderPaymentShowSummaryValuesSubTotal')}
          </SummaryValuesDS.Column>
          <SummaryValuesDS.Column type="value" direction="right">
            {formatCurrency(subTotal * dollarQuotation)}
          </SummaryValuesDS.Column>
          <SummaryValuesDS.Column type="value" direction="right">
            {formatCurrency(subTotal)}
          </SummaryValuesDS.Column>
        </SummaryValuesDS.Row>

        {!discount || (
          <SummaryValuesDS.Row>
            <SummaryValuesDS.Column type="value">
              {t('orderPaymentShowSummaryValuesDiscount')}
            </SummaryValuesDS.Column>
            <SummaryValuesDS.Column type="value" direction="right">
              - {formatCurrency(discount * dollarQuotation)}
            </SummaryValuesDS.Column>
            <SummaryValuesDS.Column type="value" direction="right">
              - {formatCurrency(discount)}
            </SummaryValuesDS.Column>
          </SummaryValuesDS.Row>
        )}

        {iva > 0 && (
          <SummaryValuesDS.Row>
            <SummaryValuesDS.Column type="value">
              {t('orderPaymentShowSummaryValuesIVA')}
            </SummaryValuesDS.Column>
            <SummaryValuesDS.Column type="value" direction="right">
              {formatCurrency(iva * dollarQuotation)}
            </SummaryValuesDS.Column>
            <SummaryValuesDS.Column type="value" direction="right">
              {formatCurrency(iva)}
            </SummaryValuesDS.Column>
          </SummaryValuesDS.Row>
        )}

        <SummaryValuesDS.Row>
          <SummaryValuesDS.Column type="value">
            {t('orderPaymentShowSummaryValuesDelivery')}
          </SummaryValuesDS.Column>
          <SummaryValuesDS.Column type="value" direction="right">
            {formatCurrency(totalFreight * dollarQuotation)}
          </SummaryValuesDS.Column>
          <SummaryValuesDS.Column type="value" direction="right">
            {formatCurrency(totalFreight)}
          </SummaryValuesDS.Column>
        </SummaryValuesDS.Row>

        <SummaryValuesDS.Row type="strip" className={styles['summary-total']}>
          <SummaryValuesDS.Column type="bookend">
            {t('orderPaymentShowSummaryValuesAmount')}
          </SummaryValuesDS.Column>
          <SummaryValuesDS.Column type="bookend" direction="right">
            {formatCurrency(total * dollarQuotation)}
          </SummaryValuesDS.Column>
          <SummaryValuesDS.Column type="bookend" direction="right">
            {formatCurrency(total)}
          </SummaryValuesDS.Column>
        </SummaryValuesDS.Row>
      </SummaryValuesDS.Root>
    </div>
  )
}

const QuotationSummaryValuesLoading = () => {
  return (
    <SummaryValuesDS.Root>
      <SummaryValuesDS.Row>
        <SummaryValuesDS.Column type="bookend">
          <Skeleton width="5rem" height="1.5rem" />
        </SummaryValuesDS.Column>
        <SummaryValuesDS.Column type="bookend" direction="right">
          <Skeleton width="3rem" height="1.5rem" />
        </SummaryValuesDS.Column>
        <SummaryValuesDS.Column type="bookend" direction="right">
          <Skeleton width="3rem" height="1.5rem" />
        </SummaryValuesDS.Column>
      </SummaryValuesDS.Row>

      <SummaryValuesDS.Row>
        <SummaryValuesDS.Column type="label">
          <Skeleton width="7rem" height="1rem" />
        </SummaryValuesDS.Column>
        <SummaryValuesDS.Column type="value" direction="right">
          <Skeleton width="6rem" height="1rem" />
        </SummaryValuesDS.Column>
        <SummaryValuesDS.Column type="value" direction="right">
          <Skeleton width="6rem" height="1rem" />
        </SummaryValuesDS.Column>
      </SummaryValuesDS.Row>

      <SummaryValuesDS.Row>
        <SummaryValuesDS.Column type="label">
          <Skeleton width="7rem" height="1rem" />
        </SummaryValuesDS.Column>
        <SummaryValuesDS.Column type="value" direction="right">
          <Skeleton width="6rem" height="1rem" />
        </SummaryValuesDS.Column>
        <SummaryValuesDS.Column type="value" direction="right">
          <Skeleton width="6rem" height="1rem" />
        </SummaryValuesDS.Column>
      </SummaryValuesDS.Row>

      <SummaryValuesDS.Row>
        <SummaryValuesDS.Column type="label">
          <Skeleton width="7rem" height="1rem" />
        </SummaryValuesDS.Column>
        <SummaryValuesDS.Column type="value" direction="right">
          <Skeleton width="6rem" height="1rem" />
        </SummaryValuesDS.Column>
        <SummaryValuesDS.Column type="value" direction="right">
          <Skeleton width="6rem" height="1rem" />
        </SummaryValuesDS.Column>
      </SummaryValuesDS.Row>

      <SummaryValuesDS.Row type="strip">
        <SummaryValuesDS.Column type="bookend">
          <Skeleton width="5rem" height="1.6rem" />
        </SummaryValuesDS.Column>
        <SummaryValuesDS.Column type="bookend" direction="right">
          <Skeleton width="6rem" height="1.6rem" />
        </SummaryValuesDS.Column>
        <SummaryValuesDS.Column type="bookend" direction="right">
          <Skeleton width="7rem" height="1.6rem" />
        </SummaryValuesDS.Column>
      </SummaryValuesDS.Row>
    </SummaryValuesDS.Root>
  )
}

export const OrderSummaryValues = (props: SummaryValuesProps) => {
  if (props.isLoading) {
    return <QuotationSummaryValuesLoading />
  }

  return <OrderSummaryValuesDefault {...props} />
}
