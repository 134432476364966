export const DEFAULT_LIMIT_PAGE = 10

export const mercaiAddressCD =
  'Blvd. Benito Juárez 49 int. Bodega 4 (Arcadia), 54948 Méx. REF. Al frente de la entrada a TultiPark II'

export const linkMapsLocationMercai =
  'https://www.google.com/maps/place/COMERCIALIZADORA+SARUGA+SA+DE+CV/@19.6338052,-99.153878,18.88z/data=!4m14!1m7!3m6!1s0x85d1f5e1a7416f35:0x38e4249e8eacb0a3!2sTultipark+II!8m2!3d19.6335568!4d-99.1528693!16s%2Fg%2F11rz8ym_gp!3m5!1s0x85d1f53779c32889:0xb1330c19b546514a!8m2!3d19.6336664!4d-99.1540235!16s%2Fg%2F11mhg9zv7g?entry=ttu'

export const appQuotationLink = (uuid: string) =>
  `${import.meta.env.VITE_APP_URL}buyer/quotation/${uuid}`
export const appOrderLink = (uuid: string) =>
  `${import.meta.env.VITE_APP_URL}buyer/order/${uuid}`

export const productsLink = (reference: string) =>
  `${import.meta.env.VITE_OPEN_AREA_URL}/product/_${reference}`
