import { Text } from '@mercai/clever'

import { AvatarProps } from './avatar.type'

import { generateAvatarClassName } from './avatar.constants'

import styles from './avatar.module.css'

export const Avatar = ({ name, imageUrl, size = 'small' }: AvatarProps) => {
  const className = generateAvatarClassName(size)

  const getInitials = () => {
    const splittedName = name?.split(' ')

    let initials = ''

    if (splittedName?.[0]) {
      initials = `${splittedName[0][0]}`
    }

    if (splittedName?.[splittedName?.length - 1]) {
      initials = `${initials}${splittedName[splittedName?.length - 1][0]}`
    }

    return initials || '-'
  }

  return (
    <div
      className={className}
      style={imageUrl ? { backgroundImage: `url(${imageUrl})` } : {}}
    >
      <Text
        variant={size === 'small' ? 'title-small' : 'title-large'}
        className={styles.initials}
      >
        {getInitials()}
      </Text>
    </div>
  )
}
