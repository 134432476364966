import { Text, CloseIcon } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import styles from './header.module.css'

import type { FinanceProfilePaymentConditionHeaderProps } from './header.type'

export const FinanceProfilePaymentConditionHeader = ({
  onHandleOpen,
  profileName,
}: FinanceProfilePaymentConditionHeaderProps) => {
  const { t } = useTranslation('componentsProfilePaymentForm')

  return (
    <div className={styles.core}>
      <div className={styles.content}>
        <div className={styles.title}>
          <Text variant="title-large">{t('headerTitle')}</Text>
          <Text variant="text-medium" className={styles['gray-600']}>
            {profileName}
          </Text>
        </div>

        <button
          className={styles.button}
          type="button"
          onClick={() => onHandleOpen(false)}
        >
          <CloseIcon />
        </button>
      </div>
    </div>
  )
}
