import { useEffect, useMemo, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'

import {
  ChevronLeftIcon,
  CloseIcon,
  Drawer,
  Text,
  Select,
  Label,
} from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import { AutoGenerateForm } from '@/components'
import { onErrorMessage, onSuccessMessage } from '@/helpers'
import {
  useCatalogCategoryList,
  useCreateCatalogProduct,
  useUpdateCatalogProduct,
  useCatalogCategoryDetail,
  useCatalogProductDetail,
  QUERIES_ADMIN,
} from '@/services'

import styles from './drawer-form.module.css'

import type { DrawerFormProps } from './drawer-form.type'
import { CatalogProductProps } from '@/types'

export const DrawerForm = ({
  isOpen,
  onHandleOpen,
  catalogProductUuid,
}: DrawerFormProps) => {
  const { t } = useTranslation('screenCatalogProduct')

  const queryClient = useQueryClient()

  const isNewRegister = !catalogProductUuid

  const [category, setCategory] = useState('')
  const [errors, setErrors] = useState<Record<string, string>>({})

  const { data } = useCatalogCategoryList()

  const { data: categoryData, isLoading: isLoadingCategory } =
    useCatalogCategoryDetail(category)

  const { data: catalogProductData } = useCatalogProductDetail(
    catalogProductUuid,
    false,
    { noop: 'true' },
  )

  useEffect(() => {
    if (catalogProductData?.data?.category) {
      setCategory(catalogProductData.data.category.uuid)
    }
  }, [catalogProductData])

  const { mutateAsync: mutateCreate } = useCreateCatalogProduct()

  const { mutateAsync: mutateUpdate } = useUpdateCatalogProduct(
    catalogProductUuid || '',
  )

  const categoryOptions = useMemo(() => {
    if (data?.data?.results) {
      return data.data.results.map((category) => ({
        label: category.name,
        value: category.uuid,
      }))
    }

    return []
  }, [data])

  const onSubmit = async (data: unknown) => {
    let response: {
      success: boolean
      message: string
      data?: CatalogProductProps
    } = {
      success: false,
      message: '',
      data: {} as CatalogProductProps,
    }

    if (isNewRegister) {
      response = await mutateCreate({
        attributes: data as CatalogProductProps['attributes'],
        categoryUuid: category,
      })
    } else {
      response = await mutateUpdate({
        uuid: catalogProductUuid,
        attributes: data as CatalogProductProps['attributes'],
        categoryUuid: category,
      })
    }

    if (response.success) {
      if (isNewRegister) {
        onSuccessMessage(t('successTitle'), t('successCreateMessage'))
      } else {
        onSuccessMessage(t('successTitle'), t('successUpdateMessage'))
      }

      setErrors({})
      onHandleOpen(false)

      queryClient.invalidateQueries({
        queryKey: [QUERIES_ADMIN.CATALOG_PRODUCT.module],
      })
    } else {
      const attributes = (response?.data?.attributes || {}) as Record<
        string,
        string
      >

      const formattedErrors = Object.keys(attributes || {}).reduce(
        (acc, key) => {
          if (attributes?.[key]) {
            return {
              ...acc,
              [key]: attributes?.[key]?.[0],
            }
          }

          return acc
        },
        {} as Record<string, string>,
      )

      setErrors((formattedErrors || {}) as Record<string, string>)

      if (isNewRegister) {
        onErrorMessage(t('errorTitle'), t('errorCreateMessage'))
      } else {
        onErrorMessage(t('errorTitle'), t('errorUpdateMessage'))
      }
    }
  }

  const getInitialValues = () => {
    if (!isNewRegister) {
      return (catalogProductData?.data?.attributes || {}) as Record<
        string,
        unknown
      >
    }

    return {}
  }

  return (
    <Drawer isOpen={isOpen} onHandleOpen={onHandleOpen}>
      <header className={styles.header}>
        <div className={styles['header-box-content']}>
          <Text className={styles['header-title']} variant="title-large">
            {isNewRegister
              ? t('drawerNewRegisterTitle')
              : t('drawerUpdateRegisterTitle')}
          </Text>
          <Text className={styles['header-subtitle']}>
            {isNewRegister
              ? t('drawerNewRegisterSubTitle')
              : t('drawerUpdateRegisterSubTitle')}
          </Text>
        </div>

        <button
          type="button"
          onClick={() => onHandleOpen(false)}
          className={styles['header-button']}
        >
          <CloseIcon />
        </button>
      </header>

      <main className={styles.main}>
        <div className={styles['main-box']}>
          <div className={styles['main-header']}>
            <button
              className={styles['main-header-back']}
              type="button"
              onClick={() => onHandleOpen(false)}
            >
              <ChevronLeftIcon />
            </button>

            <Text className={styles['main-title']} variant="text-large-bold">
              {isNewRegister
                ? t('drawerNewRegisterTitle')
                : t('drawerUpdateRegisterTitle')}
            </Text>
          </div>

          <div className={`${styles['main-content']}`}>
            <div>
              <Label>{t('categoryLabel')}</Label>
              <Select
                options={categoryOptions}
                value={category}
                onChange={setCategory}
              />
            </div>

            <AutoGenerateForm
              schema={categoryData?.data?.attributes || []}
              onSubmit={onSubmit}
              footerClassName={styles['main-footer']}
              isLoading={isLoadingCategory}
              initialValues={getInitialValues()}
              isResetForm={!isOpen}
              errors={errors}
            />
          </div>
        </div>
      </main>
    </Drawer>
  )
}
