import {
  InvalidateQueryFilters,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { convertObjectToCamelCase, convertObjectToSnakeCase } from '@/helpers'

import {
  SupplierProductListWithPagesProps,
  SupplierProductProps,
} from '@/types'

import { QUERIES_ADMIN } from './api.constants'
import { api } from './api.service'
import { SUPPLIER_PRODUCT_URL, SUPPLIER_PRODUCTS_URL } from './api.urls'

export const useSupplierProductsList = ({
  params,
}: {
  params?: Record<string, string | number>
}) => {
  const getSupplierProductsList = async (): Promise<{
    success: boolean
    message: string
    data?: SupplierProductListWithPagesProps
  }> => {
    try {
      const copyParams = { ...params }

      const offset = copyParams?.page
        ? (+(copyParams?.page || 0) - 1) * +copyParams.limit
        : 0

      delete copyParams.page

      const formattedParams = Object.keys(copyParams).reduce((acc, key) => {
        if (copyParams?.[key]) {
          return {
            ...acc,
            [key]: copyParams?.[key],
          }
        }

        return acc
      }, {})

      const { data } = await api.get(SUPPLIER_PRODUCTS_URL, {
        params: {
          ...(convertObjectToSnakeCase(formattedParams) as Record<
            string,
            string
          >),
          offset,
        },
      })

      return {
        success: true,
        message: 'Suppliers Products fetched successfully!',
        data: convertObjectToCamelCase(
          data,
        ) as SupplierProductListWithPagesProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get suppliers products!',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [QUERIES_ADMIN.SUPPLIER_PRODUCTS.module, params],
    queryFn: () => getSupplierProductsList(),
  })
}

export const useSupplierProductDetail = (uuid?: string) => {
  const getSupplierDetail = async (): Promise<{
    success: boolean
    message: string
    data?: SupplierProductProps
  }> => {
    try {
      const { data } = await api.get(SUPPLIER_PRODUCT_URL(uuid || ''))

      return {
        success: true,
        message: 'Supplier products fetched successfully!',
        data: convertObjectToCamelCase(data) as SupplierProductProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get supplier products!',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [QUERIES_ADMIN.SUPPLIER_PRODUCTS.module, uuid],
    queryFn: () => getSupplierDetail(),
    enabled: !!uuid,
  })
}

export const useCreateSupplierProduct = () => {
  const queryClient = useQueryClient()

  const createSupplier = async (
    payload: Partial<SupplierProductProps>,
  ): Promise<{
    success: boolean
    message: string
    data?: SupplierProductProps
  }> => {
    try {
      const { data } = await api.post(
        SUPPLIER_PRODUCTS_URL,
        convertObjectToSnakeCase(payload),
      )

      return {
        success: true,
        message: 'Supplier products created successfully!',
        data: convertObjectToCamelCase(data) as SupplierProductProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to create supplier products!',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.SUPPLIER_PRODUCTS.module],
    onSuccess: () => {
      queryClient.invalidateQueries([
        QUERIES_ADMIN.SUPPLIER_PRODUCTS.module,
      ] as InvalidateQueryFilters)
    },
    mutationFn: (payload: Partial<SupplierProductProps>) =>
      createSupplier(payload),
  })
}

export const useUpdateSupplierProduct = () => {
  const queryClient = useQueryClient()

  const updateSupplier = async (
    payload: Partial<SupplierProductProps>,
  ): Promise<{
    success: boolean
    message: string
    data?: SupplierProductProps
  }> => {
    try {
      const { data } = await api.patch(
        `${SUPPLIER_PRODUCTS_URL}${payload.uuid}/`,
        convertObjectToSnakeCase(payload),
      )

      return {
        success: true,
        message: 'Supplier products updated successfully!',
        data: convertObjectToCamelCase(data) as SupplierProductProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to update supplier products!',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.SUPPLIER_PRODUCTS.module],
    onSuccess: () => {
      queryClient.invalidateQueries([
        QUERIES_ADMIN.SUPPLIER_PRODUCTS.module,
      ] as InvalidateQueryFilters)
    },
    mutationFn: (payload: Partial<SupplierProductProps>) =>
      updateSupplier(payload),
  })
}

export const useDeleteSupplierProduct = () => {
  const queryClient = useQueryClient()

  const deleteSupplierProduct = async (
    uuid: string,
  ): Promise<{
    success: boolean
    message: string
    data?: SupplierProductProps
  }> => {
    try {
      const { data } = await api.delete(`${SUPPLIER_PRODUCTS_URL}${uuid}/`)

      return {
        success: true,
        message: 'Supplier product deleted successfully!',
        data: convertObjectToCamelCase(data) as SupplierProductProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to deleted supplier product!',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.SUPPLIER_PRODUCTS.module],
    onSuccess: () => {
      queryClient.invalidateQueries([
        QUERIES_ADMIN.SUPPLIER_PRODUCTS.module,
      ] as InvalidateQueryFilters)
    },
    mutationFn: (uuid: string) => deleteSupplierProduct(uuid),
  })
}
