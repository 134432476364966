import { Outlet } from 'react-router-dom'

import { WebSocketProvider } from '@/providers'

import styles from './board.module.css'

export const BoardLayout = () => {
  return (
    <WebSocketProvider>
      <div className={styles.layout}>
        <Outlet />
      </div>
    </WebSocketProvider>
  )
}
