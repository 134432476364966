import {
  MetricsCardVariantsEnum,
  MetricsCardVariantsProps,
} from './metrics-card.type'

export const variants = (
  t: (val: string) => string,
): Record<keyof typeof MetricsCardVariantsEnum, MetricsCardVariantsProps> => ({
  rfq_attended: {
    backgroundColor: '#0A1133',
    circleColor: '#0024DB',
    rotate: 0,
    title: t('rfq_attended'),
  },
  gmv_acquisition: {
    backgroundColor: '#0A1133',
    circleColor: '#EAB308',
    rotate: 0,
    title: t('gmv_acquisition'),
  },
  gmv_paid: {
    backgroundColor: '#0A1133',
    circleColor: '#22C55E',
    rotate: -10,
    title: t('gmv_paid'),
  },
  orders_delivered: {
    backgroundColor: '#0A1133',
    circleColor: '#96A6FA',
    rotate: -10,
    title: t('orders_delivered'),
  },
  active_clients: {
    circleColor: '#0A1133',
    backgroundColor: '#16A34A',
    rotate: -17,
    title: t('active_clients'),
  },
})
