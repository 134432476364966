import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  DragDropFileList,
  FileListFileProps,
  Label,
  Text,
  Select,
  Input,
} from '@mercai/clever'

import { getFileNameAndExtension } from '@/helpers'
import { ORDERS_CENTER_INVOCE_URL, api } from '@/services'

import styles from './invoice.module.css'
import type { FinanceInvoicesFormProps } from './invoice.type'
import type { OrderInvoiceProps, SaleOrderProps } from '@/types'

export const FinanceInvoicesTab = ({
  onChange,
  values = {} as SaleOrderProps,
  onSuccessUploadFiles,
}: FinanceInvoicesFormProps) => {
  const { t } = useTranslation('componentOrderCenterForm')
  const { t: tEnums } = useTranslation('enums')

  const [dataFilesInvoicePDF, setDataFilesInvoicePDF] = useState<
    FileListFileProps[]
  >([])

  const [dataFilesInvoiceXml, setDataFilesInvoiceXml] = useState<
    FileListFileProps[]
  >([])

  useEffect(() => {
    if (values?.invoices?.[0]?.pdfFile) {
      const file = getFileNameAndExtension(values?.invoices?.[0]?.pdfFile || '')

      setDataFilesInvoicePDF([
        {
          id: file.fileName,
          name: `${file.fileName}.${file.extension}`,
          url: values?.invoices?.[0]?.pdfFile,
          uploaded: true,
        },
      ])
    } else {
      setDataFilesInvoicePDF([])
    }

    if (values?.invoices?.[0]?.xmlFile) {
      const file = getFileNameAndExtension(values?.invoices?.[0]?.xmlFile || '')

      setDataFilesInvoiceXml([
        {
          id: file.fileName,
          name: `${file.fileName}.${file.extension}`,
          url: values?.invoices?.[0]?.xmlFile,
          uploaded: true,
        },
      ])
    } else {
      setDataFilesInvoiceXml([])
    }
  }, [values?.invoices, values?.payments])

  const onChangeInvoiceValues = (
    keyParam: keyof OrderInvoiceProps,
    value: string,
  ) => {
    const invoice = { ...values?.invoices?.[0] }
    const updatedInvoice = { ...invoice, [keyParam]: value }

    onChange({
      ...values,
      invoices: [updatedInvoice, ...values?.invoices.slice(1)],
    })
  }

  return (
    <div className={styles.core}>
      <div className={styles.content}>
        <div className={styles['content-title']}>
          <Text variant="title-base">{t('invoiceTitle')}</Text>
          <Text>{t('invoiceDescription')}</Text>
        </div>

        <div className={styles.field}>
          <Label>{t('invoiceIdLabel')}</Label>
          <Input
            value={values?.invoices?.[0]?.invoiceId}
            onChange={(newValue) =>
              onChangeInvoiceValues('invoiceId', newValue)
            }
            placeholder={t('invoiceIdPlaceholder')}
          />
        </div>

        <div className={styles.field}>
          <Label>{t('invoiceStatusLabel')}</Label>
          <Select
            options={[
              {
                label: tEnums('order_invoice_status_pending'),
                value: 'pending',
              },
              {
                label: tEnums('order_invoice_status_uploaded'),
                value: 'uploaded',
              },
              {
                label: tEnums('order_invoice_status_cancelled'),
                value: 'cancelled',
              },
            ]}
            value={values?.invoices?.[0]?.status}
            onChange={(newValue) => onChangeInvoiceValues('status', newValue)}
          />
        </div>

        <div className={styles.field}>
          <Label>{t('invoicePDFLabel')}</Label>
          <DragDropFileList
            translations={{
              clickToUpload: t('uploadClickToUpload'),
              dragFile: t('uploadDragFile'),
              sizeFile: t('uploadSizeFile'),
            }}
            urlAdd={ORDERS_CENTER_INVOCE_URL(values?.invoices?.[0]?.uuid || '')}
            configsRequest={{
              fileField: 'pdf_file',
              method: 'PATCH',
            }}
            axiosInstance={api}
            data={dataFilesInvoicePDF}
            onChangeData={setDataFilesInvoicePDF}
            successedUpload={onSuccessUploadFiles}
          />
        </div>

        <div className={styles.field}>
          <Label>{t('invoiceXMLLabel')}</Label>
          <DragDropFileList
            translations={{
              clickToUpload: t('uploadClickToUpload'),
              dragFile: t('uploadDragFile'),
              sizeFile: t('uploadSizeFile'),
            }}
            urlAdd={ORDERS_CENTER_INVOCE_URL(values?.invoices?.[0]?.uuid || '')}
            configsRequest={{
              fileField: 'xml_file',
              method: 'PATCH',
            }}
            axiosInstance={api}
            data={dataFilesInvoiceXml}
            onChangeData={setDataFilesInvoiceXml}
            successedUpload={onSuccessUploadFiles}
          />
        </div>
      </div>
    </div>
  )
}
