import { useEffect, useState } from 'react'

import { Button, InputNumber, Text, Drawer, Switch } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import { onSuccessMessage, onErrorMessage } from '@/helpers'

import { useCreateFinanceProfile, useUpdateFinanceProfile } from '@/services'

import styles from './profile-payment-condition-form.module.css'

import {
  FinanceProfileProps,
  FinanceProfilePaymentFormsEnum,
  FinanceProfileTransactionPaymentMethodsEnum,
} from '@/types'

import { FinanceProfilePaymentConditionHeader } from './components'

import type { ProfilePaymentFormProps } from './profile-payment-condition-form.type'

export const ProfilePaymentForm = ({
  isOpen,
  onHandleOpen,
  profileUuid,
  profileName,
  financeProfileData,
  financeProfileRefetch,
}: ProfilePaymentFormProps) => {
  const { t } = useTranslation('componentsProfilePaymentForm')
  const { t: tEnums } = useTranslation('enums')

  const isNewRecord = !financeProfileData?.createdAt

  const [upfrontPaymentPercentage, setUpfrontPaymentPercentage] =
    useState<number>(0.2)

  const [enabledPaymentForms, setEnabledPaymentForms] = useState<
    FinanceProfileProps['enabledPaymentForms']
  >([])

  const [enabledPaymentMethods, setEnabledPaymentMethods] = useState<
    FinanceProfileProps['enabledPaymentMethods']
  >([])

  const toggleEnabledPaymentForms = (
    key: keyof typeof FinanceProfilePaymentFormsEnum,
    value: boolean,
  ) => {
    if (value && !enabledPaymentForms.includes(key)) {
      setEnabledPaymentForms((prev) => [...prev, key])
    }
    if (!value) {
      setEnabledPaymentForms((prev) => prev.filter((k) => k !== key))
    }
  }

  const toggleEnabledPaymentMethods = (
    key: keyof typeof FinanceProfileTransactionPaymentMethodsEnum,
    value: boolean,
  ) => {
    if (value && !enabledPaymentMethods.includes(key)) {
      setEnabledPaymentMethods((prev) => [...prev, key])
    }
    if (!value) {
      setEnabledPaymentMethods((prev) => prev.filter((k) => k !== key))
    }
  }

  const onHandleUpfrontPaymentPercentage = (value: number) => {
    if (value > 100 || isNaN(value)) {
      value = 100
    }
    setUpfrontPaymentPercentage(value / 100)
  }

  const { mutateAsync: mutateAsyncCreate, isPending: isLoadingCreate } =
    useCreateFinanceProfile()

  const { mutateAsync: mutateAsyncUpdate, isPending: isLoadingUpdate } =
    useUpdateFinanceProfile(profileUuid)

  const onHandleSubmit = async () => {
    const mutateAsync = isNewRecord ? mutateAsyncCreate : mutateAsyncUpdate

    let payload: Partial<FinanceProfileProps> = {
      enabledPaymentForms,
      enabledPaymentMethods,
    }

    if (isNewRecord) {
      payload = {
        ...payload,
        profileUuid,
        upfrontPaymentPercentage: 1,
      }
    }

    if (enabledPaymentForms.includes('partial')) {
      payload.upfrontPaymentPercentage = upfrontPaymentPercentage
    }

    const response = await mutateAsync(payload)

    if (response.success) {
      onSuccessMessage(
        isNewRecord ? t('successCreateTitle') : t('successUpdateTitle'),
        isNewRecord ? t('successCreateMessage') : t('successUpdateMessage'),
      )

      onHandleOpen(false)

      financeProfileRefetch?.()
    } else {
      onErrorMessage(t('errorTitle'), t('errorUpdateMessage'))
    }
  }

  const getEnabledPaymentFormsComponent = (
    key: keyof typeof FinanceProfilePaymentFormsEnum,
  ) => {
    return (
      <div className={styles['switch-box']} key={key}>
        <div className={styles['switch-row']}>
          <Text>{tEnums(`financial_profile_payment_forms_${key}`)}</Text>
          <Switch
            checked={enabledPaymentForms?.includes(key) || false}
            onChange={(checked) => toggleEnabledPaymentForms(key, checked)}
          />
        </div>

        {key === 'partial' && enabledPaymentForms?.includes('partial') && (
          <div className={styles['partial-payment-box']}>
            <div>
              <Text>{t('partialPaymentUpfrontLabel')} (%)</Text>
              <InputNumber
                value={`${upfrontPaymentPercentage * 100}`}
                onChange={(value) => onHandleUpfrontPaymentPercentage(+value)}
                min={0}
                suffix=" %"
                decimalPrecision={0}
                showIcons
              />
            </div>

            <div>
              <Text>{t('partialPaymentOnDeliveryLabel')} (%)</Text>
              <InputNumber
                value={`${100 - upfrontPaymentPercentage * 100}`}
                onChange={(value) =>
                  onHandleUpfrontPaymentPercentage(100 - +value)
                }
                min={0}
                suffix=" %"
                decimalPrecision={0}
                showIcons
              />
            </div>
          </div>
        )}
      </div>
    )
  }

  const getEnabledPaymentMethodsComponent = (
    key: keyof typeof FinanceProfileTransactionPaymentMethodsEnum,
  ) => {
    return (
      <div className={styles['switch-box']} key={key}>
        <div className={styles['switch-row']}>
          <Text>
            {tEnums(`financial_profile_transaction_payment_methods_${key}`)}
          </Text>
          <Switch
            checked={enabledPaymentMethods?.includes(key) || false}
            onChange={(checked) => toggleEnabledPaymentMethods(key, checked)}
          />
        </div>
      </div>
    )
  }

  const showableEnabledPaymentForms: FinanceProfileProps['enabledPaymentForms'] =
    ['upfront', 'partial', 'on_delivery', 'xepelin_credit']

  const showableEnabledPaymentMethods: FinanceProfileProps['enabledPaymentMethods'] =
    ['cash', 'bank_transfer', 'credit_card']

  useEffect(() => {
    if (financeProfileData) {
      setEnabledPaymentForms(financeProfileData.enabledPaymentForms)
      setEnabledPaymentMethods(financeProfileData.enabledPaymentMethods)
      setUpfrontPaymentPercentage(
        financeProfileData?.upfrontPaymentPercentage
          ? +financeProfileData.upfrontPaymentPercentage
          : 0.2,
      )
    }
  }, [financeProfileData])

  return (
    <Drawer isOpen={isOpen} onHandleOpen={() => onHandleOpen(false)}>
      <FinanceProfilePaymentConditionHeader
        onHandleOpen={onHandleOpen}
        profileName={profileName}
      />

      <div className={styles.content}>
        <section>
          <div className={styles['section-title']}>
            <Text variant="text-large-bold">{t('conditionsTitle')}</Text>
            <Text variant="text-medium">{t('conditionsSubtitle')}</Text>
          </div>
          {showableEnabledPaymentForms.map(getEnabledPaymentFormsComponent)}
        </section>
        <section>
          <div className={styles['section-title']}>
            <Text variant="text-large-bold">{t('paymentMethodsTitle')}</Text>
            <Text variant="text-medium">{t('paymentMethodsSubtitle')}</Text>
          </div>
          {showableEnabledPaymentMethods.map(getEnabledPaymentMethodsComponent)}
        </section>
      </div>
      <div className={styles.footer}>
        <Button variant="secondary" onClick={() => onHandleOpen(false)}>
          {t('buttonClose')}
        </Button>
        <Button onClick={onHandleSubmit}>
          {isLoadingUpdate || isLoadingCreate
            ? t('buttonSubmitLoading')
            : t('buttonSubmit')}
        </Button>
      </div>
    </Drawer>
  )
}
