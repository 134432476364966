export const targetGMV = 3000000
export const targetThisWeekGMV = targetGMV / 4
export const targetGM = 7
export const targetCM3 = 2

export const feedbacks = [
  {
    content:
      'Todo super bien, he pedido muchas veces. Recibí todos los producto a tiempo. El equipo de ventas me mantenía actualizado de mi pedido (!!!!!!). No conoce la plataforma. Sí referería',
    author: 'Congonmar',
  },
  {
    content:
      'Ningún inconveniente les  gusto mucho el sistema - la aplicación Me guiaron por donde ir subiendo los documentos. Fue muy rápido Atención super buena de la KAM Necesita material reciclado - falta proveeduría Necesita que el cliente le confirme que sí esta bien para seguir comprando Servicio, flexibilidad, disponibilidad de los materiales (pocos proveedores) Sí referería ',
    author: 'Indplast',
  },
  {
    content:
      'Siempre ha llegado bien todo, 8 compras. Una vez se retrasó la fecha de entrega pero se compenso con la entrega a domicilio. Plataforma: acepta la cotización, cuenta para depositar, rebote de dinero 1 vez . A veces me mandan a almacén otras a domicilio - Sorpresa de cuando le mandan a donde  - me hacen rebaja? Precio es lo más importante. Sí refiere.',
    author: 'Plasticos Grande',
  },
  {
    content:
      '"Ha estado bien. Mejorar el precio, para tener más más mercado. Entrega: todo bien nos dicen cuando llega, cancelamos un alambrón por la fecha entrega. En ocaciones urgencia pagan más. Plataforma digital : aceptar cotización. Sí referiría.',
    author: 'Aceros y agregados Ecatepec',
  },
  {
    content:
      'Todo bien. El producto llegó bien. La entrega se retrasó, dijeron una fecha y llegó antes. Davi le avisó. Me mandan un link y nada más lo abro. Sí referiría.',
    author: 'Viridiana Yañez',
  },
  {
    content:
      'Muy buena, Valentina Vidal es lo mejor. Ningún punto a mejorar. A veces llega menos cantidad. Sí, está buena la plataforma: órdenes de compra y seguimiento de órdenes. No necesita ningún producto adicional. Sí recomendaría.',
    author: 'Plastipem',
  },
]
