import { useState, useEffect } from 'react'

import {
  Label,
  Input,
  InputDay,
  InputPhone,
  Radio,
  Checkbox,
  DragDropFileList,
  QuestionFillIcon,
  Tooltip,
} from '@mercai/clever'

import { getFileNameAndExtension } from '@/helpers'
import { ONBOARDING_FILES_URL, api, useFileSign } from '@/services'

import type { LevelFormProps } from './level-form.type'

import styles from './level-form.module.css'

export const LevelForm = ({
  answers,
  questions,
  onHandleAnswers,
  transtations,
}: LevelFormProps) => {
  const [answersForText, setAnswersForText] = useState<
    Record<string, string | string[]>
  >({})

  const onChangeAnswersForText = (key: string, value: string) => {
    setAnswersForText((prevAnswers) => ({
      ...prevAnswers,
      [key]: value,
    }))
  }

  const { mutateAsync } = useFileSign()

  const onClickPreview = async (url: string) => {
    let correctUrl = url

    if (!url.includes('http')) {
      const response = await mutateAsync({
        filePath: url || '',
      })

      correctUrl = response?.data?.signedUrl || ''
    }

    window.open(correctUrl, '_blank')
  }

  useEffect(() => {
    if (JSON.stringify(answers) !== JSON.stringify(answersForText)) {
      setAnswersForText(answers)
    }
  }, [answers])

  const getComponentOfQuestionType = (
    type: string,
    questionSlug: string,
    options?: { title: string; slug: string }[],
  ) => {
    const onChangeCheckBoxValue = (optionSlug: string, checked: boolean) => {
      const options = answers?.[questionSlug] ? answers?.[questionSlug] : []

      if (checked) {
        Array.isArray(options) && options?.push(optionSlug)
      } else {
        const index = options.indexOf(optionSlug)
        if (index > -1) {
          Array.isArray(options) && options?.splice(index, 1)
        }
      }

      onHandleAnswers(questionSlug, options)
    }

    const getCheckBoxValue = (optionSlug: string) => {
      return answers?.[questionSlug]
        ? answers?.[questionSlug]?.includes(optionSlug)
        : false
    }

    const getFileValue = () => {
      if (!answers?.[questionSlug]) {
        return []
      }

      const file = getFileNameAndExtension(`${answers?.[questionSlug]}` || '')

      return [
        {
          id: new Date().getTime(),
          name: `${file.fileName}.${file.extension}`,
          url: answers?.[questionSlug] as string,
          uploaded: true,
        },
      ]
    }

    if (type === 'str') {
      return (
        <Input
          onChange={(value) => onChangeAnswersForText(questionSlug, value)}
          value={
            answersForText?.[questionSlug]
              ? `${answersForText?.[questionSlug]}`
              : ''
          }
          onBlur={() =>
            onHandleAnswers(questionSlug, answersForText?.[questionSlug])
          }
        />
      )
    }

    if (type === 'phone') {
      return (
        <InputPhone
          value={
            answersForText?.[questionSlug]
              ? `${answersForText?.[questionSlug]}`
              : ''
          }
          onChange={(value) => onChangeAnswersForText(questionSlug, value)}
          onBlur={() =>
            onHandleAnswers(questionSlug, answersForText?.[questionSlug])
          }
        />
      )
    }

    if (type === 'radio') {
      return (
        <div className={styles['forms-content-options']}>
          {options?.map((option) => (
            <Radio
              key={option.slug}
              onChange={() => onHandleAnswers(questionSlug, option.slug)}
              value={answers?.[questionSlug] === option.slug}
            >
              {option.title}
            </Radio>
          ))}
        </div>
      )
    }

    if (type === 'checkbox') {
      return (
        <div className={styles['forms-content-options']}>
          {options?.map((option) => (
            <Checkbox
              key={option.slug}
              value={getCheckBoxValue(option.slug)}
              onChange={(value) => onChangeCheckBoxValue(option.slug, value)}
            >
              {option.title}
            </Checkbox>
          ))}
        </div>
      )
    }

    if (type === 'file') {
      return (
        <DragDropFileList
          axiosInstance={api}
          urlAdd={ONBOARDING_FILES_URL}
          configsRequest={{
            fileField: 'file',
            method: 'POST',
          }}
          initialData={getFileValue()}
          successedUpload={(value) => {
            onHandleAnswers(
              questionSlug,
              value?.filename ? `${value?.filename}` : '',
            )
          }}
          translations={{
            clickToUpload: transtations.uploadButton,
            dragFile: transtations.uploadDrag,
            sizeFile: transtations.uploadSize,
          }}
          onClickPreview={onClickPreview}
        />
      )
    }

    if (type === 'days') {
      return (
        <InputDay
          onChange={(value) => onHandleAnswers(questionSlug, `${value}`)}
          value={Number(answers?.[questionSlug]) || undefined}
        />
      )
    }

    return (
      <Input
        onChange={(value) => onChangeAnswersForText(questionSlug, value)}
        value={
          answersForText?.[questionSlug]
            ? `${answersForText?.[questionSlug]}`
            : ''
        }
        isTextArea={true}
        onBlur={() =>
          onHandleAnswers(questionSlug, answersForText?.[questionSlug])
        }
      />
    )
  }

  return (
    <div className={styles.core}>
      <div className={styles.content}>
        {questions?.map((question) => (
          <div key={question.slug} className={styles.question}>
            <Label className={styles['question-label']}>
              {question.title}

              {question?.description && (
                <Tooltip
                  text={question?.description}
                  orientation="left"
                  className={styles['question-tooltip']}
                  widthText="10rem"
                >
                  <QuestionFillIcon />
                </Tooltip>
              )}
            </Label>

            {getComponentOfQuestionType(
              question.typeForm,
              question.slug,
              question?.options,
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
