/* eslint-disable */
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Tag, TruckIcon, InfoIcon, Tooltip } from '@mercai/clever'

import { InfoList } from '@/components'

import { formatLongDate, formatCurrency } from '@/helpers'

import styles from './order-logistic.module.css'

import { LogisticShipmentStatusEnum } from '@/types'

import type { OrderLogisticProps } from './order-logistic.type'

export const OrderLogistic = ({
  isLoading,
  shipment
}: OrderLogisticProps) => {
  const { t } = useTranslation('componentOrderCenterShow')
  const { t: tEnums } = useTranslation('enums')

  const statusShipmentObject: Record<LogisticShipmentStatusEnum, ReactNode> = {
    pending: (
      <Tag model="light" variant="warning">
        {tEnums('logistic_shipment_status_pending')}
      </Tag>
    ),
    delivered: (
      <Tag model="light" variant="success">
        {tEnums('logistic_shipment_status_delivered')}
      </Tag>
    ),
    delivery_scheduled: (
      <Tag model="light" variant="primary">
        {tEnums('logistic_shipment_status_delivery_scheduled')}
      </Tag>
    ),
    in_transit: (
      <Tag model="light" variant="primary">
        {tEnums('logistic_shipment_status_in_transit')}
      </Tag>
    ),
    partially_delivered: (
      <Tag model="light" variant="warning">
        {tEnums('logistic_shipment_status_partially_delivered')}
      </Tag>
    ),
    on_hold: (
      <Tag model="light" variant="warning">
        {tEnums('logistic_shipment_status_on_hold')}
      </Tag>
    ),
    refused: (
      <Tag model="light" variant="danger">
        {tEnums('logistic_shipment_status_refused')}
      </Tag>
    ),
    cancelled: (
      <Tag model="light" variant="danger">
        {tEnums('logistic_shipment_status_cancelled')}
      </Tag>
    ),
  }

  const getAddress = () => {
    return shipment?.destinationAddress || t('orderLogisticShipmentAddressNotInformed');
  };


  return (
    <div className={styles.core}>
    <InfoList
      isLoading={isLoading}
      iconTitle={<TruckIcon />}
      title={t('orderLogisticTitle')}
      actions={  statusShipmentObject[shipment?.status || 'pending']}
      list={[
        {
          label: t('orderLogisticShipmentDeliveryOption'),
          value: shipment?.deliveryOption ? tEnums(`logistic_shipment_delivery_option_${shipment?.deliveryOption}`) : '-',
        },
        {
          label: t('orderLogisticShipmentAddress'),
          value: getAddress()
        },
        {
          label: <div className={styles['label-row']}>
            {t('orderLogisticShipmentExpectedDeliveryDays')} 
          <Tooltip
              text={t('orderLogisticShipmentExpectedDeliveryDescription')}
              widthText="16rem"
              orientation='top'
            >
              <InfoIcon className={styles['info-icon']} />
            </Tooltip>
          </div>,
          value: shipment?.expectedDeliveryDays ? `${shipment?.expectedDeliveryDays} ${t('orderLogisticShipmentExpectedDeliveryDaysSuffix')}` : '-',
        },
        {
          label: 
          <div className={styles['label-row']}>
          {t('orderLogisticShipmentExpectedDeliveryDate')}
          <Tooltip
              text={t('orderLogisticShipmentExpectedDeliveryDateDescription')}
              widthText="16rem"
              orientation='top'
            >
              <InfoIcon className={styles['info-icon']} />
            </Tooltip>
          </div>,
          value: shipment?.expectedDeliveryAt ? formatLongDate(shipment?.expectedDeliveryDate): '-',
        },
        {
          label: 
          <div className={styles['label-row']}>
          {t('orderLogisticShipmentExpectedDeliveryAt')}
          <Tooltip
              text={t('orderLogisticShipmentExpectedDeliveryAtDescription')}
              widthText="16rem"
              orientation='top'
            >
              <InfoIcon className={styles['info-icon']} />
            </Tooltip>
          </div>,
          value: shipment?.expectedDeliveryAt ? formatLongDate(shipment?.expectedDeliveryAt): '-',
        },
        {
          label: 
          <div className={styles['label-row']}>
          {t('orderLogisticShipmentDeliveryAt')}
          <Tooltip
              text={t('orderLogisticShipmentDeliveryAtDescription')}
              widthText="16rem"
              orientation='top'
            >
              <InfoIcon className={styles['info-icon']} />
            </Tooltip>
          </div>,
          value: shipment?.deliveredAt ? formatLongDate(shipment?.deliveredAt): '-',
        },
        {
          label: t('orderLogisticShipmentTotalFreightPrice'),
          value: shipment?.price
            ? formatCurrency(shipment?.price, 'es-MX', 'USD')
            : '-',
        },
       
      ]}
      />

      </div>
  )
}
