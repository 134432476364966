import { useTranslation } from 'react-i18next'
import { Button, Modal, Text } from '@mercai/clever'

import type { ModalConfirmDrawerCloseProps } from './modal-confirm-drawer-close.type'

import styles from './modal-confirm-drawer-close.module.css'

export const ModalConfirmDrawerClose = ({
  isOpenModalConfirm,
  setIsOpenModalConfirm,
  onCloseDrawerForm,
}: ModalConfirmDrawerCloseProps) => {
  const { t } = useTranslation('screenProfileShow')

  return (
    <Modal.Root
      size="small"
      isOpen={isOpenModalConfirm}
      onHandleOpen={setIsOpenModalConfirm}
      overlayClassName={styles.root}
      mainClassName={styles.main}
    >
      <Modal.Body className={styles.body}>
        <Text variant="title-large" className={styles.title}>
          {t('userTableDrawerConfirmDrawerCloseTitle')}
        </Text>

        <Text className={styles.subtitle}>
          {t('userTableDrawerConfirmDrawerCloseDescription')}
        </Text>

        <div className={styles.buttons}>
          <Button
            variant="secondary"
            onClick={() => setIsOpenModalConfirm(false)}
          >
            {t('userTableDrawerConfirmDrawerCloseCancelButton')}
          </Button>
          <Button variant="primary" onClick={onCloseDrawerForm}>
            {t('userTableDrawerConfirmDrawerCloseConfirmButton')}
          </Button>
        </div>
      </Modal.Body>
    </Modal.Root>
  )
}
