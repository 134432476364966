import { useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { DrawerStepperProgress } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import {
  onSuccessMessage,
  onErrorMessage,
  useValidationErrors,
} from '@/helpers'

import {
  useUpdateTransportCompany,
  QUERIES_ADMIN,
  useCreateTransportCompany,
  useRouteList,
  useVehiclesList,
} from '@/services'

import { TransportCompanyProps } from '@/types'

import {
  schemaTransportCompany,
  TransportCompanyForm,
  schemaTransportCompanyRoutes,
  TransportCompanyRoutesForm,
  RouteForm,
} from './components'

import type { TransportCompanyDrawerFormProps } from './drawer-form.type'
import { formatDataForUpdate } from './drawer-form.constants'

export const TransportCompanyDrawerForm = ({
  isOpen,
  onHandleOpen,
  transportCompany,
}: TransportCompanyDrawerFormProps) => {
  const { t } = useTranslation('screenTransportCompanies')

  const isNewRegister = !transportCompany

  const queryClient = useQueryClient()

  const [data, setData] = useState<Partial<TransportCompanyProps> | undefined>(
    formatDataForUpdate(transportCompany),
  )

  const { data: routes } = useRouteList({})
  const { data: vehicles } = useVehiclesList({})

  const { mutateAsync: mutateAsyncUpdateTransportCompany } =
    useUpdateTransportCompany()
  const { mutateAsync: mutateAsyncCreateTransportCompany } =
    useCreateTransportCompany()

  const onSubmitTransportCompanyForm = async (
    dataToUpdate?: TransportCompanyProps,
  ) => {
    let response: {
      success: boolean
      message: string
      data?: TransportCompanyProps
    } = {
      success: false,
      message: '',
      data: {} as TransportCompanyProps,
    }

    if (isNewRegister) {
      response = await mutateAsyncCreateTransportCompany(
        data as TransportCompanyProps,
      )
    } else {
      response = await mutateAsyncUpdateTransportCompany(
        dataToUpdate || (data as TransportCompanyProps),
      )
    }

    if (response.success) {
      if (isNewRegister) {
        onSuccessMessage(
          t('transportCompaniesFormSuccessCreateTitle'),
          t('transportCompaniesFormSuccessCreateMessage'),
        )
      } else {
        onSuccessMessage(
          t('transportCompaniesFormSuccessUpdateTitle'),
          t('transportCompaniesFormSuccessUpdateMessage'),
        )
      }

      queryClient.invalidateQueries({
        queryKey: [QUERIES_ADMIN.SUPPLIER.module],
      })

      setData({})
      onHandleOpen(false)
    } else {
      if (isNewRegister) {
        onErrorMessage(
          t('transportCompaniesFormErrorCreateTitle'),
          t('transportCompaniesFormErrorCreateMessage'),
        )
      } else {
        onErrorMessage(
          t('transportCompaniesFormErrorUpdateTitle'),
          t('transportCompaniesFormErrorUpdateMessage'),
        )
      }
    }
  }

  const validationTransportCompany = useValidationErrors(
    schemaTransportCompany(t),
    {
      name: data?.name,
      phone: data?.phone,
      email: data?.email,
    },
  )

  const validationVehiclePrices = useValidationErrors(
    schemaTransportCompanyRoutes(t),
    {
      routes: data?.transportCompanyRoutes,
    },
  )

  useEffect(() => {
    const formattedData = formatDataForUpdate(transportCompany)
    setData(formattedData)
  }, [transportCompany, isOpen, routes])

  return (
    <DrawerStepperProgress
      translations={{
        optional: '',
      }}
      isOpen={isOpen}
      onHandleOpen={onHandleOpen}
      title={
        isNewRegister ? t('drawerFormCreateTitle') : t('drawerFormUpdateTitle')
      }
      description={
        isNewRegister
          ? t('drawerFormCreateSubtitle')
          : t('drawerFormUpdateSubtitle')
      }
      initialStep="root"
      steps={{
        root: {
          title: t('transportCompanyFormTitle'),
          isOptional: false,
          backNavigate: 'root',
          stepNumber: 0,
          component: (
            <TransportCompanyForm.Root
              values={data || {}}
              onChangeValues={setData}
              validation={validationTransportCompany}
            />
          ),
          footer: ({ onNavigateToNextStep }) => (
            <TransportCompanyForm.Footer
              onNavigateToNextStep={() => onNavigateToNextStep('routes')}
              validation={validationTransportCompany}
            />
          ),
        },
        routes: {
          title: t('routesFormTitle'),
          isOptional: false,
          stepNumber: 1,
          backNavigate: 'root',
          component: (
            <RouteForm.Root
              values={data || {}}
              transportCompanyRoutes={routes?.data?.results}
              onChangeValues={setData}
            />
          ),
          footer: ({ onNavigateToPreviousStep, onNavigateToNextStep }) => (
            <RouteForm.Footer
              onNavigateToPreviousStep={onNavigateToPreviousStep}
              onNavigateToNextStep={() =>
                onNavigateToNextStep('transport-company-routes')
              }
              values={data || {}}
            />
          ),
        },
        'transport-company-routes': {
          isOptional: false,
          stepNumber: 1,
          backNavigate: 'routes',
          component: (
            <TransportCompanyRoutesForm.Root
              values={data || {}}
              vehicles={vehicles?.data?.results || []}
              onChangeValues={setData}
              validation={validationVehiclePrices}
            />
          ),
          footer: ({ onNavigateToPreviousStep }) => (
            <TransportCompanyRoutesForm.Footer
              onNavigateToPreviousStep={onNavigateToPreviousStep}
              onSubmit={onSubmitTransportCompanyForm}
              validation={validationVehiclePrices}
            />
          ),
        },
      }}
    />
  )
}
