import { useTranslation } from 'react-i18next'
import { Skeleton, Text, Tooltip, InfoIcon } from '@mercai/clever'
import { Card } from '../card'
import { variants } from './metrics-card.constants'
import styles from './metrics-card.module.css'

import { MetricsCardProps } from './metrics-card.type'

export const MetricsCard = ({
  className,
  value = '',
  badge = '',
  variant = 'rfq_attended',
  tooltipText = '',
  isLoading = false,
}: MetricsCardProps) => {
  const { t } = useTranslation('componentsMetricsCard')

  const { backgroundColor, circleColor, title, rotate } = variants(t)[variant]

  const Loading = () => (
    <div className={styles.children}>
      <div className={styles.title}>
        <Skeleton height="14px" width="100px" isActive={true} />
      </div>
      <div className={styles.content}>
        <div className={styles.value}>
          <Skeleton height="24px" width="48px" />
        </div>
        {badge && (
          <div className={styles.badge}>
            <Skeleton height="14px" width="14px" />
          </div>
        )}
      </div>
    </div>
  )

  return (
    <Card className={`${styles.wrapper} ${className}`}>
      <div style={{ backgroundColor }} className={styles.background}></div>
      <div className={styles['svg-box']}>
        <svg
          width="213"
          height="78"
          viewBox="0 0 213 78"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            transform={`rotate(${rotate},100,10)`}
            d="M126.282 178.842C37.6369 178.842 -34.2184 107.429 -34.2184 19.3422C-34.2184 -68.7443 37.6369 -140.158 126.282 -140.158C214.926 -140.158 286.782 -68.7443 286.782 19.3422C286.782 107.429 214.926 178.842 126.282 178.842Z"
            stroke="white"
            stroke-opacity="0.06"
            fill="transparent"
          />
          <path
            transform={`rotate(${rotate},100,10)`}
            d="M167.282 178.842C101.28 178.842 47.7816 125.784 47.7816 60.3422C47.7816 -5.09962 101.28 -58.1578 167.282 -58.1578C233.284 -58.1578 286.782 -5.09961 286.782 60.3422C286.782 125.784 233.284 178.842 167.282 178.842Z"
            stroke="white"
            stroke-opacity="0.06"
            fill="transparent"
          />
          <path
            transform={`rotate(${rotate},100,10)`}
            d="M220.282 178.842C183.548 178.842 153.782 149.51 153.782 113.342C153.782 77.1746 183.548 47.8422 220.282 47.8422C257.016 47.8422 286.782 77.1746 286.782 113.342C286.782 149.51 257.016 178.842 220.282 178.842Z"
            stroke="white"
            stroke-opacity="0.06"
            fill="transparent"
          />
          <path
            transform={`rotate(${rotate},100,10)`}
            d="M195.782 178.842C145.518 178.842 104.782 138.543 104.782 88.8422C104.782 39.1417 145.518 -1.15781 195.782 -1.15781C246.045 -1.15781 286.782 39.1417 286.782 88.8422C286.782 138.543 246.045 178.842 195.782 178.842Z"
            stroke="white"
            stroke-opacity="0.06"
            fill="transparent"
          />
          <path
            transform={`rotate(${rotate},100,10)`}
            fill={circleColor}
            d="M338.255 157.827C318.431 217.64 282.043 231.993 222.008 212.095C161.972 192.198 118.388 130.913 138.211 71.1001C158.035 11.2873 229.79 13.6631 289.826 33.5604C349.861 53.4577 358.078 98.0141 338.255 157.827Z"
          />
        </svg>
      </div>
      {!isLoading ? (
        <div className={styles.children}>
          <div className={styles['box-title']}>
            <Text className={styles.title}>{title}</Text>
            {tooltipText && (
              <Tooltip
                text={tooltipText}
                widthText="15rem"
                orientation="bottom"
                className={styles.tooltip}
              >
                <InfoIcon />
              </Tooltip>
            )}
          </div>
          <div className={styles.content}>
            <div className={styles.value}>{value}</div>
            {badge && <div className={styles.badge}>{badge}</div>}
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </Card>
  )
}
