import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import {
  Button,
  InputSearch,
  Pagination,
  Select,
  TableColumns,
  TableColumnsColumnProps,
  Text,
} from '@mercai/clever'
import { SupplierProductProps } from '@/types'
import { formatCurrency, useParamsState } from '@/helpers'
import { useSupplierProductsList } from '@/services'
import { DEFAULT_LIMIT_PAGE } from '@/constants'

import { SupplierProductsTableProps } from './supplier-products-table.types'

import styles from './supplier-products-table.module.css'
import { Metrics } from '../metrics'
import { ROUTES_ADMIN } from '@/router'
import { SupplierDrawerForm } from '../../../components'

export const SupplierProductsTable = ({
  uuid,
  values,
}: SupplierProductsTableProps) => {
  const { t } = useTranslation('screenSupplierShow')
  const { t: tEmuns } = useTranslation('enums')

  const [filter, setFilter] = useParamsState({
    page: '1',
    limit: `${DEFAULT_LIMIT_PAGE}`,
    supplierUuid: uuid,
    productTitle: '',
    catalogProduct_Uuid: '',
    search: '',
  })

  const [queryParams, setQueryParams] = useParamsState({
    supplierProductform: '',
  })

  const onHandleNewRegister = () => {
    setQueryParams({
      ...queryParams,
      supplierProductform: 'true',
    })
  }

  const [totalPages, setTotalPages] = useState(1)

  const handleFilter = (changedKey: string, newValue: string) => {
    setFilter({ ...filter, [changedKey]: newValue })
  }

  const { data, isLoading } = useSupplierProductsList({
    params: {
      ...filter,
    },
  })

  const columns: TableColumnsColumnProps<SupplierProductProps>[] = [
    {
      title: t('supplierProductsTableName'),
      key: 'name',
      dataKey: 'catalogProduct',
      render: ({ record }) => (
        <Link
          to={`/${ROUTES_ADMIN.CATALOG.PRODUCT.path}/${record?.catalogProduct?.uuid}`}
        >
          {record?.catalogProduct?.attributes?.title}
        </Link>
      ),
    },
    {
      title: t('supplierProductsTablePacking'),
      key: 'package',
      dataKey: 'package',
      width: '12rem',
      render: ({ record }) =>
        record?.package
          ? tEmuns(`supplier_product_package_${record.package}`)
          : '-',
    },
    {
      title: t('supplierProductsTableLocation'),
      key: 'supplierWarehouseUuid',
      dataKey: 'supplierWarehouseUuid',
      width: '12rem',
      render: ({ record }) => (
        <div className={styles['warehouse-column']}>
          <img
            src={
              record?.supplierWarehouse?.supplier?.isForeign
                ? '/images/flag-us.svg'
                : '/images/flag-mx.svg'
            }
            alt={'flag'}
          />
          {record?.supplierWarehouse?.name}
        </div>
      ),
    },
    {
      title: t('supplierProductsTablePrice'),
      key: 'priceWithoutIVA',
      dataKey: 'price',
      width: '12rem',
      render: ({ record }) =>
        record?.price ? formatCurrency(record.price, 'es-MX', 'USD') : '-',
    },
    {
      title: t('supplierProductsTableMin'),
      key: 'minimumPurchaseQuantity',
      dataKey: 'minimumPurchaseQuantity',
      width: '12rem',
      render: ({ record }) =>
        record?.minimumPurchaseQuantity
          ? `${record?.minimumPurchaseQuantity} Kg`
          : '-',
    },
    {
      title: t('supplierProductsTableAvailable'),
      key: 'quantity',
      dataKey: 'quantity',
      width: '12rem',
      render: ({ record }) =>
        record?.quantity ? `${record?.quantity} Kg` : '-',
    },
  ]

  useEffect(() => {
    if (data?.data?.count) {
      setTotalPages(Math.ceil(data?.data?.count / +filter.limit))
    }
  }, [data])

  return (
    <div>
      <div className={styles.metrics}>
        <Metrics values={values} />
      </div>
      <Text className={styles.title} variant="title-large">
        {t('supplierProductsTitle')}
      </Text>
      <div className={styles['table-header']}>
        <div className={styles.filters}>
          <InputSearch
            value={filter.search}
            onChange={(value) => handleFilter('search', value)}
            translations={{
              inputPlaceholder: t('supplierProductsTableSearchPlaceholder'),
            }}
          />
        </div>
        <Button variant="light" onClick={onHandleNewRegister}>
          {t('supplierProductsAddButton')}
        </Button>
      </div>
      <TableColumns<SupplierProductProps>
        isLoading={isLoading}
        columns={columns}
        data={data?.data?.results || []}
      />
      <div className={styles.pagination}>
        <div className={styles['pagination-show-registers']}>
          <Text>
            {t('supplierPaginationShowRegisters', {
              showNumber:
                (data?.data?.count || 0) < +filter.limit
                  ? data?.data?.count
                  : +filter.limit,
              totalNumber: data?.data?.count || 0,
            })}
          </Text>

          <Select
            value={filter.limit}
            onChange={(value) => handleFilter('limit', value)}
            options={[
              { label: '10', value: '10' },
              { label: '20', value: '20' },
              { label: '50', value: '50' },
              { label: '100', value: '100' },
            ]}
          />
        </div>

        <Pagination
          totalPages={totalPages}
          onChange={(page) => handleFilter('page', `${page}`)}
          currentPage={+filter.page as number}
        />
      </div>
      <SupplierDrawerForm
        noSupplierForm={true}
        supplierUuid={uuid}
        isOpen={!!queryParams.supplierProductform}
        onHandleOpen={() =>
          setQueryParams({ ...queryParams, supplierProductform: '' })
        }
      />
    </div>
  )
}
