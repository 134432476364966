import { ReactNode } from 'react'
import { Text } from '../text'
import { Button } from '../button'
import { Tooltip } from '../tooltip'
import { CircleProgressBar } from '../circle-progress-bar'
import { TrashIcon, CheckIcon, AlertIcon, EyeIcon } from '../../../icons'

import styles from './file-list.module.css'

import { FileListProps } from './file-list.type'

export const FileList = ({
  data,
  onClickRemove,
  onClickPreview,
}: FileListProps) => {
  const getContentName = (name: string): ReactNode => {
    if (name.length > 36) {
      return (
        <Tooltip
          text={name}
          orientation="bottom"
        >{`${name.slice(0, 36)}...`}</Tooltip>
      )
    }

    return name
  }

  return (
    <ul className={styles.core}>
      {data?.map((file) => {
        const splittedName = file.name.split('.')
        const extension = splittedName[splittedName.length - 1]

        const url = file.preview

        return (
          <li key={file.preview || file.id}>
            <div className={styles['box-extension']}>{extension}</div>
            <div className={styles['box-info']}>
              <Text variant="title-small">{getContentName(file.name)}</Text>
              <Text variant="text-small">{file.readableSize}</Text>
            </div>

            <div className={styles['box-actions']}>
              {file.error && (
                <AlertIcon className={styles['box-actions-svg-error']} />
              )}

              {!file.error && (
                <>
                  {url && (
                    <Button
                      variant="ghost"
                      size="small"
                      model="square"
                      href={url}
                      target="_blank"
                    >
                      <EyeIcon />
                    </Button>
                  )}

                  {!file.uploaded && (
                    <CircleProgressBar progress={file.progress} />
                  )}

                  {file.uploaded && !onClickRemove && (
                    <CheckIcon className={styles['box-actions-svg-check']} />
                  )}

                  {file.uploaded && file?.url && onClickPreview && (
                    <Button
                      variant="ghost"
                      model="square"
                      onClick={() => onClickPreview(file?.url || '')}
                    >
                      <EyeIcon />
                    </Button>
                  )}

                  {file.uploaded && onClickRemove && (
                    <Button
                      variant="ghost"
                      model="square"
                      onClick={() => onClickRemove(file.id)}
                    >
                      <TrashIcon className={styles['box-actions-svg']} />
                    </Button>
                  )}
                </>
              )}
            </div>
          </li>
        )
      })}
    </ul>
  )
}
